
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import { DownloadService } from '@shared/services/download/download.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { ExportServiceService } from '@shared/services/export-service/export-service.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'historic-concept',
  templateUrl: './historic-concept.component.html'
})
export class HistoricConceptComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  userPermissions: any = this.permissions.getPermissions();
  currentComponent: string = this.route.snapshot.data['componentName'];
  historicConcept:any = {
    elementWordFilter: '',
    orderOperationDates: true,
    orderAccessDates: true,
    showMenu: false,
    showAccessHistoric: false,
    historicPrevous: null,
    userLogin: this.context.user,
    filters: ['operationType', 'section', 'user'],
    filtersAccess: ['userType', 'name', 'accessType'],
    operationTypeFilter: {
        name: this.translate.instant('historic-concept.operation-type')
    },
    sectionFilter: {
        name: this.translate.instant('historic-concept.section')
    },
    userTypeFilter:{
        name: this.translate.instant('common.type')
    },
    userFilter:{
        name: this.translate.instant('field-list.user')
    },
    nameFilter:{
        name: this.translate.instant('field-list.user')
    },
    accessTypeFilter:{
        name: this.translate.instant('historic-concept.access-road')
    },
    elementFilter: {
        name: this.translate.instant('historic-concept.element-affected'),
        width: '200px'
    },
    compareVersions: {
        jwtToken: this.context.jwtToken,
        logID: null,
        conceptObjectID: null
    }
  }

  constructor(private permissions: UserPermisionsFactory,
    private globalCfg: GlobalCfgFactory,
    private route: ActivatedRoute,
    private context: ContextFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private loader: LoaderFactory,
    private filterColumnService: FilterColumnService,
    private registerService: RegisterMixtapeService,
    private commonUtilsService: CommonUtilsService,
    private downloadService: DownloadService,
    private modalService: ModalServiceService,
    private dateConverterService: DateConverterService,
    private exportService:ExportServiceService) { }
    @Input() concept: any = {};

    @Output() launchAction = new EventEmitter()

  ngOnInit(): void {
    this.historicConcept.concept = this.concept;
    this.getHistoric();
    this.getAccesHistoric();
  }

  getHistoric(){
      this.apiService.get('conceptobjects/historic', this.concept.conceptObjectId)
          .then((data:any)=> {
            data.forEach((historic:any) => {
                historic.showActions = false;
                if((historic.operationTypeId === 2 || historic.operationTypeId === 19 || historic.operationTypeId === 20 || historic.operationTypeId === 23)
                    && historic.sectionId !== 3 && !(this.historicConcept.concept.poll && this.historicConcept.concept.sequentialView) && !this.historicConcept.concept.anonymized){
                    historic.showActions = true;
                }
                if(historic.operationTypeId === 24){
                    historic.previousParticipantsComparator = historic.previousParticipants.split('|');
                    historic.currentParticipantsComparator = historic.currentParticipants.split('|');
                }
            });
              this.historicConcept.historicList = data;
              this.historicConcept.filterHistoricList = this.historicConcept.historicList;
              // vm.concept.initializedhistoric = true;
              this.getFilters(this.historicConcept.historicList, 'filters');
                  this.loader.closeLoader(['charge-historic-concept-list']);
                  this.historicConcept.showTable = true;
          }, (errorData:any) =>{
              this.loader.closeError();
          })
  }

  getFilters(list:any, typeFilter:any){
    let that: any = this.historicConcept;
      for(let i = 0; i < that[typeFilter].length; i++){
          let filter:any = that[typeFilter][i];
          that[filter+'List'] = this.filterColumnService.extractList(filter, list);
          that[filter+'ListFilter'] = [];
          if(filter === 'user' || filter === 'name'){
              for(let j = 0; j < that[filter+'List'].length; j++){
                  let filterList:any = that[filter+'List'][j]
                  if(filterList.name.trim().toLowerCase() === that.userLogin.fullName.trim().toLowerCase()){
                      that[filter+'Filter'].showMyOption = {
                          visible: true,
                          name: filterList.name,
                          selected: false
                      };
                      break;
                  }
              }
          }
      }
  }

  getAccesHistoric(){
      this.apiService.get('conceptobjects/historicaccess/', this.concept.conceptObjectId)
          .then((data:any)=>{
              this.historicConcept.historicAccessList = data;
              this.historicConcept.filterHistoricAccessList = this.historicConcept.historicAccessList;
              this.getFilters(this.historicConcept.historicAccessList, 'filtersAccess');
          }, (errorData:any)=>{
              this.loader.closeError()
          })
  }

  orderBySearch(e:any, type:any){
      this.historicConcept[type+'WordFilter'] = e.newValue;
      this.filterHistoric(type);
  }

  filterHistoric(type:any = ''){
    let that: any = this.historicConcept;
      let list:any = that.historicList;
      let filterArray = that.filters;
      if(that.showAccessHistoric){
          list = that.historicAccessList;
          filterArray = that.filtersAccess;
      }
      let searchFilter = that.elementWordFilter;
      let searchWord = 'element';
      if (type !== '') {
          searchFilter = that[type + 'WordFilter'];
          searchWord = type;
      }
      let filterList = this.filterColumnService.filterByWord(searchFilter, list, searchWord);
      filterArray.forEach((filter:any) => {
        let filteredList = that[filter+'ListFilter'];
        filterList = this.filterColumnService.filterBycolumn(filterList, filteredList, filter);
      });
      let filterType:string = that.showAccessHistoric? 'filterHistoricAccessList' : 'filterHistoricList';
      that[filterType] = filterList;
  }

  orderDate(){
      this.historicConcept.orderOperationDates = !this.historicConcept.orderOperationDates;
  }

  orderDateAccess(){
      this.historicConcept.orderAccessDates = !this.historicConcept.orderAccessDates;
  }

  selectTypeOperation(e:any, type:any){
    let item: any = e.source;
    let listFilter:any  = this.historicConcept[type+'ListFilter'];
    this.historicConcept[type+'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
    item.selected = !item.selected;
    if(this.historicConcept[type+'Filter']){
        this.historicConcept[type+'Filter'].hasFilter = this.historicConcept[type+'ListFilter'].length;
    }
    this.filterHistoric();
  }

  // getSnapshotTypes(path:string, successCallback:any) {
  //   this.apiService.get('conceptobjects/historic', path + '/snapshot-types').then(
  //       successCallback,
  //       function (error) { console.error(error) }
  //   );
  // }

  historyViewer(historic:any) {
    // this.apiService.get('conceptobjects/historic', historic.conceptObjectID + '/' + historic.logOperationID + '/snapshot-types').then(
    //   (data:any) => {
        if (historic.hasJsonSnapshot) {
          sessionStorage.removeItem('historyViewerDataJson');
          sessionStorage.setItem('historyViewerData', historic.conceptObjectID + '/' + historic.logOperationID);
          window.open('/history-viewer', '_blank');
        } else if (historic.hasPdfSnapshot) {
          this.historicConcept.compareVersions.logID = historic.logOperationID;
          this.historicConcept.compareVersions.conceptObjectID = historic.conceptObjectID;
          this.historicConcept.openCompareVersions();
        }
      // },
      // (error:any) => {
      //   console.error(error);
      // }
    //);
  }

  exportEdition(historic: any) {
    this.historicConcept.historyViewerData = historic.conceptObjectID + '/' + historic.logOperationID;
    let configuration: any = {
      list: this.concept.documentation.documents,
      hasPrevious: false,
      title: this.translate.instant('historic-concept.export-edition'),
      extension: 'PDF'
    }
    this.modalService.exportModal(configuration)
    .subscribe((result:any) => {
      if(result.result === 'ok'){
        let htmlPrint = $('#history-viewer').html().replace('history-viewer__block-header-body', 'common__hide');
        let config: any = {
            htmlPrint: htmlPrint,
            nameDocument: result.filename+'.pdf',
            conceptObjectId: this.concept.conceptObjectId,
            uploadFile: true,
            landscape: false,
            successCallback: (uploadResponse:any, blob:any) => {
                delete this.historicConcept.historyViewerData;
                this.modalService.adviceModal(this.translate.instant('historic.export-edition-ok').replace('{0}', uploadResponse.name), 'info:accept:check-round');
                this.concept.concept.documentation.documents.push(uploadResponse);
                this.concept.concept.documentation.totalDocuments++;
                if (result.downloadFile){
                    this.downloadService.triggerDownload(blob, uploadResponse.name);
                }
                if (result.openFile){
                    window.open(URL.createObjectURL(blob), '_blank');
                }
            }
        };
        this.exportService.toPdf(config);
      }else{
        delete this.historicConcept.historyViewerData;
      }
    });
  }

  editionComparator(historic:any){
    this.registerService.register({
        'Distinct ID': this.context.user.externalId,
        'Client ID': this.context.user.companyExternalId,
        'event': 'compare editions',
        'Concept Name': sessionStorage.getItem('breadcrumbConcept') + this.concept.title,
        'Centinela product': this.context.settings.defaultProduct
    });

    let path = historic.conceptObjectID + '/' + historic.logOperationID;
    // this.getSnapshotTypes(path, (data:any) => {
      if (historic.hasJsonSnapshot) {
          sessionStorage.setItem('comparatorViewerData', path);
          window.open('/comparator-viewer', '_blank');
      } else if (historic.hasPdfSnapshot) {
          this.historicConcept.compareVersions.logID = historic.logOperationID;
          this.historicConcept.compareVersions.conceptObjectID = historic.conceptObjectID;
          this.historicConcept.openCompareVersions();
      }
    // });
  }

  changeViewList(){
      this.historicConcept.showAccessHistoric = !this.historicConcept.showAccessHistoric;
      if(this.historicConcept.showAccessHistoric){
          let resgisterItem ={
              'Distinct ID': this.context.user.externalId,
              'Client ID': this.context.user.companyExternalId,
              'event':'access log',
              'Concept Name':sessionStorage.getItem('breadcrumbConcept') + this.historicConcept.concept.title,
              'Centinela product': this.context.settings.defaultProduct
          }
          this.registerService.register(resgisterItem);
      }

  }

  exportList() {
      this.modalService.exportFormatModal().subscribe((result:any)=>{
        if(result.result === 'ok'){
          this.exportListModal(result.selectedFormat);
        }
      })
  }
  exportListModal(formatResult:string) {
    let configuration: any = {
      list: this.concept.documentation.documents,
      hasPrevious: true,
      extension: formatResult
    }
    this.modalService.exportModal(configuration)
    .subscribe((result:any) => {
      if(result.result === 'ok'){
        let config: any = {
            conceptObjectId: this.historicConcept.concept.conceptObjectId,
            idPrint: 'pdf-historic-concept-list',
            list: cloneDeep(this.historicConcept.historicList),
            typeColumns: ['element', 'operationType', 'section', 'dateIso', 'user'],
            columns: {
                element: this.translate.instant('historic-concept.element-affected'),
                operationType: this.translate.instant('historic-concept.operation-type'),
                section: this.translate.instant('historic-concept.section'),
                dateIso: this.translate.instant('historic-concept.operation-date'),
                user: this.translate.instant('field-list.user')
            },
            uploadFile: true,
            landscape: true,
            successCallback: (uploadResponse:any, blob:any) => {
                delete this.historicConcept.configurationPdf;
                this.getHistoric();
                this.modalService.adviceModal(this.translate.instant('documentation-list.export-list-ok').replace('{0}', uploadResponse.name), 'info:accept:check-round');
                this.historicConcept.concept.documentation.documents.push(uploadResponse);
                this.historicConcept.concept.documentation.totalDocuments++;
                if(result.downloadFile){
                    this.downloadService.triggerDownload(blob, uploadResponse.name);
                }
                if(result.openFile){
                    formatResult === 'xls'?this.launchAction.emit({ action: { type: 'editFile', file: uploadResponse } }):window.open(URL.createObjectURL(blob), '_blank');
                }
            }
        };
        config.list.forEach((historic:any) => {
          historic.dateIso = this.dateConverterService.convertDate(historic.date, 'all-with-second');
        })
        switch (formatResult) {
          case 'xls':
              config.fileName = result.filename;
              config.title = this.translate.instant('common.historic');
              this.exportService.toXls(config);
              break;
          case 'pdf':
              config.exportDate = this.dateConverterService.setTimezone(new Date());
              config.title = this.historicConcept.concept.title;
              config.subtitle = this.translate.instant('common.historic');
              config.nameDocument = result.filename+'.pdf';
              config.orderType = 'creationDate';
              config.orderAsc = true;
              this.historicConcept.configurationPdf = config;
              let that = this;
              setTimeout(function () {
                  let htmlPrint = $('#pdf-historic-concept-list').html();
                  config.htmlPrint = htmlPrint;
                  that.exportService.toPdf(config);
              });
              break;
        }
      }else{
        if(result.result === 'previous') {
          this.exportList();
        }
      }

    })
  }
}
