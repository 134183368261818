import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { StateManagementFactory } from '@shared/factories/state-management/state-management.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';


@Component({
  selector: 'clients-tracking-dashboard',
  templateUrl: './clients-tracking-dashboard.component.html',
})
export class ClientsTrackingDashboardComponent implements OnInit, OnChanges {
  skin: string =  this.globalCfg.skin;
  clientsTracking: any = {
    clientsCount: 0,
    lastEvent: {},
    lastEvaluatedClient: [],
    nextTracings: []
  }
  constructor(private route: ActivatedRoute,
    private router: Router,
    private context: ContextFactory,
    private globalCfg: GlobalCfgFactory,
    private loader: LoaderFactory,
    private stateManagement: StateManagementFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private registerService: RegisterMixtapeService) { }

  @Input() product:any={};
  @Input() evaluationList:any={};
  @Input() queryProduct:any={};
  @Output() launchAction = new EventEmitter();

  ngOnInit(): void {
    this.getClients();
  }

  ngOnChanges(): void {
    if(this.evaluationList && this.evaluationList.length && !this.clientsTracking.configuratedClients){
        this.configureClientsTracking();
    }
  }

  getClients(){
    let productId = 'blanqueo';
    this.apiService.get(productId+ '/clients')
      .then((data:any)=>{
          this.configureEvaluatedClients(data);
          this.clientsTracking.lastEvaluatedClient = data.sort(this.orderList('creationDate'))[0];
          this.clientsTracking.clientsCount = data.length;
      });
  }

  orderList(type: any){
    return function(a: any, b:any) {
      if (a[type] < b[type]) {
        return 1;
      }
      return a[type] > b[type] ? -1 : 0;
    }
  }

  // orderListDate(a: any, b: any) {
  //     if (a.creationDate < b.creationDate) {
  //       return 1;
  //     }

  //     return a.creationDate > b.creationDate ? -1 : 0;
  // }

  configureEvaluatedClients(clientList:any){
    this.clientsTracking.evaluatedClients = [
      {
        color: this.skin === 'steel-blue'? '#00DA65' : '#77D166',
        keyColor: '#77D166',
        count: 0,
        order: 1
      },
      {
        color: this.skin === 'steel-blue'? '#EDC603' : '#F1BC3E',
        keyColor: '#F1BC3E',
        count: 0,
        order: 2
      },
      {
        color: this.skin === 'steel-blue'? '#FEA411' : '#F1923E',
        keyColor: '#F1923E',
        count: 0,
        order: 3
      },
      {
        color: this.skin === 'steel-blue'? '#E24751' : '#C43741',
        keyColor: '#C43741',
        count: 0,
        order: 4
      },
      {
        color: this.skin === 'steel-blue'? '#9399A6' : '#AAAAAA',
        keyColor: '#AAAAAA',
        count: 0,
        order: 5
      }
    ];
    clientList.forEach((client:any) => {
      if(client.proximoSeguimiento){
        this.clientsTracking.nextTracings.push(client);
      }
      if(!client.color){
        client.riesgo = this.translate.instant('anti-money-laundering-panel.risk-free');
        // client.color = this.skin === 'steel-blue'? '#9399A6' : '#AAAAAA';
        client.color = '#AAAAAA';
      }
      client.colorRGBARiesgo = 'rgba('+this.commonUtilsService.convertHexToRGB(client.color)+',0.36)';
      let newEvaluated = {
        color: client.color.toUpperCase(),
        colorRGBA: client.colorRGBARiesgo,
        risk: client.riesgo
      }
      this.configureBubblesCount(newEvaluated);
    });
  }

  configureBubblesCount(newEvaluated: any){
    this.clientsTracking.evaluatedClients.forEach((bubbleEvaluated:any) => {
      bubbleEvaluated.colorRGBA = 'rgba('+this.commonUtilsService.convertHexToRGB(bubbleEvaluated.color)+',0.36)';
      if(bubbleEvaluated.keyColor === newEvaluated.color){
        bubbleEvaluated.colorRGBA = newEvaluated.colorRGBA;
        bubbleEvaluated.risk = newEvaluated.risk;
        bubbleEvaluated.count++;
      }
    });
  }

  configureClientsTracking(){
    this.clientsTracking.configuratedClients = true;
    this.clientsTracking.clientsIdList = [];
    this.evaluationList.forEach((evaluation:any) => {
      if(!this.clientsTracking.clientsIdList.length){
          let evaluationAdd = {
              clientId: evaluation.clientId,
              clientName: evaluation.clientName,
              countEvaluations: 1
          }
          this.clientsTracking.clientsIdList.push(evaluationAdd);
      }else{
          this.findClient(evaluation);
      }
    });
    this.clientsTracking.clientsIdList = this.clientsTracking.clientsIdList.sort(this.orderList('countEvaluations'));
    this.configureListRecurrency();
  }

  findClient(evaluation: any){
    for(let [index, client] of this.clientsTracking.clientsIdList.entries()){
        if(client.clientId === evaluation.clientId){
            client.countEvaluations++;
            break;
        }
        if(index === this.clientsTracking.clientsIdList.length - 1){
            let evaluationAdd = {
                clientId: evaluation.clientId,
                clientName: evaluation.clientName,
                countEvaluations: 1
            }
            this.clientsTracking.clientsIdList.push(evaluationAdd);
            break;
        }
    }
  }

  configureListRecurrency(){
    let maxCount : any = this.clientsTracking.clientsIdList[0].countEvaluations;
    this.clientsTracking.listMoreRecurrency = [];
    for(let client of this.clientsTracking.clientsIdList){
        if(client.countEvaluations === maxCount){
          this.clientsTracking.listMoreRecurrency.push(client);
        }else{
            break;
        }
    }
  }

  // orderCount(a: any, b: any) {
  //   if (a.countEvaluations < b.countEvaluations) {
  //     return 1;
  //   }
  //   return a.countEvaluations > b.countEvaluations ? -1 : 0;
  // }

  goTo(option: any){
    this.loader.openLoader('open-clients-tracking-window');
    this.stateManagement.setStateUrl(window.location.pathname);
    this.router.navigate([option]);
  }

  viewQueryKnowledge(){
    this.launchAction.emit({
        action: 'openKnowledge',
        evaluation: this.queryProduct
      });
  }

}
