import moment from "moment";

import { Injectable } from '@angular/core';
import { cloneDeep } from "lodash";
import ExpandMenu from '../../../../../assets/data/expanded-menu.json'
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { ExpandedMenuService } from '@shared/services/expanded-menu/expanded-menu.service';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { DocumentActionsService } from '@shared/services/document-actions/document-actions.service';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { ReportUtilsService } from "@shared/services/report-utils/report-utils.service";
import { DownloadService } from "@shared/services/download/download.service";
import { ExportServiceService } from "@shared/services/export-service/export-service.service";

@Injectable({
  providedIn: 'root'
})
export class ReportRecordDetailService {
  filterDocumentationList: any = [];
  constructor(private filterColumnService: FilterColumnService,
    private dateConverterService: DateConverterService,
    private commonUtilsService: CommonUtilsService,
    private userPermisions: UserPermisionsFactory,
    private apiService: ApiServiceService,
    private loader: LoaderFactory,
    private expandedMenuService: ExpandedMenuService,
    private translate: AfTranslateFactory,
    private modalService: ModalServiceService,
    private documentActions: DocumentActionsService,
    private context: ContextFactory,
    private reportUtilsService: ReportUtilsService,
    private downloadService: DownloadService,
    private exportService: ExportServiceService) { }


  configureColumns(child:any) {
      let that: any = child.reportRecordDetail;
      that.hasOverflow = false;
      let columnsWidth:number = 0;
      let tableWidth:any = $('.report-record-detail__header').width();

      child.columns.forEach((column:any) => {
        if(column.name && column.name !=="conceptObjectId"){
          column.id = this.configureId(column.name);
          child.rows = this.replaceInRow(column, child.rows, child);
        }

        if(column.filterType !== null && Number.isInteger(column.filterType)){
            this.extractFilterType(column, child);
        }


        if(column.fieldTypeId === 'date'){
            this.configureDateRows(child.rows, column, child);
        }

        this.calculateColumnWidth(column, child);
        columnsWidth += column.percent;
      });

      if (child.skin != 'icam-red') {
          for (let i = 0; i < child.columns.length; i++) {
              let columnPercent = 0
              let column = child.columns[i];
              columnPercent = ((column.percent * 100) / columnsWidth);
              if ((columnPercent / 100) * tableWidth >= column.min) {
                  column.displayWidth = columnPercent - 1 + '%';
                  column.margin = '0px 0.5%';
              } else {
                  let columnWidth : any = column.min - (child.columns.length * 2);
                  if(columnWidth < column.min){
                      columnWidth = column.min;
                  }
                  column.displayWidth = columnWidth + 'px';
                  column.margin = '0px 7px';

                  that.hasOverflow = true;
              }

              if (i === child.columns.length - 1) {
                  that.showEllipsis = true;
              }
          }
          this.configureRows(child);
          that.reportRecordDetailList = cloneDeep(child.rows)
          that.filterReportRecordDetailList = that.reportRecordDetailList
        //   that.filters.forEach((filter:any) => {
        //     that[filter+'List'] = this.filterColumnService.extractList(filter, that.filterReportRecordDetailList);
        //     that[filter+'ListFilter'] = [];
        // });

      }
  }

  replaceInRow(column:any, rows:any, child:any){
    rows.forEach((row:any) => {
      for( let [key, value] of Object.entries(row)){
        if(column.name.toLowerCase() === key.toLowerCase()){
          row[column.id] = value;
        }
      }
    })
    return rows;
  }

  extractFilterType(column:any, child:any){
    let that: any = child.reportRecordDetail;
      switch (column.columnType) {
          case '3':
          case '4':
              if (child.skin === 'icam-red') {
                  column.filterType = "list";
                  column.rows = that.detailsView? that.detailsFilter : that.reportsListFilter;

              } else {
                  column.filterType = "listFind";
                  that[column.id + 'InputFilter'] = '';
                  column.rows = child.rows;
              }
              that.filters.push(column.id);
              that[column.id+'List'] = this.filterColumnService.extractList(column.id, column.rows);
              that[column.id+'ListFilter'] = [];
              break;
          case '2':
              column.filterType = "date";
              that.dateFilters.push(column.id);
              break;
          default:
              column.filterType = "find";
              that['search'+column.id] = '';
              that.findFilters.push(column.id)
              break;
      }
  }

  configureDateRows(rows: any, column: any, child:any){
    rows.forEach((row:any) => {
      if(row[column.id] && row[column.id] !== null && row[column.id].indexOf('/') !== -1){
        let extractDate : any = row[column.id].split('/');
        row[column.id] = this.dateConverterService.toIso(new Date(extractDate[1]+'/'+extractDate[0]+'/'+extractDate[2]));
    }
    });
  }

  calculateColumnWidth(column:any, child:any) {
      column.percent = 100 / (column.id === 'method' ? (child.columns.length - 1) : ((child.columns.length - 1) / 0.8));
      column.min = 100;
  }

  configureRows(child:any){
    child.rows.forEach((row: any) => {
      this.commonUtilsService.configureReportRow(row, child.columns);
    });
  }


  getProductPermissions(productId:any, child:any){
      this.commonUtilsService.getProductPermissions(productId, this.apiService)
        .then((data:any)=> {
          child.reportRecordDetail.userPermissions = this.userPermisions.getPermissions();
        })
  }

  getReportData(child:any){
      this.apiService.get('reportobjects/data', child.report.reportObjectId)
          .then((data:any)=>{
            child.rows = data;
      }, (errorData:any)=>{ })
  }


  configureId(name:any){
      switch(name.toLowerCase()){
          case 'asunto':
              return 'detail';
          case 'fecha de incidencia':
              return 'incidentDate';
          case 'fecha de entrada':
              return 'entranceDate';
          case 'tipología':
              return 'tipologia';
          case 'referencia':
              return 'reference';
          case 'fecha de resolución':
              return 'resolutionDate';
          case 'método de seguimiento, medición y análisis':
              return 'method';
          case 'resolución':
              return 'resolution';
          case 'organización':
              return 'organizacion'
          case 'canal':
              return 'channel';
          default:
              return name;
      }
  }

  configureDate(dateString:any){
      if(dateString === null){
          return '';
      }
      let datesplit:any = dateString.split('/');
      return moment.utc(datesplit[1]+'/'+datesplit[0]+'/'+datesplit[2]).format();
  }

  getDocumentationList(child:any){
      this.apiService.get('reportdocuments', child.report.reportObjectId)
          .then((data:any)=>{
              child.reportRecordDetail.documentationList = data;
              child.reportRecordDetail.filterDocumentationList = child.reportRecordDetail.documentationList;
              this.filterDocumentationList = child.reportRecordDetail.documentationList;
              this.loader.closeLoader(['report-open-report', 'dashboard-panel-new-report', 'front-login-login']);
      }, (errorData:any)=>{})
  }

  getExpandedMenu(child:any){
    let menuOptionsDocument : any = ExpandMenu['menuDocumentReportRecord'].menu;
    child.reportRecordDetail.menuOptionsDocument = this.expandedMenuService.menuDocumentationReports(menuOptionsDocument);
  }

  getFilters(list:any, child:any){
        child.reportRecordDetail.documentFilters.forEach((filter:any) => {
            child.reportRecordDetail[filter+'List'] = this.filterColumnService.extractList(filter, list);
            child.reportRecordDetail[filter+'ListFilter'] = [];
        });
  }

  filterReport(child:any){
    let filterList: any = child.reportRecordDetail.reportRecordDetailList;
    child.reportRecordDetail.findFilters.forEach((type:any)=>{
      filterList = this.filterColumnService.filterByWord(child.reportRecordDetail['search'+type], filterList , type.toLowerCase());
    })
    child.reportRecordDetail.filters.forEach((filter:any) => {
        let filteredList = child.reportRecordDetail[filter+'ListFilter'];
        filterList = this.filterColumnService.filterBycolumn(filterList, filteredList, filter);
    })
    child.reportRecordDetail.dateFilters.forEach((type:any)=>{
      let dates: any = {
        from: child.reportRecordDetail[type+'FromDate'],
        to: child.reportRecordDetail[type+'ToDate'],
      }
      if(dates.from !== null && dates.to !== null){
        filterList = this.filterColumnService.filterByRangeDates(filterList, type, dates);
      }
    })

    child.reportRecordDetail.filterReportRecordDetailList = filterList;
  }

  resetFilter(e:any, type:any, child:any){
      e.stopPropagation();
      e.preventDefault();
      child.reportRecordDetail['search'+type] = '';
      this.filterReport(child);
  }

  orderDate(type:any, child:any) {
      if(child.reportRecordDetail.dateOrder === type){
          child.reportRecordDetail.orderOperationDates = !child.reportRecordDetail.orderOperationDates;
      }else{
          child.reportRecordDetail.dateOrder = type;
          child.reportRecordDetail.orderOperationDates = true;
      }
  }

  selectTypeOperation(item:any, column:any, child:any){
      let listFilter:any = child.reportRecordDetail[column.id+'ListFilter'];
      child.reportRecordDetail[column.id+'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
      item.selected = !item.selected;
      column.hasFilter = child.reportRecordDetail[column.id+'ListFilter'].length;
      this.filterReport(child);
  }

  selectTypeOperationDocumentation(item:any, type:any, child:any){
    let listFilter:any = child.reportRecordDetail[type+'ListFilter'];
    child.reportRecordDetail[type+'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
    item.selected = !item.selected;
    if(child.reportRecordDetail[type+'Filter']){
        child.reportRecordDetail[type+'Filter'].hasFilter = child.reportRecordDetail[type+'ListFilter'].length;
    }
    this.filterReportDocument(type, child);
  }

  changeViewWindow(child:any){
      sessionStorage.removeItem('reportIdSelected')
      this.commonUtilsService.goToStateUrl();
  }

  relatedConcept(report:any, child:any) {
    this.apiService.get('conceptobjects', report.conceptObjectId)
        .then((data:any)=> {
            let to : any = '/evaluation/' + data.evaluationId +
            '/folder/' + data.folderId +
            '/concept/' + data.conceptId +
            '/concept-evaluated/' + data.conceptObjectId;
            window.open(to, '_blank');
        }, ()=>{})
  }

  updateReport(child:any){
      let text : string = this.translate.instant('documentation-list.update-report-data').replace('{report}',  child.report.title);
      this.modalService.adviceModal(text, 'info:question:question')
        .subscribe((result:any)=>{
          if(result.result === 'ok'){
            this.refreshReport(child);
          }
        })
  }

  refreshReport(child:any){
      let itemSelected : any = {
          type: 'refresh-report',
          item: child.report
      }
      this.modalService.informativeLoadingModal(itemSelected)
        .subscribe((result:any)=>{
          if(result.result === 'ok'){
            this.getReportData(child);
            this.getDocumentationList(child);
            child.reportRecordDetail.selectedView = {
              name: this.translate.instant('report-table.view-list'),
              viewOption: 'detail'
            }
            child.reportRecordDetail.viewTableHtml = child.reportRecordDetail.selectedView.viewOption
          }
        })
  }

  exportReport(child:any, filterReportRecordDetailList: any) {
      this.modalService.exportFormatModal(this.translate.instant('report-record-detail.export-report'))
        .subscribe((formatResult:any) => {
          if(formatResult.result === 'ok'){
            let configuration: any = {
              list: child.reportRecordDetail.documentationList,
              hasPrevious: true,
              title: this.translate.instant('report-record-detail.export-report'),
              extension: formatResult.selectedFormat
            }
            this.modalService.exportModal(configuration)
              .subscribe((result:any) => {
                if(result.result === 'ok'){
                  result.format = formatResult.selectedFormat;
                  let list: any = result.format === 'xls'?child.rows:filterReportRecordDetailList;
                  this.generateReport(result, list, child);
                }else if(result.result ==='previous'){
                  this.exportReport(child, filterReportRecordDetailList);
                }
              })
          }
        })
  }

  generateReport(result:any, list: any, child:any){
    if(child.report.excelCharts > 0){
      this.generateBackReport(result, child);
    }else{
      result.list = list;
      result.reportColumns = child.columns;
      result.reportTitle = child.report.title;
      result.reportObjectId = child.report.reportObjectId;
      result.idPdf = 'pdf-report-record-detail';
      this.generateFrontReport(result, child);
    }
  }

  generateFrontReport(result: any, child: any){
    let config: any = this.reportUtilsService.generateFrontReport(result);
    config.successCallback = (uploadResponse:any, blob:any) => {
        delete child.reportRecordDetail.configurationPdf;
        this.modalService.adviceModal(this.translate.instant('report-info.export-report-ok') + '"' + result.filename + '". ' + this.translate.instant('report-info.info-export-documentation'), 'info:accept:check-round');
        child.reportRecordDetail.documentationList.push(uploadResponse);
        if(result.downloadFile){
            this.downloadService.triggerDownload(blob, config.nameDocument);
        }
        if(result.openFile){
            result.format === 'xls'?this.documentActions.doAction('open', uploadResponse):window.open(URL.createObjectURL(blob), '_blank');
        }
    }
    switch (result.format) {
      case 'xls':
          this.exportService.toXls(config);
          break;
      case 'pdf':
          config.exportDate = this.dateConverterService.setTimezone(new Date());
          child.reportRecordDetail.configurationPdf = config;
          let that = this;
          setTimeout(function () {
              let htmlPrint = $('#'+result.idPdf).html();
              config.htmlPrint = htmlPrint;
              that.exportService.toPdf(config);
          });
          break;
    }
  }

  generateBackReport(newDocumentAdded:any, child: any) {
      let url:string = 'reportobjects/excel/';
      let newDocument:any = {
          name: newDocumentAdded.filename
      };
      this.loader.openLoader('report-info-report-excel');
      this.apiService.add(url + child.report.reportObjectId + (newDocumentAdded.format == 'pdf' ? '/1' : ''), newDocument)
        .then((data:any)=>{
          let text = this.translate.instant('report-info.export-report-ok') + '"' + newDocument.name + '". ' + this.translate.instant('report-info.info-export-documentation')
          this.modalService.adviceModal(text, 'info:accept:check-round');
          if (newDocumentAdded.downloadFile) {
              this.documentActions.doAction('download', data);
          }
          if (newDocumentAdded.openFile) {
              setTimeout(()=>{
                this.documentActions.doAction('open', data);
              }, 500);
          }
          child.reportRecordDetail.documentationList.push(data);
          this.loader.closeLoader(['report-info-report-excel']);
      }, (errorData:any)=>{
          this.loader.closeError();
      });
  }

  launchActionOption(option:string, documentSelected:any, child:any){
      switch(option){
          case 'view':
              this.documentActions.doAction('open', documentSelected);
              break;
          case 'delete':
              this.deleteQuestionModal(documentSelected, child);
              break;
          case 'anonymatum-document':
                if(this.context.anonymatumService){
                    this.adviseAnonymatum(documentSelected, child);
                }else{
                    this.modalService.adviceModal(this.translate.instantSiteTerms('anonymatum-service-no-adquired'), 'error:accept:warning',  '', [], '', true)
                }
              break;
          case 'download':
              this.documentActions.doAction('download', documentSelected);
              break;
      }
  }

  deleteQuestionModal(documentSelected:any, child:any){
      this.modalService.adviceModal(this.translate.instant('report-info.delete-document-question')+documentSelected.name+' ?', 'info:question:question')
        .subscribe((result:any)=>{
          if(result.result === 'ok'){
            this.loader.openLoader('report-record-detail-delete-document');
            this.deleteDocumentReport(documentSelected, child);
          }
        })
  }

  getFilterDocumentationList(){
    return this.filterDocumentationList;
  }

  deleteDocumentReport(documentSelected:any, child:any){
      this.apiService.delete('reportdocuments', documentSelected.reportDocumentId)
          .then((data:any)=>{
              child.reportRecordDetail.documentationList = this.commonUtilsService.deleteElement(documentSelected, child.reportRecordDetail.documentationList, 'reportDocumentId');
              this.getFilters(child.reportRecordDetail.filterDocumentationList, child);
              this.filterReportDocument('Name', child)
              this.loader.closeLoader(['report-record-detail-delete-document']);
              let text:string = this.translate.instant('report-info.delete-document').replace('{document}',  documentSelected.name);
              this.modalService.adviceModal(text, 'info:accept:check-round');
          }, (errorData:any)=>{
              this.loader.closeError()
          })
  }

  adviseAnonymatum(document:any, child:any){
    this.modalService.adviceModal(this.translate.instant('documentation-list.anonymatum-advise'), 'info:question:question')
      .subscribe((result:any)=>{
        if(result.result==='ok'){
          this.anonymatumDocument(document, child);
        }
      })
  }

  anonymatumDocument(document:any, child:any){
    let itemSelected : any = {
        type: 'anonymatum-report',
        item: document,
    }
    this.modalService.informativeLoadingModal(itemSelected)
      .subscribe((result:any)=>{
        if(result.result ==='ok'){
          let index = child.reportRecordDetail.filterDocumentationList.findIndex((item:any)=>{return result.item.reportDocumentId === item.reportDocumentId});
          if(index !== -1){
            child.reportRecordDetail.filterDocumentationList.splice(index, 1, result.item)
          }
          this.getFilters(child.reportRecordDetail.filterDocumentationList, child);
          this.filterReportDocument('Name', child)
          this.modalService.adviceModal(this.translate.instant('documentation-list.anonymatum-document-ok'), 'info:accept:check-round')
        }else if(result?.error?.description ==="anonymize_no_credits"){
          this.modalService.adviceModal(this.translate.instantSiteTerms('anonymatum-service-no-credits'), 'error:accept:warning',  '', [], '', true)
        }else{
          this.modalService.adviceModal([this.translate.instant('documentation-list.anonymatum-document-error'), this.translate.instant('documentation-list.anonymatum-document-causes')], 'error:error-link:warning')
        }
      })
  }
  filterReportDocument(type:any, child:any){
      let filterList:any = [];
      let selectedType:any = type === 'modifiedBy'?'Name':type;
      filterList = this.filterColumnService.filterByWord(child.reportRecordDetail['search'+selectedType],  child.reportRecordDetail.documentationList, type.toLowerCase());
      child.reportRecordDetail.documentFilters.forEach((filter:any) => {
          let filteredList = child.reportRecordDetail[filter+'ListFilter'];
          filterList = this.filterColumnService.filterBycolumn(filterList, filteredList, filter);
      })
      child.reportRecordDetail.filterDocumentationList = filterList;
  }
}





