import { Injectable } from '@angular/core';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Injectable({
  providedIn: 'root'
})
export class AdviceModalParentService {

  constructor(private translate: AfTranslateFactory,
    private modalService: ModalServiceService) {
    }
  configureModal(child: any){
    child.adviceModal.from = child.from;
    child.adviceModal.errorTranslate = this.translate.instant('users-admin.import.error-'+child.from);
    let type = child.adviceType.split(':');
    child.adviceModal.typeModal = type[0];
    child.adviceModal.modalQuestion = type[1];
    let typeIcon = type[2];
    if(child.isLanding){
      if(typeIcon === 'advice-full'){
        typeIcon = 'info-full';
      }else if(typeIcon === 'warning' || typeIcon === 'mandatory-field'){
        typeIcon = 'close-round-full';
      }
    }
    child.adviceModal.confirmQuestionIcon = 'lf-icon-'+typeIcon;
    child.adviceModal.typeText = 'normal';
    if(child.adviceModal.modalQuestion === 'accept-link'){
      child.adviceModal.typeText = 'link';
      child.adviceModal.modalQuestion = 'accept';
      let initLink = child.questionText.indexOf('<button-link>');//+13
      let finishLink = child.questionText.indexOf('</button-link>');//+14
      child.adviceModal.firstQuestionText = child.questionText.slice(0, initLink);
      child.adviceModal.buttonLink = child.questionText.slice(initLink+13, finishLink);
      child.adviceModal.lastQuestionText = child.questionText.slice(finishLink+14, child.questionText.length);
      child.adviceModal.fromInfo = type[3];
    } else if(child.adviceModal.modalQuestion === 'error-link'){
      child.adviceModal.typeText = 'link';
      child.adviceModal.modalQuestion = 'accept';
      child.adviceModal.firstQuestionText = child.questionText[0];
      child.adviceModal.buttonLink = child.questionText[1];
      child.adviceModal.fromInfo = child.adviceModal.modalQuestion;
    }
  }

  openInfoModal(child: any){
    let configuration: any = {
      fromInfo: child.adviceModal.fromInfo
    };
    this.modalService.modalMoreInfo(configuration);
  }
}
