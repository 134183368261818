import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuIconsService {
  menuIcons:any = [{
    productId:"one",
      icons:[{
          id:"01",
          icon:"lf-icon-lead",
          iD_Version00:13753,
          iD_Version01:1

    },{
          id:"02",
          icon:"lf-icon-build",
          iD_Version00:13758,
          iD_Version01:2

    },{
          id:"03",
          icon:"lf-icon-danger",
          iD_Version00:13762,
          iD_Version01:3

    },{
          id:"04",
          icon:"lf-icon-shield",
          iD_Version00:13763,
          iD_Version01:4

    },{
          id:"05",
          icon:"lf-icon-audit-products",
          iD_Version00:13764,
          iD_Version01:5

    },{
          id:"06",
          icon:"lf-icon-lock",
          iD_Version00:382629,
          iD_Version01:382629

    },{
          id:"07",
          icon:"lf-icon-group",
          iD_Version00:13765,
          iD_Version01:6

    },{
          id:"08",
          icon:"lf-icon-review",
          iD_Version00:13766,
          iD_Version01:7

    },{
          id:"09",
          icon:"lf-icon-improvement",
          iD_Version00:13767,
          iD_Version01:8

    },{
          id:"10",
          icon:"lf-icon-numbered-list",
          iD_Version00:342085,
          iD_Version01:342085

    },{
          id:"11",
          icon:"lf-icon-search-rrhh",
          iD_Version00:251176,
          iD_Version01:251176

    }]
},{
    productId:"centF",
      icons:[{
          id:"01",
          icon:"lf-icon-lead",
          iD_Version00:47792,
          iD_Version01:59

    },{
          id:"02",
          icon:"lf-icon-build",
          iD_Version00:48236,
          iD_Version01:60

    },{
          id:"03",
          icon:"lf-icon-danger",
          iD_Version00:47794,
          iD_Version01:61

    },{
          id:"04",
          icon:"lf-icon-shield",
          iD_Version00:47797,
          iD_Version01:63

    },{
          id:"05",
          icon:"lf-icon-lock",
          iD_Version00:47795,
          iD_Version01:62

    },{
          id:"06",
          icon:"lf-icon-group",
          iD_Version00:47796,
          iD_Version01:64

    },{
          id:"07",
          icon:"lf-icon-review",
          iD_Version00:47798,
          iD_Version01:65

    },{
          id:"08",
          icon:"lf-icon-improvement",
          iD_Version00:47799,
          iD_Version01:66

    }]
},{
    productId:"cpda",
      icons:[{
          id:"01",
          icon:"lf-icon-lead",
          iD_Version00:70512,
          iD_Version01:9845

    },{
          id:"02",
          icon:"lf-icon-build",
          iD_Version00:70514,
          iD_Version01:9846

    },{
          id:"03",
          icon:"lf-icon-danger",
          iD_Version00:70513,
          iD_Version01:9847

    },{
          id:"04",
          icon:"lf-icon-shield",
          iD_Version00:70516,
          iD_Version01:9848

    },{
          id:"05",
          icon:"lf-icon-lock",
          iD_Version00:70515,
          iD_Version01:9849

    },{
          id:"06",
          icon:"lf-icon-group",
          iD_Version00:70517,
          iD_Version01:9850

    },{
          id:"07",
          icon:"lf-icon-review",
          iD_Version00:70518,
          iD_Version01:9851

    },{
          id:"08",
          icon:"lf-icon-improvement",
          iD_Version00:70519,
          iD_Version01:9852

    }]
},{
    productId:"fiscal",
      icons:[{
          id:"01",
          icon:"lf-icon-is",
          iD_Version00:"",
          iD_Version01:""

    },{
          id:"02",
          icon:"lf-icon-iva",
          iD_Version00:"",
          iD_Version01:""

    },{
          id:"03",
          icon:"lf-icon-money-bag",
          iD_Version00:"",
          iD_Version01:""

    },{
          id:"04",
          icon:"lf-icon-money-localization",
          iD_Version00:"",
          iD_Version01:""

    },{
          id:"05",
          icon:"lf-icon-hand-sphere",
          iD_Version00:"",
          iD_Version01:""

    },{
          id:"06",
          icon:"lf-icon-irpf",
          iD_Version00:"",
          iD_Version01:""

    },{
          id:"07",
          icon:"lf-icon-special-tax",
          iD_Version00:"",
          iD_Version01:""

    },{
          id:"08",
          icon:"lf-icon-money-world",
          iD_Version00:"",
          iD_Version01:""

    },{
          id:"09",
          icon:"lf-icon-hand-plant",
          iD_Version00:"",
          iD_Version01:""

    },{
          id:"10",
          icon:"lf-icon-balance",
          iD_Version00:"",
          iD_Version01:""

    }]

},{
    productId:"bpt002",
      icons:[{
          id:"01",
          icon:"lf-icon-is",
          iD_Version00:327005,
          iD_Version01:327005

    },{
          id:"02",
          icon:"lf-icon-iva",
          iD_Version00:327009,
          iD_Version01:327009

    },{
          id:"03",
          icon:"lf-icon-money-bag",
          iD_Version00:327010,
          iD_Version01:327010

    },{
          id:"04",
          icon:"lf-icon-money-localization",
          iD_Version00:327011,
          iD_Version01:327011

    },{
          id:"05",
          icon:"lf-icon-hand-sphere",
          iD_Version00:327012,
          iD_Version01:327012

    },{
          id:"06",
          icon:"lf-icon-special-tax",
          iD_Version00:327013,
          iD_Version01:327013

    },{
          id:"07",
          icon:"lf-icon-money-world",
          iD_Version00:327014,
          iD_Version01:327014

    },{
          id:"08",
          icon:"lf-icon-hand-plant",
          iD_Version00:327015,
          iD_Version01:327015

    },{
          id:"09",
          icon:"lf-icon-balance",
          iD_Version00:327016,
          iD_Version01:327016

    }]

},{
    productId:"bpt",
      icons: [{
        id: "01",
        icon: "lf-icon-build",
        iD_Version00: 38514,
        iD_Version01: 16405

    },{
        id: "02",
        icon: "lf-icon-lead",
        iD_Version00: 38516,
        iD_Version01: 16406

    },{
        id: "02",
        icon: "lf-icon-compliance",
        iD_Version00: 40471,
        iD_Version01: 40471
    },{
        id: "03",
        icon: "lf-icon-example",
        iD_Version00: 38517,
        iD_Version01: 16407

    },{
        id: "04",
        icon: "lf-icon-communication",
        iD_Version00: 38518,
        iD_Version01: 16408

    },{
        id: "05",
        icon: "lf-icon-shield",
        iD_Version00: 69651,
        iD_Version01: 16409

    },{
        id: "06",
        icon: "lf-icon-documentation",
        iD_Version00: 69664,
        iD_Version01: 16410

    },{
        id: "07",
        icon: "lf-icon-balance",
        iD_Version00: 69667,
        iD_Version01: 16411

    },{
        id: "08",
        icon: "lf-icon-review",
        iD_Version00: 69668,
        iD_Version01: 24335

    },{
        id: "09",
        icon: "lf-icon-improvement",
        iD_Version00: 69669,
        iD_Version01: 23471

    },{
        id: "10",
        icon: "lf-icon-practical-cases",
        iD_Version00: 69672,
        iD_Version01: 16414

    },{
        id: "11",
        icon: "lf-icon-certificate",
        iD_Version00: 69670,
        iD_Version01: 23475

    },{
        id: "12",
        icon: "lf-icon-transparency-report",
        iD_Version00: 69671,
        iD_Version01: 16413
    },{
        id: "14",
        icon: "lf-icon-news-communication",
        iD_Version00: 482404,
        iD_Version01: 482404
    }
  ]

},{
    productId:"penal003",
      icons:[{
          id:"01",
          icon:"lf-icon-lead",
          iD_Version00:68228,
          iD_Version01:68228

    },{
          id:"02",
          icon:"lf-icon-build",
          iD_Version00:68229,
          iD_Version01:68229

    },{
          id:"03",
          icon:"lf-icon-calendar",
          iD_Version00:68230,
          iD_Version01:68230

    },{
          id:"04",
          icon:"lf-icon-shield",
          iD_Version00:68231,
          iD_Version01:68231

    },{
          id:"05",
          icon:"lf-icon-book",
          iD_Version00:68232,
          iD_Version01:68232

    },{
          id:"06",
          icon:"lf-icon-news-communication",
          iD_Version00:68233,
          iD_Version01:68233

    },{
          id:"07",
          icon:"lf-icon-improvement",
          iD_Version00:68234,
          iD_Version01:68234

    }]

},{
    productId:"penal",
      icons:[{
          id:"01",
          icon:"lf-icon-lead",
          iD_Version00:38507,
          iD_Version01:20507

    },{
          id:"02",
          icon:"lf-icon-build",
          iD_Version00:38508,
          iD_Version01:20508

    },{
          id:"03",
          icon:"lf-icon-shield",
          iD_Version00:38509,
          iD_Version01:20509

    },{
          id:"04",
          icon:"lf-icon-calendar",
          iD_Version00:38510,
          iD_Version01:20510

    },{
          id:"05",
          icon:"lf-icon-book",
          iD_Version00:38511,
          iD_Version01:20511

    },{
          id:"06",
          icon:"lf-icon-news-communication",
          iD_Version00:38512,
          iD_Version01:20512

    },{
          id:"07",
          icon:"lf-icon-improvement",
          iD_Version00:38513,
          iD_Version01:20513

    }]

},{
    productId:"vithas",
      icons:[{
          id:"01",
          icon:"lf-icon-license",
          iD_Version00:75035,
          iD_Version01:20547

    },{
          id:"02",
          icon:"lf-icon-build",
          iD_Version00:75070,
          iD_Version01:20548

    },{
          id:"03",
          icon:"lf-icon-concursal",
          iD_Version00:75082,
          iD_Version01:20549

    },{
          id:"04",
          icon:"lf-icon-bill",
          iD_Version00:75084,
          iD_Version01:20553

    },{
          id:"05",
          icon:"lf-icon-service-contract",
          iD_Version00:75091,
          iD_Version01:20552

    },{
          id:"06",
          icon:"lf-icon-handshake",
          iD_Version00:75099,
          iD_Version01:20550

    },{
          id:"07",
          icon:"lf-icon-intellectual",
          iD_Version00:75101,
          iD_Version01:20551

    },{
          id:"08",
          icon:"lf-icon-compliance",
          iD_Version00:75106,
          iD_Version01:20554

    },{
          id:"09",
          icon:"lf-icon-communication",
          iD_Version00:75351,
          iD_Version01:20555

    },{
          id:"10",
          icon:"lf-icon-organization",
          iD_Version00:75115,
          iD_Version01:20556

    },{
          id:"11",
          icon:"lf-icon-documentation",
          iD_Version00:75119,
          iD_Version01:20557

    },{
          id:"12",
          icon:"lf-icon-health",
          iD_Version00:75146,
          iD_Version01:20558

    },{
          id:"13",
          icon:"lf-icon-review",
          iD_Version00:75151,
          iD_Version01:20559

    },{
          id:"14",
          icon:"lf-icon-balance",
          iD_Version00:75152,
          iD_Version01:20560

    },{
          id:"15",
          icon:"lf-icon-lock",
          iD_Version00:75153,
          iD_Version01:20561
    }]

},{
    productId:"security",
      icons:[{
          id:"01",
          icon:"lf-icon-handshake",
          iD_Version00:65847,
          iD_Version01:""
    },{
          id:"02",
          icon:"lf-icon-signature",
          iD_Version00:65846,
          iD_Version01:""
    }]

},{
    productId:"cm",
      icons:[{
          id:"01",
          icon:"lf-icon-bill",
          iD_Version00:229004,
          iD_Version01:229004
    },{
          id:"02",
          icon:"lf-icon-consulting",
          iD_Version00:0,
          iD_Version01:0
    },{
          id:"03",
          icon:"lf-icon-documentation",
          iD_Version00:237253,
          iD_Version01:237253
    },{
          id:"04",
          icon:"lf-icon-signature",
          iD_Version00:9904,
          iD_Version01:9904
    },{
          id:"05",
          icon:"lf-icon-registro-mercantil",
          iD_Version00:0,
          iD_Version01:0
    },{
          id:"06",
          icon:"lf-icon-camara-comercio",
          iD_Version00:"",
          iD_Version01:0
    },{
          id:"07",
          icon:"lf-icon-handshake",
          iD_Version00:9905,
          iD_Version01:9905
    },{
          id:"08",
          icon:"lf-icon-hand-sphere",
          iD_Version00:0,
          iD_Version01:0
    }]

},{
    productId:"cm",
      icons:[{
          id:"01",
          icon:"lf-icon-bill",
          iD_Version00:229004,
          iD_Version01:229004
    },{
          id:"02",
          icon:"lf-icon-consulting",
          iD_Version00:0,
          iD_Version01:0
    },{
          id:"03",
          icon:"lf-icon-documentation",
          iD_Version00:237253,
          iD_Version01:237253
    },{
          id:"04",
          icon:"lf-icon-signature",
          iD_Version00:9904,
          iD_Version01:9904
    },{
          id:"05",
          icon:"lf-icon-registro-mercantil",
          iD_Version00:0,
          iD_Version01:0
    },{
          id:"06",
          icon:"lf-icon-camara-comercio",
          iD_Version00:"",
          iD_Version01:0
    },{
          id:"07",
          icon:"lf-icon-handshake",
          iD_Version00:9905,
          iD_Version01:9905
    },{
          id:"08",
          icon:"lf-icon-hand-sphere",
          iD_Version00:0,
          iD_Version01:0
    }]

},{
      productId:"concursal",
      icons:[{
          id:"01",
          icon:"lf-icon-requester",
          iD_Version00:365091,
          iD_Version01:365091
    },{
          id:"02",
          icon:"lf-icon-balance",
          iD_Version00:373764,
          iD_Version01:373764
    },{
          id:"03",
          icon:"lf-icon-subject",
          iD_Version00:409880,
          iD_Version01:409880
    }]
},{
    productId:"concurs002",
      icons:[{
          id:"01",
          icon:"lf-icon-debtor-checklist",
          iD_Version00:457575,
          iD_Version01:457575
    },{
          id:"02",
          icon:"lf-icon-creditor-checklist",
          iD_Version00:503781,
          iD_Version01:503781
    },{
          id:"03",
          icon:"lf-icon-handshake",
          iD_Version00:458791,
          iD_Version01:458791
    },{
          id:"04",
          icon:"lf-icon-price",
          iD_Version00:518135,
          iD_Version01:518135
    },{
          id:"05",
          icon:"lf-icon-concursal",
          iD_Version00:504138,
          iD_Version01:504138
    },{
          id:"06",
          icon:"lf-icon-concursal",
          iD_Version00:538443,
          iD_Version01:538443
    },{
          id:"07",
          icon:"lf-icon-balance",
          iD_Version00:396782,
          iD_Version01:396782
    }]
},{
    productId:"covid",
      icons:[{
          id:"01",
          icon:"lf-icon-build",
          iD_Version00:435064,
          iD_Version01:435064
    },{
          id:"02",
          icon:"lf-icon-health",
          iD_Version00:435783,
          iD_Version01:435783
    },{
          id:"03",
          icon:"lf-icon-shield",
          iD_Version00:435065,
          iD_Version01:435065
    }]

},{
    productId:"blanqueo",
    icons:[{
        id:"01",
        icon:"lf-icon-health",
        iD_Version00:348975,
        iD_Version01:348975,
        folderNamedKey: "SujetoObligado"
    },{
        id:"02",
        icon:"lf-icon-build",
        iD_Version00:1058302,
        iD_Version01:1058302,
        folderNamedKey: "Cliente"
    },{
        id:"03",
        icon:"lf-icon-all",
        iD_Version00:750298,
        iD_Version01:750298,
        folderNamedKey: "Operacion"
    },{
        id:"04",
        icon:"lf-icon-transparency-report",
        iD_Version00: 289707,
        iD_Version01: 289707,
        folderNamedKey: "ExamenEspecial"
    },{
        id: "05",
        icon: "lf-icon-shield",
        iD_Version00: 793943,
        iD_Version01: 793943,
        folderNamedKey: "DiligenciasReforzadas"
    },{
        id:"06",
        icon:"lf-icon-search-rrhh",
        iD_Version00:289708,
        iD_Version01:289708
    }]

},{
    productId:"adaptalia",
      icons:[{
          id:"01",
          icon:"lf-icon-lead",
          iD_Version00:441363,
          iD_Version01:441363
    },{
          id:"02",
          icon:"lf-icon-build",
          iD_Version00:441364,
          iD_Version01:441364
    },{
          id:"03",
          icon:"lf-icon-danger",
          iD_Version00:441365,
          iD_Version01:441365
    },{
          id:"04",
          icon:"lf-icon-review",
          iD_Version00:441366,
          iD_Version01:441366
    },{
          id:"05",
          icon:"lf-icon-audit-products",
          iD_Version00:458984,
          iD_Version01:458984
    },{
          id:"06",
          icon:"lf-icon-group",
          iD_Version00:441367,
          iD_Version01:441367
    },{
          id:"07",
          icon:"lf-icon-search-rrhh",
          iD_Version00:441368,
          iD_Version01:441368
    }]

},{
    productId:"igualdad",
      icons:[{
          id:"01",
          icon:"lf-icon-build",
          iD_Version00:550846,
          iD_Version01:550846
    },{
          id:"02",
          icon:"lf-icon-equality",
          iD_Version00:551681,
          iD_Version01:551681
    },{
          id:"03",
          icon:"lf-icon-health",
          iD_Version00:556881,
          iD_Version01:556881
    },{
          id:"04",
          icon:"lf-icon-audit-products",
          iD_Version00:559226,
          iD_Version01:559226
    },{
          id:"05",
          icon:"lf-icon-balance",
          iD_Version00:556882,
          iD_Version01:556882
    },{
          id:"06",
          icon:"lf-icon-improvement",
          iD_Version00:556883,
          iD_Version01:556883
    },{
          id:"07",
          icon:"lf-icon-shield",
          iD_Version00:556884,
          iD_Version01:556884
    },{
          id:"08",
          icon:"lf-icon-news-communication",
          iD_Version00:580844,
          iD_Version01:580844
    },{
      id:"09",
      icon:"lf-icon-group",
      iD_Version00:1506900,
      iD_Version01:1506900
    }]
},{
    productId:"igualdad02",
      icons:[{
          id:"01",
          icon:"lf-icon-documentation",
          iD_Version00:849588,
          iD_Version01:849588
    },{
          id:"02",
          icon:"lf-icon-build",
          iD_Version00:848555,
          iD_Version01:848555
    },{
          id:"03",
          icon:"lf-icon-equality",
          iD_Version00:848556,
          iD_Version01:848556
    },{
          id:"04",
          icon:"lf-icon-health",
          iD_Version00:848595,
          iD_Version01:848595
    },{
          id:"05",
          icon:"lf-icon-audit-products",
          iD_Version00:848596,
          iD_Version01:848596
    },{
          id:"06",
          icon:"lf-icon-balance",
          iD_Version00:848597,
          iD_Version01:848597
    },{
          id:"07",
          icon:"lf-icon-improvement",
          iD_Version00:848598,
          iD_Version01:848598
    },{
          id:"08",
          icon:"lf-icon-shield",
          iD_Version00:848599,
          iD_Version01:848599
    },{
          id:"09",
          icon:"lf-icon-news-communication",
          iD_Version00:848600,
          iD_Version01:848600
    },{
      id:"10",
      icon:"lf-icon-group",
      iD_Version00:1506900,
      iD_Version01:1506900
    }]
},{
    productId:"icam",
      icons:[{
          id:"01",
          icon:"lf-icon-news-communication",
          iD_Version00:1,
          iD_Version01:1
    },{
          id:"02",
          icon:"lf-icon-subject",
          iD_Version00:2,
          iD_Version01:2
    },{
          id:"03",
          icon:"lf-icon-contacts",
          iD_Version00:3,
          iD_Version01:3
    },{
          id:"04",
          icon:"lf-icon-questionnaire",
          iD_Version00:49,
          iD_Version01:61
    }]
},{
    productId:"oneinstance",
      icons:[{
          id:"01",
          icon:"lf-icon-compliance",
          iD_Version00:947359,
          iD_Version01:947359
    },{
          id:"02",
          icon:"lf-icon-group",
          iD_Version00:1007077,
          iD_Version01:1007077
    },{
          id:"03",
          icon:"lf-icon-health",
          iD_Version00:1023770,
          iD_Version01:1023770
    },{
          id:"04",
          icon:"lf-icon-bill",
          iD_Version00:996531,
          iD_Version01:996531
    },{
          id:"05",
          icon:"lf-icon-audit-products",
          iD_Version00:967493,
          iD_Version01:967493
    },{
          id:"06",
          icon:"lf-icon-danger",
          iD_Version00:972039,
          iD_Version01:972039
    },{
          id:"07",
          icon:"lf-icon-documentation",
          iD_Version00:972042,
          iD_Version01:972042
    }]
},{
    productId:"gpap",
      icons:[{
          id:"01",
          icon:"lf-icon-transparency-report",
          iD_Version00:1011970,
          iD_Version01:1011970
    },{
          id:"02",
          icon:"lf-icon-lead",
          iD_Version00:1002101,
          iD_Version01:1002101
    },{
          id:"03",
          icon:"lf-icon-shield",
          iD_Version00:1002533,
          iD_Version01:1002533
    },{
          id:"04",
          icon:"lf-icon-compliance",
          iD_Version00:1033760,
          iD_Version01:1033760
    },{
          id:"05",
          icon:"lf-icon-group",
          iD_Version00:1038985,
          iD_Version01:1038985
    },{
          id:"06",
          icon:"lf-icon-organization",
          iD_Version00:1038987,
          iD_Version01:1038987
    },{
          id:"07",
          icon:"lf-icon-balance",
          iD_Version00:1148507,
          iD_Version01:1148507
    }]
},{
    productId:"antifraude",
      icons:[{
          id:"01",
          icon:"lf-icon-compliance",
          iD_Version00:947359,
          iD_Version01:947359
    },{
          id:"02",
          icon:"lf-icon-group",
          iD_Version00:1007077,
          iD_Version01:1007077
    },{
          id:"03",
          icon:"lf-icon-health",
          iD_Version00:947360,
          iD_Version01:947360
    },{
          id:"04",
          icon:"lf-icon-audit-products",
          iD_Version00:967493,
          iD_Version01:967493
    },{
          id:"05",
          icon:"lf-icon-danger",
          iD_Version00:972039,
          iD_Version01:972039
    },{
          id:"06",
          icon:"lf-icon-documentation",
          iD_Version00:972042,
          iD_Version01:972042
    },{
          id:"07",
          icon:"lf-icon-bill",
          iD_Version00:996531,
          iD_Version01:996531
    },{
          id:"08",
          icon:"lf-icon-subject",
          iD_Version00:1032647,
          iD_Version01:1032647
    },{
          id:"09",
          icon:"lf-icon-shield",
          iD_Version00:1107606,
          iD_Version01:1107606
    },{
          id:"10",
          icon:"lf-icon-compliance",
          iD_Version00:1107106,
          iD_Version01:1107106
    }]
  }];

  getProductIcons(productId:string){
    let menuIcons = this.menuIcons.filter((menu: any) => menu.productId === productId)[0];
    // let menuIcons = this.menuIcons.filter((menu:any)=>{menu.productId === productId;});
    return menuIcons?.icons?menuIcons.icons:[];
  }
}
