<div class="attachments-template attachments-template--custom"
    [ngClass]="{'predefined-templates__template-box--expand': attachmentsTemplate.fullScreen}"
    *ngIf="!attachmentsTemplate.hiddenTemplate">
    <div class="attachments-template__custom-header">
        <i class="lf-icon-add attachments-template__custom-add-icon"></i>
        <p class="attachments-template__custom-header-title"
            [afTranslate]="attachmentsTemplate.titleHeader">Documentos adjuntos</p>
        <i class="predefined-templates__change-view-icon attachments-template__custom-maximize-icon"
            (click)="maximizeScreen()"
            matTooltip="{{(attachmentsTemplate.fullScreen?'common.fold':'common.deploy') | afTranslate}}"
            matTooltipPosition="above"
            matTooltipClass="above"
            [ngClass]="attachmentsTemplate.fullScreen?'lf-icon-collapse-panel':'lf-icon-open-window'"></i>
        <div class="common__bubble-count-custom attachments-template__bubble-count-custom"
            [ngShow]="attachmentsTemplate.attachmentList.length || typeFrom === 'documentReference' ">
            {{attachmentsTemplate.attachmentList.length}}
        </div>
        <div class="pull-righ"
            [ngShow]="!attachmentsTemplate.hideAddDocuments && userPermissions !=='none' && !attachmentsTemplate.concept.anonymized">
            <i class="lf-icon-up-file box-comments__add-icon attachments-template__custom-upload-icon"
                [ngClass]="{'attachments-template__custom-upload-icon--disabled':attachmentsTemplate.lockComToManager}"
                (click)="launchActionDocuments('upDocument')"
                [ngShow]="!attachmentsTemplate.concept.sharedReadOnly && (attachmentsTemplate.itemSelected.conceptId || typeFrom === 'task') && !attachmentsTemplate.icamConceptObject"
                matTooltip="{{'up-document.file' | afTranslate}}"
                matTooltipPosition="above"
                matTooltipClass="above">
            </i>
        </div>
    </div>
    <div *ngIf="attachmentsTemplate.lockComToManager"
        class="attachments-template__custom-lock-to-manager">
        <i class="lf-icon-warning attachments-template__custom-lock-to-manager-icon"></i>
        <p class="attachments-template__custom-lock-to-manager-text"
            [afTranslate]="'attachments.no-permissions-to-add'">
            _No puedes adjuntar nuevos documentos en este punto del proceso de la comunicación.
        </p>
    </div>
    <div class="attachments-template__custom-no-documents"
        [ngClass]="{'attachments-template__custom-no-documents--with-top':!attachmentsTemplate.lockComToManager}"
        [ngShow]="!attachmentsTemplate.attachmentList.length && attachmentsTemplate.closeSpinner && typeFrom !== 'documentReference' && (attachmentsTemplate.itemSelected.conceptId || typeFrom === 'task')">
        <p [afTranslate]="attachmentsTemplate.infoNoDocuments">No hay documentos adjuntos</p>
    </div>
    <ul class="attachments-template__documents-block"
        [ngShow]="attachmentsTemplate.attachmentList.length || typeFrom === 'documentReference'"
        [ngClass]="{'attachments-template__documents-block--expand': attachmentsTemplate.fullScreen}">
        <li class="attachments-template__custom-documents-item"
            *ngFor="let document of attachmentsTemplate.attachmentList">
            <div class="attachments-template__document-table-col-6-custom"
                [ngClass]="{'attachments-template__document-table-col-8-custom': skin ==='icam-red'}">
                <i [class]="'attachments-template__documents-table-icon attachments-template__documents-table-icon--custom '+ (document.extension | extensionDocument)">
                </i>
                <div class="common__table-display attachments-template__name-document">
                    <div class="common__table-cell-tooltip">
                        <div class="concept-list__cell-block">
                            <parragraph-tooltip [text]="document.name"></parragraph-tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div class="attachments-template__document-table-col-2-custom"
                [ngShow]="skin!=='icam-red'">
                <span class="attachments-template__custon-document-info">
                    {{document.modified | convertDate:'without-time'}}
                </span>
            </div>
            <div class="attachments-template__document-table-col-2-custom">
                <span class="attachments-template__custon-document-info">
                    {{document.fileSize | convertBytes}}
                </span>
                <div class="attachments-template__options-block"
                    [ngShow]="(attachmentsTemplate.itemSelected.conceptObjectId || attachmentsTemplate.itemSelected.taskId || attachmentsTemplate.itemSelected.conceptId)">
                    <ul class="attachments-template__documents-table-options-custom">
                        <li class="attachments-template__menu-options-custom"
                            [ngHide]="document.disabledDelete">
                            <i class="lf-icon-trash"
                                matTooltip="{{'common.remove-document' | afTranslate}}"
                                matTooltipPosition="above"
                                matTooltipClass="above"
                                (click)="launchActionDocuments('delete' , document)"></i>
                        </li>
                        <li class="attachments-template__menu-options-custom attachments-template__menu-options-custom--edit"
                            [ngShow]="(attachmentsTemplate.itemSelected.conceptObjectId || attachmentsTemplate.itemSelected.taskId || (attachmentsTemplate.itemSelected.conceptId && document.sharedPublicConceptDocumentId))">
                            <i class="lf-icon-download"
                                matTooltip="{{'expanded-menu.download' | afTranslate}}"
                                matTooltipPosition="above"
                                matTooltipClass="above"
                                (click)="launchActionDocuments('download' , document)">
                            </i>
                        </li>
                    </ul>
                </div>
            </div>
        </li>
    </ul>
</div>
