<!-- <button (click)="saveAsPng()"
[disabled]="reportGraph.disabledSaveAsPng">Capturar gráfica</button> -->
<div class="report-graphs" [ngClass]="{'report-graphs--tasks': reportGraph.configurateReport.type === 4}">
    <div class="report-graphs__residual-block" *ngIf="showResidualOption">
        <div class="report-graphs__inherent-block-title">
            <p class="report-graphs__inherent-block-title-text" [afTranslate]="'report-graph.residual-risk'"></p>
            <button data-html2canvas-ignore="true" class="report-graphs__capture" #tooltip1="matTooltip"
                [disabled]="reportGraph.disabledSaveAsPng" (click)="saveAsPng(tooltip1, '.report-graphs__residual-block')"
                [matTooltip]="'results-report.capture-image' | afTranslate" matTooltipPosition="above"
                matTooltipClass="above">
                <i class="lf-icon-all"></i>
            </button>
        </div>
        <!-- <div class="report-graphs__left-graph report-graphs__left-graph--inherent"
            *ngIf="reportGraph.configurateReport.type !== 6 && !(reportGraph.configurateReport.type === 1 && reportGraph.configurateReport.subType === 2)"
            [ngClass]="{'report-graphs__left-graphs--without-map': reportGraph.configurateReport.subType === 1}"> -->
        <div class="report-graphs__left-graph report-graphs__left-graph--inherent"
            [ngClass]="{'report-graphs__left-graphs--without-map': reportGraph.configurateReport.subType === 1}">
            <donut-graph [graphData]="reportGraph.otherGraphsResidualData"
                [configurateReport]="reportGraph.configurateReport" [from]="'report-table'" [id]="'residual'">
            </donut-graph>
            <bar-graph [graphData]="reportGraph.otherGraphsResidualData"
                [configurateReport]="reportGraph.configurateReport" [id]="'residual'">
            </bar-graph>
        </div>
    </div>

    <div class="report-graphs__top-block"
        *ngIf="reportGraph.configurateReport.type !== 10 && !(reportGraph.report.reportId === 205 || reportGraph.report.reportId === 206 || reportGraph.report.reportId === 207)">
        <div class="report-graphs__inherent-block-title" *ngIf="showResidualOption">
            <p class="report-graphs__inherent-block-title-text" [afTranslate]="'report-graph.inherent-risk'"></p>
            <button #tooltip2="matTooltip" data-html2canvas-ignore="true" class="report-graphs__capture"
                [disabled]="reportGraph.disabledSaveAsPng" (click)="saveAsPng(tooltip2, '.report-graphs__top-block')"
                [matTooltip]="'results-report.capture-image' | afTranslate" matTooltipPosition="above"
                matTooltipClass="above">
                <i class="lf-icon-all"></i>
            </button>
        </div>
        <div class="report-graphs__left-graph report-graphs__left-graph--inherent"
            *ngIf="reportGraph.otherGraphsData?.amount"
            [ngClass]="{'report-graphs__right-graphs--without-map': reportGraph.configurateReport.subType === 1}">
            <donut-graph [graphData]="reportGraph.otherGraphsData" [configurateReport]="reportGraph.configurateReport"
                [from]="'report-table'" [id]="'inherent'"></donut-graph>
            <bar-graph [graphData]="reportGraph.otherGraphsData" [configurateReport]="reportGraph.configurateReport"
                [id]="'inherent'">
            </bar-graph>
        </div>
        <div class="report-graphs__right-graphs report-graphs__right-graphs--inherent"
            *ngIf="reportGraph.configurateReport.subType !== 1 && !(reportGraph.report.reportId === 197 || reportGraph.report.reportId === this.reportGraph.from)">
            <heat-map-graph [graphData]="reportGraph.graphData"></heat-map-graph>
        </div>
        <div class="report-graphs__investigation-state-block"
            *ngIf="reportGraph.report.reportId === 197 || reportGraph.actualVersion">
            <div class="report-graphs__inherent-block-title">
                <p class="report-graphs__inherent-block-title-text" [afTranslate]="'report-graph.state-investigation'">
                </p>
                <button data-html2canvas-ignore="true" class="report-graphs__capture" #tooltip4="matTooltip"
                    [disabled]="reportGraph.disabledSaveAsPng"
                    (click)="saveAsPng(tooltip4, '.report-graphs__investigation-state-block')"
                    [matTooltip]="'results-report.capture-image' | afTranslate" matTooltipPosition="above"
                    matTooltipClass="above">
                    <i class="lf-icon-all"></i>
                </button>
            </div>
            <div *ngIf="reportGraph.stateLoad">
                <donut-graph class="dashboard-reports__donut-graph" [graphData]="reportGraph.reportsState"
                    [configurateReport]="reportGraph.donutOptions">
                </donut-graph>
                <div class="donut-graph__legend donut-graph__legend--report-state donut-graph__legend--show-report-graph">
                    <div class="donut-graph__legend-inner-triangle donut-graph__legend-inner-triangle--report-state"></div>
                    <div class="donut-graph__dashboard-donut-header">
                        <p class="donut-graph__dashboard-donut-header-text" [afTranslate]="'common.state'">_estado</p>
                        <p class="donut-graph__dashboard-donut-header-text float-end"
                            [afTranslate]="'common.informations'">_informaciones</p>
                    </div>
                    <ul class="donut-graph__dashboard-donut-body donut-graph__dashboard-donut-body--report-state">
                        <li class="donut-graph__dashboard-donut-row"
                            *ngFor="let complaints of reportGraph.reportsState.original">
                            <div class="donut-graph__row-left">
                                <i class="lf-icon-step-final donut-graph__row-left-dot"
                                    [ngStyle]="{'color': complaints.color}"></i>
                                <p class="donut-graph__row-left-text">{{complaints.label}}</p>
                            </div>
                            <div class="donut-graph__row-right">
                                <p class="donut-graph__row-right-text donut-graph__row-right-text--no-percent">
                                    {{complaints.complaints}}</p>
                                <p class="donut-graph__row-right-text donut-graph__row-right-text--right">
                                    {{complaints.complaintsPerc}} %</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div *ngIf="reportGraph.investigationLoad">
                <stacked-bar-plot class="dashboard-reports__donut-graph" [graphData]="reportGraph.reportsInvestigation">
                </stacked-bar-plot>
                <div class="donut-graph__legend donut-graph__legend--show-report-graph-stacked">
                    <div class="donut-graph__legend-inner-triangle"></div>
                    <div class="donut-graph__dashboard-donut-header">
                        <p class="donut-graph__dashboard-donut-header-text"
                            [afTranslate]="'report-graph.admission'">:_admision</p>
                        <p class="donut-graph__dashboard-donut-header-text float-end"
                            [afTranslate]="'common.informations'">_informaciones</p>
                    </div>
                    <ul class="donut-graph__dashboard-donut-body donut-graph__dashboard-donut-body--stacked-graph">
                        <li class="donut-graph__dashboard-donut-row"
                            *ngFor="let complaints of reportGraph.reportsInvestigation.original">
                            <div class="donut-graph__row-left">
                                <p class="donut-graph__row-left-text">{{complaints.label}}</p>
                            </div>
                            <div class="donut-graph__row-right">
                                <p class="donut-graph__row-right-text donut-graph__row-right-text--no-percent">
                                    {{complaints.count}}</p>
                                <p class="donut-graph__row-right-text donut-graph__row-right-text--right">
                                    {{complaints.complaintsPerc.toFixed(1)}} %</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="report-graphs__information-typo-block" *ngIf="reportGraph.informationTypology">
            <div class="report-graphs__inherent-block-title">
                <p class="report-graphs__inherent-block-title-text" [afTranslate]="'report-graph.information-typology'">
                </p>
                <button data-html2canvas-ignore="true" class="report-graphs__capture" #tooltip3="matTooltip"
                    [disabled]="reportGraph.disabledSaveAsPng"
                    (click)="saveAsPng(tooltip3, '.report-graphs__information-typo-block')"
                    [matTooltip]="'results-report.capture-image' | afTranslate" matTooltipPosition="above"
                    matTooltipClass="above">
                    <i class="lf-icon-all"></i>
                </button>
            </div>
            <horizontal-poll-graph class="report-graphs__information-typo-container"
                [graphData]="reportGraph.informationTypologyData"
                [isModal]="true"
                [fromComponent]="'graphReport'">
            </horizontal-poll-graph>
        </div>
    </div>
    <!-- <div class="report-graphs__bottom-block"
        *ngIf="reportGraph.configurateReport.type === 4">
            <div class="report-graphs__legend-header">
                <div class="report-graphs__block-text-legend">
                    <i class="lf-icon-calendar report-graphs__icon-calendar"></i>
                    <p class="report-graphs__text-legend"
                      [afTranslate]="'report-graph.calendar-legend'">
                       _Inicio del proyecto - fecha actual
                    </p>
                </div>
            </div>
        <scatter-plot-graph [graphData]="reportGraph.graphTaskAnualData">
        </scatter-plot-graph>
    </div> -->
    <div class="report-graphs__esg-heat-map-block" *ngFor="let data of reportGraph.esgHeatMapData">
        <div [class]="data.title.replaceAll(' ', '-').toLowerCase()">
            <div class="report-graphs__esg-heat-map-header">
                <p class="report-graphs__esg-heat-map-header-title">
                    {{data.title}}
                </p>
                <button data-html2canvas-ignore="true" class="report-graphs__capture"
                    [disabled]="reportGraph.disabledSaveAsPng" #tooltip5="matTooltip"
                    (click)="saveAsPng(tooltip5, '.'+data.title.replaceAll(' ', '-').toLowerCase())"
                    [matTooltip]="'results-report.capture-image' | afTranslate" matTooltipPosition="above"
                    matTooltipClass="above">
                    <i class="lf-icon-all"></i>
                </button>
            </div>
            <div class="report-graphs__esg-heat-map-header-graph">
                <heat-map-graph [graphData]="data"></heat-map-graph>
            </div>
        </div>
    </div>
</div>
