import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { NgbActiveModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { ConceptUtilsService } from '@shared/services/concept-utils/concept-utils.service';
import { ApiPropertiesFactory } from '@shared/factories/api-properties/api-properties.factory';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'public-link',
  templateUrl: './public-link.component.html'
})
export class PublicLinkComponent implements OnInit, OnDestroy {
  skin: string = this.globalCfg.skin;
  subscribers : any = {};
  dataProtectionView: boolean = this.skin === 'dark-blue' || this.skin === 'steel-blue' || this.skin === 'gm-law';
  evaluationId: string = this.route.snapshot.params['evaluationId']
  publicLink:any ={
    noRangeColor: this.translate.instant('public-link.no-range-color'),
    setGlobal: false,
    showInfo: true,
    step: 1,
    globalConfiguration: null,
    publishButton: this.translate.instant('common.publish'),
    messageLink: '',
    tinyMceOptions: {
      noEditable: false,
      typeView: 'white',
      height: 220,
      toolbar: 'bold italic underline forecolor backcolor alignleft aligncenter alignright alignjustify undo redo bullist numlist outdent indent removeformat link',
      placeholder: this.translate.instant('common.add-comment')
    },
    corporativeInformation: {
      publishConcept: {},
      configurated : false,
      error: false
    },
    contactsField: {
      typeConfiguration: 'publish-concept',
      fieldType: 'multiple',
      addContacts: true,
      required: false,
      typeCall: 'contacts',
      selectContacts: []
    },
    listDocument: [],
    protectionData: [],
    legalData:[]
  }


  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private loader: LoaderFactory,
    private route: ActivatedRoute,
    private modalService: ModalServiceService,
    private context: ContextFactory,
    private registerService: RegisterMixtapeService,
    private activeModal: NgbActiveModal,
    private broadcastService: BroadcastService,
    private conceptUtilsService: ConceptUtilsService,
    private apiProperties: ApiPropertiesFactory) { }

  @Input() concept: any;
  @Input() evaluationInfo: any;

  ngOnInit(): void {
    this.publicLink.concept = this.concept;
    this.publicLink.published = this.concept.publicShared;
    this.publicLink.publishButton = this.publicLink.published ? this.translate.instant('expanded-menu.unpublish') : this.translate.instant('common.publish');
    this.launchWatchers();
    this.getCorporativeInformation();
    this.getPublicConceptTitle()
    this.getTaskProcess();
    this.getDocumentationList();
    this.getUrlPublic();
    if(this.dataProtectionView){
        this.getProtectionData();
        this.getAdditionalData();
        this.getLegalData();
    }
    this.resizeWindow();
    this.changeShowInfo(true);
  }

  launchWatchers(){
    this.subscribers.changeDataprotectionBroadcast = this.broadcastService.subscribe('changeDataprotection', (data:any) => {
      this.publicLink.protectionData = data.protectionData;
    });

    this.subscribers.changeDataadditionalBroadcast = this.broadcastService.subscribe('changeDataadditional', (data:any) => {
      this.publicLink.additionalData = data.additionalData;
    });

    this.subscribers.changeDatalegalBroadcast = this.broadcastService.subscribe('changeDatalegal', (data:any) => {
      this.publicLink.legalData = data.legalData;
    });
  }

  getCorporativeInformation(){
    this.apiService.get('publicconceptcfg', 'concept/' + this.concept.conceptId)
        .then((data:any)=> {
            if(data){
                let global:boolean = false;
                data.footerDesignType = !data.footerDesignType? 3 : data.footerDesignType;
                // Si el data trae un companyId, el data es global
                data.companyId? global = true : this.fillSetCorporativeInformation(data, false);
                let corporativeInformation: any = this.publicLink.corporativeInformation;
                corporativeInformation.configurated = true;
                corporativeInformation.error = false;
                // corporativeInformation.logoImage = data.logo;
                corporativeInformation.logoImage = this.commonUtilsService.setLogo(data.logoOther, data.logo);
                corporativeInformation.faviconImg = this.commonUtilsService.setLogo(data.favIcon,data.favIcon);
                if(data.logo){
                  corporativeInformation.allLogos = this.configureLogos(data);
                }
                this.getGlobalConfiguration(global);
            }
        }, (errorData:any)=> {
            this.handleDismiss('closeError');
        });
  }

  getPublicConceptTitle(){
    this.apiService.get('publicconceptcfg', 'concept/' + this.concept.conceptId+'/landing-title')
      .then((data:any)=> {
          if(!data.length){
              data=[{conceptId: this.concept.conceptId, language: sessionStorage.getItem('selectLanguage'), title: '', numOrder: 1}]
          }else{
            this.hasDefaultLanguage(data)
        }
          this.publicLink.corporativeInformation.titleInfo = {}
          this.configureTitle(data);
      }, (errorData:any)=>{})
  }

  hasDefaultLanguage(data:any){
    let selectLanguage: any = sessionStorage.getItem('selectLanguage')
    if(!Boolean(data.filter((item:any)=>item.language === selectLanguage).length)){
      data.splice(0,0, {conceptId: this.publicLink.concept.conceptId, language: selectLanguage, title: '', numOrder: 1})
    }
}

  configureTitle(data:any){
    this.publicLink.selectLanguages = [];
    this.configureSelectedLanguages(data)
    this.publicLink.corporativeInformation.titleInfo = {
            defaultLanguage: sessionStorage.getItem('selectLanguage'),
            concept: this.concept,
            languagesApp: this.context.settings.languagesApp,
            selectedLanguages: this.publicLink.selectLanguages,
            languageSelected: this.publicLink.languageSelected
        };
}

configureSelectedLanguages(data:any){
    for(let i = 0; i < data.length; i++){
        let selectedLanguage = data[i];
        let textDefault = selectedLanguage.language === sessionStorage.getItem('selectLanguage');
        this.selectListLanguagesSelected(selectedLanguage, textDefault);
    }
}

selectListLanguagesSelected( selectedLanguage:any, textDefault:any){
  this.publicLink.languages = this.commonUtilsService.configureAvailableLanguages(this.apiProperties.availableLanguages);
    for(let i = 0; i < this.publicLink.languages.length; i++){
        let language = this.publicLink.languages[i];
        language.indexListLanguage = i;
        if(language.listValueId === selectedLanguage.language){
            language.selected = true;
            language.preSelectItem = selectedLanguage.title;
            language.defaultOption = textDefault;
            language.active = language.listValueId === sessionStorage.getItem('selectLanguage');
            this.publicLink.selectLanguages.push(language);
            this.publicLink.chargedLiteralDefault = true;
            if(language.active){
                this.publicLink.languageSelected = language;
            }
            break;
        }
    }
}

  fillSetCorporativeInformation(data:any, global:boolean){
    if(!data.textColor && data.color){
        data.textColor = data.color.toLowerCase() === '#fff201' || data.color.toLowerCase() === '#ffb001'? '#000000' : '#ffffff';
    }
    this.publicLink.corporativeInformation.publishConcept = {
        color: data.color,
        textColor: data.textColor,
        footerDesignType: data.footerDesignType,
        youtube: data.youtube,
        twitter: data.twitter,
        facebook: data.facebook,
        linkedin: data.linkedin,
        phone: data.phone,
        footer: data.footer,
        showLogoInFooter: data.showLogoInFooter,
        customEmail: data.customEmail,
        smtpServer: data.smtpServer,
        smtpPort: data.smtpPort === null?'': data.smtpPort,
        smtpUser: data.smtpUser === null?'': data.smtpUser,
        smtpPassword: data.smtpPassword,
        smtpFrom: data.smtpFrom,
        smtpssl: data.smtpssl,
        oauthReconfigNeeded: data.oauthReconfigNeeded,
        oauthUser: data.oauthUser,
        oauthAccessToken: data.oauthAccessToken,
        sharedPublicConceptConfigurationId: data.sharedPublicConceptConfigurationId,
        global: global
    };

    this.prevConfigureChannelConfiguration(global);
  }

  prevConfigureChannelConfiguration(global:boolean){
    let channelConfiguration:any = {};
    if(this.publicLink.corporativeInformation && this.publicLink.corporativeInformation.publishConcept){
        channelConfiguration = {
            color: this.publicLink.corporativeInformation.publishConcept.color,
            textColor: this.publicLink.corporativeInformation.publishConcept.textColor,
            logo: this.publicLink.corporativeInformation.logoImage,
            configurated: this.publicLink.corporativeInformation.configurated,
            global: global
        };
    }
    this.configureChannelCorporativeInformation(channelConfiguration);
  }

  configureChannelCorporativeInformation(channelConfiguration:any){
    if(this.concept.channelView){
        this.concept.channelCorporativeInformation.color = channelConfiguration.color;
        this.concept.channelCorporativeInformation.textColor = channelConfiguration.textColor;
        this.concept.channelCorporativeInformation.global = channelConfiguration.global;
        this.concept.channelCorporativeInformation.configurated = channelConfiguration.configurated;
        this.concept.channelCorporativeInformation.logo = channelConfiguration.logo;
    }
  }

  configureLogos(data:any){
    return {
        logo: this.commonUtilsService.setLogo(data.logo, data.logo),
        logo320X55: this.commonUtilsService.setLogo(data.logoFooter, data.logo),
        logo260X45: this.commonUtilsService.setLogo(data.logoHeader, data.logo),
        logo290X50: this.commonUtilsService.setLogo(data.logoOther, data.logo)
    }
  }

  getGlobalConfiguration(setGlobal:any){
    this.apiService.get('company','globalcustomization/'+this.context.user.companyId)
        .then((dataglobal:any)=>{
            this.publicLink.globalConfiguration = dataglobal;
            this.publicLink.corporativeInformation.globalConfiguration = dataglobal;
            if(setGlobal){
                this.fillSetCorporativeInformation(dataglobal, setGlobal);
                this.configureLogos( this.publicLink.globalConfiguration);
            }else{
                this.prevConfigureChannelConfiguration(setGlobal);
            }
        },(errorData:any)=>{
            this.prevConfigureChannelConfiguration(setGlobal);
        });
  }

  getTaskProcess(){
    if(this.concept.processId){
        this.apiService.get('processgeneraltasks/process', this.concept.processId)
            .then((data:any)=>{
              this.publicLink.task = data;
              this.concept.taskProcess = data;
            }, (errorData:any)=>{})
    }else{
        this.publicLink.task = null;
        this.concept.taskProcess = null;
    }
  }
  getDocumentationList(){
    this.apiService.get('publicconceptdoc/concept', this.concept.conceptId)
        .then((data:any)=>{
            this.publicLink.listDocument = data;
        }, (errorData:any)=>{
            this.handleDismiss('closeError');
        })
  }
  getUrlPublic(){
    this.apiService.get('concepts/publicurl', this.concept.conceptId).then(
      (data:any) => {
        this.publicLink.url = data;
      },
      (error:any) => {
        console.error('Error al intentar obtener la URL de enlace público:', error);
        this.handleDismiss('closeError');
      }
    );
  }

  getProtectionData(){
    this.apiService.get('publicconceptcfg/concept', this.concept.conceptId + '/data-treatments')
    .then((data:any)=>{
        this.publicLink.protectionData = data;
    }, (errorData:any)=>{
        this.handleDismiss('closeError');
    })
  }

  getAdditionalData(){
    this.apiService.get('publicconceptcfg/concept', this.concept.conceptId + '/extra-info')
        .then((data:any)=>{
          this.publicLink.additionalData = data;
        }, (errorData:any)=>{
          this.handleDismiss('closeError');
        })
  }

  getLegalData(){
    this.apiService.get('publicconceptcfg/concept', this.concept.conceptId + '/legal-info')
      .then((data:any)=>{
          this.publicLink.legalData = data;
      }, (errorData:any)=>{
        this.handleDismiss('closeError');
      })
  }

  resizeWindow(){
    window.addEventListener('resize', ()=>{
      setTimeout(()=>{
        this.changeShowInfo(true);
      });
    })
  }

  changeShowInfo(resize?:boolean){
    let that = this.publicLink;
    if(!resize){
      that.resize = false;
      that.showInfo = !that.showInfo;
    }else{
      that.resize = true;
    }

    if(!that.showInfo){
        $('.modal-content').addClass('public-link__modal-size');
        if(window.innerWidth >= 1500 && resize){
            $('.modal-content').removeClass('public-link__modal-size');
            that.showInfo = true;
        }
    }else{
        $('.modal-content').removeClass('public-link__modal-size');
        if(window.innerWidth < 1500 && resize){
            $('.modal-content').addClass('public-link__modal-size');
            that.showInfo = false;
        }else if(window.innerWidth >= 1500 && resize){
            that.showInfo = true;
        }
    }

    if(that.step === 2){
        that.showInfo = true;
    }
  }

  editCorporativeInformation(){
    this.modalService.corporativeInformation(this.concept, this.evaluationInfo.clientId, this.publicLink.corporativeInformation)
        .subscribe((result:any) => {
            if(result.result==='global'){
                this.publicLink.corporativeInformation.publishConcept.global = true;
                this.deleteConfig(this.publicLink.corporativeInformation.publishConcept.global);
                this.modalService.adviceModal(this.translate.instant('corporative-information-modal.configuration-global-save-ok'), 'info:accept:check-round')
            }else if(result.result === 'ok'){
                let global;
                if(this.publicLink.corporativeInformation.globalConfiguration){
                    global = this.publicLink.corporativeInformation.globalConfiguration;
                }
                this.publicLink.corporativeInformation = {
                    configurated: this.publicLink.corporativeInformation.configurated,
                    titleInfo: this.publicLink.corporativeInformation.titleInfo,
                    logo: result.logo,
                    allLogos:this.getAllLogos(result),
                    logoImage: result.logoImage,
                    publishConcept: result.publishConcept,
                    faviconImg: result.faviconImg,
                    faviconFile: result.faviconFile
                };
                if(global){
                  this.publicLink.corporativeInformation.globalConfiguration = global;
                }
                if(!result.setGlobal){
                  this.checkLanguageTitle(result.titleInfo)
                  this.configureCorporativeInformation(this.concept, this.publicLink.corporativeInformation, result.new);
                }
                this.publicLink.customSpecific = !result.setGlobal;
                let channelConfiguration = {
                    color: this.publicLink.corporativeInformation.publishConcept.color,
                    textColor: this.publicLink.corporativeInformation.publishConcept.textColor,
                    logo: this.publicLink.corporativeInformation.logoImage,
                    configurated: true,
                    global: result.setGlobal
                };
                this.configureChannelCorporativeInformation(channelConfiguration);

          }else if(result.result ==='not-modify'){
            this.checkLanguageTitle(result.titleInfo);
            this.modalService.adviceModal(this.translate.instant('corporative-information-modal.configuration-save'), 'info:accept:check-round')
          }

        })
  }

  checkLanguageTitle(list:any){
    let listTitleInfo = this._getNonEmptyTitles(list);
    this._updateTitleList(listTitleInfo);
  }

  private _getNonEmptyTitles(list: any): any [] {
    let listTitleInfo:any = [];
    this.publicLink.corporativeInformation.titleInfo = {}
    for(let i = 0; i < list.length; i++){
      let language = list[i]
      if(language.preSelectItem !== ''){
        listTitleInfo.push({
          conceptId: this.concept.conceptId,
          language:  language.listValueId,
          numOrder: i+1,
          title: language.preSelectItem
        })
      }
    }
    return listTitleInfo;
  }

  private _updateTitleList(listTitleInfo: any []) {
    this.apiService
    .update('publicconceptcfg/concept/' + this.concept.conceptId, 'landing-title', listTitleInfo)
    .then((data:any)=>{
      this.hasDefaultLanguage(listTitleInfo);
      this.configureTitle(listTitleInfo);
    }, (errorData)=>{
      this.configureTitle(listTitleInfo);
    });
  }

  editReferenceDocument(){
    let configuration:any = {
        documentationList: this.publicLink.listDocument,
        concept: this.concept,
        protectionData: this.dataProtectionView ? this.publicLink.protectionData : undefined,
        additionalData: this.dataProtectionView ? this.publicLink.additionalData : undefined,
        legalData: this.dataProtectionView ? this.publicLink.legalData : undefined,

    };
    this.modalService.referenceDocument(configuration).subscribe((result:any) => {
        if( this.concept.channelView){
          this.concept.referenceDocuments = this.publicLink.listDocument;
          if(this.dataProtectionView){
            this.concept.protectionData = this.conceptUtilsService.addProtectionData(result.protectionData);
            this.concept.additionalData = this.conceptUtilsService.addProtectionData(result.additionalData);
            this.concept.legalData = this.conceptUtilsService.addProtectionData(result.legalData);
          }
        }
    });
  }

  processManagement(){
      this.modalService.processOpsModal(this.evaluationInfo, this.publicLink.concept, 'public-link').subscribe((result:any)=>{
        if(result.result === 'ok'){
          this.concept.processId = result.concept.processId;
          this.concept.taskProcess = result.concept.taskProcess
          if(this.concept.processId && this.concept.taskProcess){
            this.publicLink.errorHasProcessOps = false;
          }
        }
      })
  }

  publishConcept(){
    if(this.publicLink.published){
        this.changePublicShared(this.concept, false, 'unpublish');
    }else{
        let resgisterItem ={
           'Distinct ID': this.context.user.externalId,
            'Client ID': this.context.user.companyExternalId,
            'event':'publish public link',
            'Concept Name':sessionStorage.getItem('breadcrumbConcept') + this.publicLink.concept.title,
            'Centinela product': this.context.settings.defaultProduct
        }
        this.registerService.register(resgisterItem);
        let processConfigurated:boolean = this.concept.publicLite?true : (this.concept.processId && this.concept.taskProcess);
        if(this.publicLink.corporativeInformation.configurated && processConfigurated){
            this.changePublicShared(this.concept, true);
        }else{
          this.publicLink.corporativeInformation.error = (!this.publicLink.corporativeInformation.configurated);
          this.publicLink.errorHasProcessOps = !(this.concept.processId && this.concept.taskProcess);
        }
    }
  }

  changePublicShared(concept:any, publicSharedStatus:boolean, unpublish:string = '') {
    this.loader.openLoader('folder-public-shared')
    this.apiService.patch('concepts/' + this.evaluationInfo.evaluationId + '/' + concept.conceptId + '/setpublic/' + publicSharedStatus).then(
      (data:any)=>{
          concept.publicShared = publicSharedStatus;
          if(concept.publicShared){
              this.publicLink.step = 2;
              this.publicLink.showInfo = true;
              this.publicLink.finishDescription = (this.publicLink.customSpecific? this.translate.instant('public-link.identity-specific') : this.translate.instant('public-link.identity-global'))+' '+ this.translate.instant('public-link.confirm-publish');
          }
          if(this.publicLink.setGlobal){
            this.modalService.adviceModal(this.translate.instant('corporative-information-modal.configuration-global-save-ok'), 'info:accept:check-round')
            this.fillSetCorporativeInformation(this.publicLink.globalConfiguration, this.publicLink.setGlobal);
          }
          if(unpublish === 'unpublish'){
              this.publicLink.published = false;
              this.publicLink.publishButton = this.translate.instant('expanded-menu.publish');
              let channelConfiguration:any = {
                  global: false,
                  configurated: null
              };
              if(this.publicLink.globalConfiguration){
                  channelConfiguration = this.publicLink.globalConfiguration;
                  channelConfiguration.global = true;
                  channelConfiguration.configurated = this.publicLink.corporativeInformation.configurated;
                  if(!channelConfiguration.textColor){
                      channelConfiguration.textColor = channelConfiguration.color.toLowerCase() === '#fff201' || channelConfiguration.color.toLowerCase() === '#ffb001'? '#000000' : '#ffffff';
                  }
              }
              this.configureChannelCorporativeInformation(channelConfiguration);
              this.handleDismiss('unpublish');
          }
      },
      (error:any) => {
          console.error(error);
      }
    ).finally(() => {
      this.loader.closeLoader('folder-public-shared');
    });
  }

  sharePublicConcept(){
    this.setMessageForDestinations();
    let copyNotification = this.publicLink.checkCopyNotification ? 1 : 0;

    this.loader.openLoader('share-public-concept');
    this.apiService.patch('concepts/' + this.evaluationInfo.evaluationId, this.concept.conceptId + '/sharepublic/' + copyNotification , this.publicLink.contactsField.selectContacts).then(
      (data:any) => {
        this.handleAccept();
      },
      (error:any) => {
        console.error(error);
        this.modalService.adviceModal(this.translate.instant('folder.error-shared-link'), 'error:accept:warning')
      }
    ).finally(() => {
      this.loader.closeLoader('share-public-concept');
    });
  }

  receiveContacts(e:any){
    this.publicLink.contactsField.selectContacts = e.contacts;
  }

  copyText(tooltip:NgbTooltip, type:string) {
    try{
      this.commonUtilsService.clipboardText(type);
      tooltip.open();
      setTimeout(() => {
        tooltip.close();
      },1000)
    }catch(error:any){
      console.error('Error al copiar la URL de enlace público:', error);
    }
  }

  deleteConfig(global:any){
    let sharedPublicConceptConfigurationId:any = this.publicLink.corporativeInformation.publishConcept.sharedPublicConceptConfigurationId
    this.apiService.delete('publicconceptcfg', sharedPublicConceptConfigurationId)
    .then((data:any)=>{
        this.getGlobalConfiguration(global);
    }, (error:any)=>{})
  }

  getAllLogos(result:any){
    return {
        logo: result.listLogos? result.listLogos[0].html.toDataURL('image/png') : result.logoImage,
        logo260X45: result.listLogos? result.listLogos[3].html.toDataURL('image/png') : result.logoImage,
        logo320X55: result.listLogos? result.listLogos[2].html.toDataURL('image/png') : result.logoImage,
        logo290X50: result.listLogos? result.listLogos[1].html.toDataURL('image/png') : result.logoImage,
    }
  }



  configureCorporativeInformation(concept:any, publishConcept:any, nuevo:any = undefined){
    let corporativeInformation: any = this.publicLink.corporativeInformation;
    this.loader.openLoader('folder-share-public-concept')
    corporativeInformation.configurated && corporativeInformation.publishConcept.sharedPublicConceptConfigurationId && !nuevo? this.updateCorporativeInfo(concept, publishConcept) : this.addCorporativeInfo(concept, publishConcept);
  }

  updateCorporativeInfo(concept:any, publishConcept:any){
    this.apiService.update('publicconceptcfg', undefined, publishConcept.publishConcept)
        .then((data:any)=>{
            if (data !== null && data !== '') {
                this.addFaviconToCorporativeInformation(data, publishConcept);
                this.addLogoToCorporativeInformation(concept, publishConcept, data);
            }else{
                this.changePublicShared(concept, false);
            }
        }, (error:any)=> {
            this.loader.closeError();
        })
  }

  addCorporativeInfo(concept:any, publishConcept:any){
    this.apiService.add('publicconceptcfg', publishConcept.publishConcept)
        .then((data:any)=>{
            if (data !== null && data !== '') {
                this.publicLink.corporativeInformation.publishConcept.sharedPublicConceptConfigurationId = data.sharedPublicConceptConfigurationId;
                this.addLogoToCorporativeInformation(concept, publishConcept, data);
                this.addFaviconToCorporativeInformation(data, publishConcept);
            }else{
                this.changePublicShared(concept, false);
            }
        }, ()=> {
            this.loader.closeError();
        })
  }

  addFaviconToCorporativeInformation(published:any, publishConcept: any){
    this.apiService.attachFilePatch('publicconceptcfg/'+ published.sharedPublicConceptConfigurationId+'/favicon', publishConcept.faviconFile)
      .then((data:any)=>{}, (errorData:any)=>{});
  }

  addLogoToCorporativeInformation(concept:any, publishConcept:any,  published:any){
    this.apiService.attachFilePatch('publicconceptcfg/'+ published.sharedPublicConceptConfigurationId+'/logos', publishConcept.logo)
        .then((data:any)=>{
            this.publicLink.corporativeInformation.configurated = true;
            this.loader.closeLoader(['folder-share-public-concept']);
            this.modalService.adviceModal(this.translate.instant('corporative-information-modal.configuration-specific-save-ok'), 'info:accept:check-round')
        }, (errorData:any)=>{
            this.loader.closeError();
            this.changePublicShared(concept, false);
        });
  }

  setMessageForDestinations(){
    this.publicLink.messageLink = this.commonUtilsService.extractInnerHTML(this.publicLink.messageLink);
    if(this.publicLink.messageLink){
        for(let i = 0; i < this.publicLink.contactsField.selectContacts.length; i++){
            this.publicLink.contactsField.selectContacts[i].comments = this.publicLink.messageLink;
        }
    }
    // if(!this.publicLink.checkCopyNotification){
    //   this.publicLink.contactsField.selectContacts.push(this.context.user);
    // }
    // return this.publicLink.contactsField.selectContacts;
  }

  generateQr(url:any){
    this.modalService.qrModal(url)
      .subscribe((result:any)=>{
        if(result.result==='ok'){
          this.modalService.adviceModal(this.translate.instant('channel-configuration.qr-succes'),'info:accept:check-round')
        }

    })
  }

  handleAccept(type:any = 'noUpdate') {
    this.activeModal.close({
      result : 'ok',
      //sharedTo: this.setMessageForDestinations()
    });
  }

  handleDismiss(close:string = '') {
    this.activeModal.close({
      reason: 'cancel',
      close: close
    });
  }

  ngOnDestroy(): void {
    this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
