import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ReportVersionFactory } from '@shared/factories/report-version/report-version.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
@Injectable()

export class ReportRecordDetailResolver implements Resolve<any> {
  url: string = '';

  constructor(private apiService: ApiServiceService,
    private reportVersion: ReportVersionFactory) { }

  resolve(route: ActivatedRouteSnapshot) {
    if(sessionStorage.hasOwnProperty('report-version')){
      this.url = 'reportobjects/byreportid/' + route.params['reportId'];
      if(route.params['evaluationId']){
        this.url += '/' + route.params['evaluationId'];
      }
      return this.getData();
    }else{
      return this.getReportsVersion();
    }
  }

  getReportsVersion(){
    return this.apiService.get('denunc/dashboard/reports/', 'ids')
      .then((data:any)=>{
        this.reportVersion.setReportVersion(data);
        return this.getData();
      }, (errorData:any)=>{})
  }

  getData(){
    return this.apiService.get(this.url)
      .then((data:any)=>{
        return data;
      })
  }
}


