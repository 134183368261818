import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ReportRecordDetailService } from '../utils/report-record-detail.service';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Component({
  selector: 'report-record-documentation',
  templateUrl: './report-record-documentation.component.html'
})

export class ReportRecordDocumentationComponent implements OnInit{
 
  constructor(private commonUtilsService: CommonUtilsService,
    private reportRecordDetailService: ReportRecordDetailService,
    private translate: AfTranslateFactory) {}

  @Input() rows:any = null;
  @Input() columns:any = null;
  @Input() reportfields:any = null;
  @Input() report:any = null;
  @Input() documentationList:any = null;
  @Input() reportRecordDetail: any;
  @ViewChild(NgbDropdown)
  dropdown: NgbDropdown

  ngOnInit(): void {
    let that: any = this.reportRecordDetail;
    that.resolutionFilter = {name: this.translate.instant('report-record-detail.resolution')};
    that.organizationFilter = {name: this.translate.instant('report-record-detail.organization')};
    that.channelFilter = {name: this.translate.instant('report-record-detail.channel')};
    that.modifiedByFilter = {name: this.translate.instant('common.author')};
    that.viewTableHtml = "documentation";
    that.documentFilters = ['modifiedBy'];
    that.dateOrder = 'modified';
    that.orderOperationDates = true;
    this.reportRecordDetailService.getFilters(that.filterDocumentationList, this);
    if(this.dropdown){
      this.dropdown.close();
    }
    this.reportRecordDetailService.getExpandedMenu(this);
    this.reportRecordDetail.filterDocumentationList = this.documentationList
  }

  orderBySearch(e:any, type:any){
    this.reportRecordDetail['search'+type] = e.newValue;
    this.reportRecordDetailService.filterReportDocument(type, this);
  }

  orderDate(type:any) {
    this.reportRecordDetailService.orderDate(type, this)
  }

  selectTypeOperation(e:any, type:any){
    this.reportRecordDetailService.selectTypeOperationDocumentation(e.source, type, this)
  }

  launchActionOption(option:any, documentSelected:any){
    this.reportRecordDetailService.launchActionOption(option, documentSelected, this)
  }
}


