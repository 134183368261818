<div class="users-admin-import">
    <div class="common__modal-header-block">
        <i class="lf-icon-up-file common__icon-header"></i>
        <span class="common__text-header" 
            [afTranslate]="'users-admin.import.dialog-title-' + type">
        </span>
        <i class="lf-icon-close common__icon-close-modal" 
            (click)="handleDismiss()">
        </i>
    </div>
    <div class="users-admin-import__body-select-file"
        [ngShow]="importModal.view === 'select-file'">
        <field-type-onefile [field]="importModal.fileField"
            [type]="'new'"
            [fileInfo]="importModal.fileInfo"
            (itemSelected)="fileSelected($event)">
        </field-type-onefile>
    </div>
    <div class="users-admin-import__body-analized"
        [ngShow]="importModal.view === 'analized-contacts' || importModal.view === 'analized-clients'">
        <div class="users-admin-import__index-box">
            <button class="users-admin-import__index-button"
                [ngHide]="importModal.showImportContacts"
                (click)="importModal.showImportContacts = !importModal.showImportContacts">
                <i class="lf-icon-contacts users-admin-import__index-icon"></i>
                <span class="users-admin-import__index-text" 
                    [afTranslate]="'users-admin.import.' + type + '-index-button'">
                </span>
            </button>
            <div class="users-admin-import__contacts-block"
                [ngShow]="importModal.showImportContacts">
                <div class="users-admin-import__contacts-header"
                    [ngClass]="{'users-admin-import__contacts-header--green': importModal.typeSelect === 'validate',
                      'users-admin-import__contacts-header--red': importModal.typeSelect === 'error'}">
                    <button class="users-admin-import__filter-button users-admin-import__filter-button--close"
                        (click)="importModal.showImportContacts = !importModal.showImportContacts">
                        <i class="lf-icon-close users-admin-import__contacts-close-icon"></i>
                    </button>
                    <div class="users-admin-import__filter-block" 
                        ngbDropdown>
                        <button class="users-admin-import__filter-button float-start"
                            ngbDropdownToggle>
                            <i class="lf-icon-filter-2 users-admin-import__filter-icon"></i>
                        </button>
                        <p class="users-admin-import__amount-text">
                            <span>{{importModal.filterBoxText}}</span>
                            <span class="users-admin-import__amount-num">{{amountHeader}}</span>
                        </p>
                        <ul class="users-admin-import__drop-filter-list users-admin-import__drop-filter-list--import-modal" 
                            ngbDropdownMenu 
                            role="menu">
                            <li class="users-admin-import__drop-filter-item" 
                                (click)="filterToShow('all')">
                                <span class="users-admin-import__drop-filter-text" 
                                    [afTranslate]="'contacts.state-all'">
                                </span>
                                [{{importModal.allFields.length}}]
                                <i class="lf-icon-check-round-full users-admin-import__drop-filter-icon"></i>
                            </li>
                            <li class="users-admin-import__drop-filter-item"
                                (click)="filterToShow('validate')">
                                <span class="users-admin-import__drop-filter-text" 
                                    [afTranslate]="'users-admin.import.validate-contacts-card'">
                                </span>
                                [{{importModal.validateFields.length}}]
                                <i class="lf-icon-check-round-full users-admin-import__drop-filter-icon"></i>
                            </li>
                            <li class="users-admin-import__drop-filter-item" 
                                (click)="filterToShow('error')">
                                <span class="users-admin-import__drop-filter-text" 
                                    [afTranslate]="'users-admin.import.error-contacts-card'">
                                </span>
                                [{{errorFields.length}}]
                                <i class="lf-icon-check-round-full users-admin-import__drop-filter-icon"></i>
                            </li>
                        </ul>
                    </div>
                </div>
                <label class="users-admin-import__contact-search-block">
                    <input class="users-admin-import__contact-input"
                        [(ngModel)]="importModal.searchContact"
                        [placeholder]="'common.search' | afTranslate">
                    <i class="lf-icon-search users-admin-import__seach-icon"></i>
                </label>
                <ul class="users-admin-import__contact-list">
                    <li *ngFor="let contact of importModal.allFields | byWord: importModal.searchContact:['nameComplete']; let index = index"
                        [ngShow]="importModal.typeSelect ==='all' || importModal.typeSelect === contact.type"
                        class="users-admin-import__contact-item"
                        [id]="'contact-index-' + index"
                        matTooltip="{{contact.nameComplete}}"
                        matTooltipPosition="below"
                        matTooltipClass="below"
                        [matTooltipDisabled]="showTooltip('contact-index-' + index)">
                        <i class="lf-icon-user users-admin-import__contact-icon"
                            [ngClass]="!contact.errorList.length ? 'users-admin-import__contact-icon--green' : 'users-admin-import__contact-icon--red'"></i>
                        <span [id]="'span-contact-index-' + index">{{contact.nameComplete}}</span>
                    </li>
                </ul>
            </div>
        </div>
        <h2 class="users-admin-import__contacts-import-title"
          [afTranslate]="'users-admin.import.' + type + '-import-title'"></h2>
        <div class="common__option-box">
            <h3 class="users-admin-import__contacts-import-amount">
                {{importModal.allFields.length}}
                <span class="users-admin-import__contacts-import-amount-text"
                  [afTranslate]="'users-admin.import.num-total'"></span>
            </h3>
            <p class="common__contacts-import-icon">
                <i class="lf-icon-contacts"></i>
            </p>
        </div>
        <div class="common__option-box common__option-box--center">
            <h3 class="users-admin-import__contacts-import-amount">
                {{importModal.validateFields.length}}
                <span class="users-admin-import__contacts-import-amount-text"
                  [afTranslate]="'users-admin.import.validate-contacts-card'"></span>
            </h3>
            <p class="common__contacts-import-icon common__contacts-import-icon--green">
                <i class="lf-icon-check-round"></i>
            </p>
        </div>
        <div class="common__option-box">
            <h3 class="users-admin-import__contacts-import-amount">
                {{errorFields.length}}
                <span class="users-admin-import__contacts-import-amount-text"
                  [afTranslate]="'users-admin.import.error'"></span>
            </h3>
            <p class="common__contacts-import-icon common__contacts-import-icon--red">
                <i class="lf-icon-warning"></i>
            </p>
            <button [ngShow]="errorFields.length"
                class="users-admin-import__view-errors-button"
                (click)="changeView('error-contacts')">
                <i class="lf-icon-visible"></i>
                <span class="users-admin-import__view-errors-button-text" 
                    [afTranslate]="'users-admin.import.view-errors-form'">
                </span>
            </button>
        </div>
    </div>
    <div class="users-admin-import__body-analized"
        [ngShow]="importModal.view === 'analized-concepts'">
        <h2 class="users-admin-import__contacts-import-title">
            <i class="lf-icon-compliance"></i>
            <span [afTranslate]="'common.total'"></span>:
            {{importModal.allFields.length}}
            <span [afTranslate]="'users-admin.import.analyzed-fields'"></span>
        </h2>
        <div class="common__option-box">
            <h3 class="users-admin-import__contacts-import-amount">
                {{importModal.validateFields.length}}
                <span class="users-admin-import__contacts-import-amount-text" 
                    [afTranslate]="'users-admin.import.validate-contacts-card'">
                </span>
            </h3>
            <p class="common__contacts-import-icon common__contacts-import-icon--green">
                <i class="lf-icon-check-round"></i>
            </p>
        </div>
        <div class="common__option-box common__option-box--center">
            <h3 class="users-admin-import__contacts-import-amount">
                {{incompleteFields.length}}
                <span class="users-admin-import__contacts-import-amount-text" 
                    [afTranslate]="'users-admin.import.incomplete'">
                </span>
            </h3>
            <p class="common__contacts-import-icon common__contacts-import-icon--yellow">
                <i class="lf-icon-warning"></i>
            </p>
            <button [ngShow]="incompleteFields.length"
                class="users-admin-import__view-errors-button users-admin-import__view-errors-button--yellow"
                (click)="changeView('warning-fields')">
                <i class="lf-icon-mandatory-field"></i>
                <span class="users-admin-import__view-errors-button-text" 
                    [afTranslate]="'users-admin.import.revise-fields'">
                </span>
            </button>
        </div>
        <div class="common__option-box">
            <h3 class="users-admin-import__contacts-import-amount">
                {{errorFields.length}}
                <span class="users-admin-import__contacts-import-amount-text" 
                    [afTranslate]="'users-admin.import.error'">
                </span>
            </h3>
            <p class="common__contacts-import-icon common__contacts-import-icon--red">
                <i class="lf-icon-warning"></i>
            </p>
            <button [ngShow]="errorFields.length"
                class="users-admin-import__view-errors-button"
                (click)="changeView('error-concepts')">
                <i class="lf-icon-visible"></i>
                <span class="users-admin-import__view-errors-button-text" 
                    [afTranslate]="'users-admin.import.view-errors-form'">
                </span>
            </button>
        </div>
    </div>
    <div class="users-admin-import__errors-block"
        *ngIf="importModal.view === 'error-contacts' || importModal.view ==='error-concepts' || importModal.view ==='warning-fields' || importModal.view ==='error-clients'"
        [ngClass]="{'users-admin-import__errors-block--warning': showWarningInfo}">
        <advice-message [ngShow]="showWarningInfo"
            class="users-admin-import__advice-message"
            icon="warning"
            type="warning"
            text="importModal.warningInfo"
            type-class="no-margin"
            close-advice="closeAdvice('showWarningInfo')">
        </advice-message>
        <div class="users-admin-import__errors-block-elements"
            [ngClass]="{'users-admin-import__errors-block-elements--warning': showWarningInfo}">
            <div class="users-admin-import__errors-header">
                <p class="users-admin-import__errors-title" 
                    [afTranslate]="'users-admin.import.' + importModal.view">
                </p>
                <span class="header-nav__icon-numeric-nav">{{errorFields.length}}</span>
            </div>
            <ul class="users-admin-import__error-list">
                <li *ngFor="let contact of importModal.filterErrorFields; let $index = index"
                    class="users-admin-import__error-item">
                    <p class="users-admin-import__contact-error-name"
                        [id]="'error-contact-' + $index"
                        matTooltip="{{contact.nameComplete}}"
                        matTooltipPosition="below"
                        matTooltipClass="below">
                        <i class="users-admin-import__contact-icon"
                            [ngClass]="{'lf-icon-user users-admin-import__contact-icon--list-error': importModal.view === 'error-contacts',
                            'lf-icon-compliance users-admin-import__contact-icon--list-error': importModal.view === 'error-concepts',
                            'lf-icon-compliance users-admin-import__contact-icon--list-warning': importModal.view === 'warning-fields'}">
                        </i>
                        <span [id]="'span-error-contact-' + $index">
                            {{contact.nameComplete}}
                        </span>
                    </p>
                    <ul class="users-admin-import__contact-error-list">
                        <li *ngFor="let error of contact.errorList"
                            class="users-admin-import__contact-error-item">
                            <span class="users-admin-import__contact-error-item"
                                [ngClass]="{'users-admin-import__contact-error-item--error': importModal.view === 'error-contacts' || importModal.view === 'error-concepts' || importModal.view === 'error-clients',
                                  'users-admin-import__contact-error-item--warning': !(importModal.view === 'error-contacts' || importModal.view === 'error-concepts' || importModal.view === 'error-clients')}">
                                {{error.errorType}}:
                            </span>
                            {{error.errorDescription}}
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

    </div>
    <div class="users-admin-import__footer">
        <div [ngHide]="importModal.view === 'error-contacts' || importModal.view === 'error-concepts' || importModal.view === 'warning-fields' || importModal.view === 'view-error' || importModal.view === 'error-clients'">
            <button type="reset"
                    class="common__button common__button--clean"
                    (click)="handleDismiss()"
                    [afTranslate]="'common.cancel'"></button>
            <button type="reset"
                    [ngShow]="importModal.view === 'analized-contacts' || importModal.view === 'analized-concepts' || importModal.view === 'analized-clients'"
                    class="common__button common__button--clean"
                    (click)="previous()"
                    [afTranslate]="'common.previous'"></button>
            <button type="submit"
                    [ngShow]="importModal.view === 'analized-contacts' || importModal.view === 'analized-concepts' || importModal.view === 'analized-clients'"
                    class="common__button common__button--active"
                    [disabled]="!importModal.validateFields.length && !incompleteFields.length"
                    (click)="close()"
                    [afTranslate]="'evaluation-import.buttons.import'"></button>
            <button type="submit"
                    [ngShow]="importModal.view === 'select-file'"
                    class="common__button common__button--active"
                    (click)="analizeClick()"
                    [afTranslate]="'users-admin.import.analize-file'"></button>
        </div>
        <button type="reset"
            [ngShow]="importModal.view === 'error-contacts' || importModal.view === 'error-concepts' || importModal.view === 'warning-fields' || importModal.view === 'error-clients'"
            class="common__button common__button--active"
            (click)="importModal.view = 'analized-' + type"
            [afTranslate]="'common.return'"></button>
    </div>
</div>
