import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { ReportVersionFactory } from '@shared/factories/report-version/report-version.factory';
import { StateManagementFactory } from '@shared/factories/state-management/state-management.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'report-incidents-list',
  templateUrl: './report-incidents-list.component.html'
})
export class ReportIncidentsListComponent implements OnInit {
  constructor(
    private translate:AfTranslateFactory,
    private commonUtilsService: CommonUtilsService,
    private apiService: ApiServiceService,
    private userPermissions: UserPermisionsFactory,
    private loader: LoaderFactory,
    private filterColumnService: FilterColumnService,
    private router: Router,
    private stateManagement: StateManagementFactory,
    private route: ActivatedRoute,
    private modalService: ModalServiceService,
    private context: ContextFactory,
    private reportVersion: ReportVersionFactory) { }

  reportList:any = this.route.snapshot.data['reportList'].reports;
  archivedList:any = this.route.snapshot.data['reportList'].archivedReports;
  isNewVersion: boolean = Boolean(this.route.snapshot.data['reportList'].archivedReports?.length && this.checkNewVersion())
  reportIncidentsList:any = {
    reportIdSelected: this.route.snapshot.params['reportId'],
    productIdSelected: this.context.settings?.defaultProduct,
    configuredTotal:{
      first: 1,
      last: 50
    },
    configPagination: {
      pageSize: 50,
      maxSize: 10
    },
    currentPage: 1,
    orderOperationDates: true,
    search: false,
    searchReport: '',
    searchTitle: '',
    dateOrder: 'creationDate',
    filters: ['author'],
    toShow: 'report'
  }

  ngOnInit(): void {
    this.configureView();

    this.commonUtilsService.getProductPermissions(this.reportIncidentsList.productIdSelected, this.apiService)
      .then( (data:any)=> {
        this.reportIncidentsList.userPermissions = this.userPermissions.getPermissions();
      })
    let text: string = this.reportIncidentsList.reportIdSelected ==='163'? 'report-incidents-list.global-register-reports' : 'report-incidents-list.event-log';
    this.reportIncidentsList.titleText = this.translate.instant(text)
  }

  checkNewVersion(){
    return this.route.snapshot.params['evaluationId']?this.reportVersion.folderReportItsNewVersion :  this.reportVersion.dashboardReportItsNewVersion;
  }

  configureView(){
      this.reportIncidentsList.authorFilter ={
          name: this.translate.instant('common.author')
      }
      this.getReportList();
  }

  getReportList(){
      this.reportIncidentsList.filterReportList = this.reportList;
      this.getFilters();
      this.loader.closeLoader(['open-list-reports', 'front-login-login']);
  }

  getFilters(){
    this.reportIncidentsList.filters.forEach((filter:any) => {
      this.reportIncidentsList[filter+'List'] = this.filterColumnService.extractList(filter, this.reportIncidentsList.filterReportList);
      this.reportIncidentsList[filter+'ListFilter'] = [];
    });
  }

  selectTypeOperation(e:any, type:any){
      let listFilter = this.reportIncidentsList[type+'ListFilter'];
      this.reportIncidentsList[type+'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, e.item);
      e.item.selected = !e.item.selected;
      if(this.reportIncidentsList[type+'Filter']){
          this.reportIncidentsList[type+'Filter'].hasFilter = this.reportIncidentsList[type+'ListFilter'].length;
      }
      this.filterReport();
  }

  filterReport(){
      let list: any = this.reportIncidentsList.toShow === 'report'? this.reportList : this.archivedList;
      let filterList:any = [];
      filterList = this.filterColumnService.filterByWord(this.reportIncidentsList.searchTitle,  list, 'title');
      this.reportIncidentsList.filters.forEach((filter:any) => {
        let filteredList = this.reportIncidentsList[filter+'ListFilter'];
        filterList = this.filterColumnService.filterBycolumn(filterList, filteredList, filter);
      });

      this.reportIncidentsList.filterReportList = filterList;
  }

  openFocus(type:any){
      this.reportIncidentsList['openFocus'+type] = !this.reportIncidentsList['openFocus'+type];
  }

  orderDate(type:any) {
      if(this.reportIncidentsList.dateOrder === type){
          this.reportIncidentsList.orderOperationDates = !this.reportIncidentsList.orderOperationDates;
      }else{
        this.reportIncidentsList.dateOrder = type;
        this.reportIncidentsList.orderOperationDates = (type !== 'title');
      }
  }

  launchActionOption(report:any, option:any){
      switch(option){
          case 'edit':
              this.stateManagement.setStateUrl(window.location.pathname);
              if(report.reportId === this.reportVersion.folderReportVersion || report.reportId === this.reportVersion.dashboardReportVersion){
                this.router.navigate(['report-detail', report.reportObjectId]);
              }else{
                this.router.navigate(['report-detail', report.reportObjectId, 'archived']);
              }
              break;
          case 'openWindow':
              if(report.reportId === this.reportVersion.folderReportVersion || report.reportId === this.reportVersion.dashboardReportVersion){
                window.open('/report-detail/'+ report.reportObjectId, '_blank');
              }else{
                window.open('/report-detail/'+ report.reportObjectId+'/archived', '_blank');
              }
              break;
          case 'delete':
              this.deleteReportQuestion(report);
              break;
      }
  }

  deleteReportQuestion(report:any){
      let word:string = this.translate.instant('concept-list.report');
      let questionText = this.translate.instant('concept-list.delete-question')+word+" '"+report.title+"' ?";
      this.modalService.adviceModal(questionText, 'info:question:question')
      .subscribe((result:any)=>{
        if(result.result === 'ok'){
          this.loader.openLoader('delete-report-incidents-list');
          this.deleteReport(report)
        }
      })
  }

  deleteReport(report:any){
    this.commonUtilsService.registerEvent('delete report', this.reportIncidentsList.productIdSelected, report.reportId, 'report', 'other')
    this.apiService.delete('reportobjects', report.reportObjectId)
          .then((data:any)=> {
              let list: any = this.reportIncidentsList.toShow === 'report'? this.reportList : this.archivedList;
              list = this.commonUtilsService.deleteElement(report, list, 'reportObjectId');
              if(this.reportIncidentsList.toShow === 'report'){
                this.reportList = list;
              }else{
                this.archivedList = list;
              }
              this.filterReport();
              this.loader.closeLoader('delete-report-incidents-list');
              this. modalService.adviceModal(this.translate.instant('concept-list.report-delete'), 'info:accept:check-round');
          }, (errorData:any)=>{
              this.loader.closeError();
              let text: string = errorData.status === 403? 'concept-list.delete-error-permissions' : 'concept-list.delete-error-concept';
              this.modalService.adviceModal(this.translate.instant(text), 'error:accept:warning');
          });
  }

  toggleSearch() {
    this.reportIncidentsList.search = !this.reportIncidentsList.search;
    if (!this.reportIncidentsList.search) {
        this.reportIncidentsList.searchReport = '';
    }
  }

  showTooltip(id:any){
    return this.commonUtilsService.showTooltip(id)
  }

  changeViewWindow(){
    this.commonUtilsService.goToStateUrl();
  }

  resetTitleFilter(){
    this.reportIncidentsList.searchTitle = '';
    this.filterReport();
  }

  viewArchived(){
    if(this.reportIncidentsList.toShow === 'report'){
      this.reportIncidentsList.toShow = 'archived'
      this.reportIncidentsList.filterReportList = this.archivedList;
    }else{
      this.reportIncidentsList.toShow = 'report'
      this.reportIncidentsList.filterReportList = this.reportList;
    }
    this.getFilters();
    this.loader.closeLoader(['open-list-reports', 'front-login-login']);
  }

  deleteArchived(){
    this.modalService.adviceModal(this.translate.instant("concept-list.delete-all-archive-reports"), 'info:question:question')
    .subscribe((result:any)=>{
      if(result.result==='ok'){
        this.loader.openLoader('folder-delete-archived')
        this.configureDeleteAllArchived();
      }
    })
  }

  configureDeleteAllArchived(){
    let uniqueList: any = [];
    for(var i = 0; i < this.archivedList.length; i++){
      var archivedItem: any = this.archivedList[i];
      let itsUnique: boolean = true;
      for(var j = 0; j < uniqueList.length; j++){
        let uniqueItem: any = uniqueList[j];
        if(uniqueItem.reportId === archivedItem.reportId){
          itsUnique = false;
          break;
        }
      }
      if(itsUnique){
        uniqueList.push(archivedItem)
      }
    }
    for(var i = 0; i < uniqueList.length; i++){
      this.deleteAllArchived(uniqueList[i].reportId, (i === uniqueList.length - 1));
    }
  }

  deleteAllArchived(reportId:any, last: boolean = false){
    let evaluationId: any = this.route.snapshot.params['evaluationId']? this.route.snapshot.params['evaluationId'] : 0;
    this.apiService.delete('reportobjects/'+evaluationId, reportId)
        .then((data:any)=>{
          if(last){
            this.archivedList = [];
            this.viewArchived();
            this.loader.closeLoader(['folder-delete-archived'])
            this.modalService.adviceModal(this.translate.instant('concept-list.delete-archive-reports'), 'info:accept:check-round');
          }
        }, (errorData:any)=>{
          if(last){
            this.modalService.adviceModal(this.translate.instant('common.unexpected-error'), 'error:accept:warning');
            this.loader.closeError();
          }
        })
  }
}
