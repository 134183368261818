import moment from 'moment';
import { cloneDeep, remove } from 'lodash';

import { Injectable } from '@angular/core';

import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ContextFactory } from '@shared/factories/context/context.factory';

import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

import pattern from '../../../../../assets/data/pattern.json'

@Injectable({
  providedIn: 'root'
})
export class TaskFormParentService {

  subscribers: any = {};
  constructor(private translate: AfTranslateFactory,
    private context: ContextFactory,
    private broadcastService: BroadcastService,
    private modalService: ModalServiceService,
    private apiService: ApiServiceService,
    private dateConverterService: DateConverterService,
    private commonUtilsService: CommonUtilsService) { }

    configureTaskForm(child:any){
        return {
            showAccept: true,
            hasContacts: false,
            typeShareDocumentSelected: '0',
            saveTask: null,
            titleParticipants: 'task',
            videoconferences: Boolean(this.context.user.canUseJitsi),
            taskDate: null,
            repetitionActived: false,
            showErrorHourOrDate: false,
            description: false,
            canCreate: child.userPermissions !== 'none',
            tinyMceOptions: {
              typeView: 'task',
              noEditable: false,
              height: '301px',
              toolbar: 'bold italic underline | alignleft aligncenter alignright alignjustify | undo redo | bullist numlist outdent indent | removeformat | link',
            },
            taskDescription: {
              label: this.translate.instant('common.description')
            },
            taskReach: {
                type: 'reach',
                model: 'Evaluation',
                required: true,
                label: this.translate.instant('task-edit.task-scope')
            },
            taskEvaluation: {
                required: true,
                label: this.translate.instant('common.implantation')
            },
            taskClient: {
                required: true,
                isTree: true,
                toFind: 'text',
                id: 'task-client-id',
                label: this.translate.instant('common.organization')
            },
            taskTitle: {
                id: "edt-task-title",
                required: true,
                maxLength: 250,
                label: this.translate.instant('common.title')
            },
            typeOfTask: {
                id: "edt-task-type",
                required: true,
                disabled:false,
                label: this.translate.instant('task-concept.task-type')
            },
            taskExpirationDate: {
                id:"edt-task-expiration",
                from:'task',
                label: this.translate.instant('task-edit.expiration-date')
            },
            stateStartTime: {
                id:'add-alert-start-time',
                label: this.translate.instant('task-form.hour-init'),
                required: true
            },
            stateEndTime: {
                id:'add-alert-end-time',
                label: this.translate.instant('task-form.hour-finish'),
                required: true
            },
            taskModality: {
                type: 'typemeeting',
                model: 'FaceToFace',
                required: true,
                label: this.translate.instant('task-form.modality')
            },
            securitySettings: {
                type: 'securitysettings',
                model: 'Open',
                required: true,
                label: this.translate.instant('task-form.security-settings'),
            },
            taskPlace: {
                id:"edt-task-place",
                required:  true,
                label: this.translate.instant('task-edit.place'),
                text: this.translate.instant('task-edit.place')
            },
            repeatPeriod: {
                text: this.translate.instant('repeat-pattern.repeat-period'),
                disabled: Boolean(child.isLanding) || child.userPermissions === 'none' || Boolean(child.task && child.task.repeatPatternReadonly)
            },
            shareTypeDocumentField: {
              multiple: false,
              required: true,
              type: 'modal',
              label: this.translate.instant('share-document.type-share-label')
            },
            shareDocumentationField: {
              label: this.translate.instant('share-document.restricted-select'),
              multiple: true,
              required: true,
            },
            typeShareDocument: [{
                listValueId:'0',
                text: this.translate.instant('share-document.no-share-documents')
              },{
                listValueId:'1',
                text: this.translate.instant('share-document.share-all-documents')
              },{
                listValueId:'2',
                text: this.translate.instant('share-document.share-selected-documents')
            }]

          }
    }

    initWatchers(child:any){
      this.subscribers.changeCloseTaskStateBroadcast = this.broadcastService.subscribe('changeCloseTaskState', (data:any)=>{
        this.configureFieldsWithTask(child);
      })
      this.subscribers.disableTaskTypeInputBroadcast = this.broadcastService.subscribe('disableTaskTypeInput', (data:any)=>{
        this.disableTaskTypeInput(child);
      })
    }

    disableTaskTypeInput(child:any){
      let that: any = child.taskForm;
      if(child.currentComponent === 'genericTask'){
        that.taskReach.noEditable = true;
        that.taskEvaluation.noEditable = true;
        that.taskClient.noEditable = true;
      }
      that.taskExpirationDate.showAlertButton = true;
      for (let i = 0; i < that.typeList.length; i++) {
          if (that.task.taskTypeId === that.typeList[i].taskTypeId) {
              that.task.type = that.typeList[i].taskTypeName;
              break;
          }
      }
      that.typeOfTask.noEditable = true;
      that.taskModality.noEditable = that.task.taskTypeId === 4;
      that.securitySettings.noEditable = that.task.taskTypeId === 4;
    }

    getPatterns(child:any){
      child.taskForm.patternList = pattern;
      this.configurePatternList(child);
      if(child.taskForm.task.repeatPattern && child.taskForm.task.repeatPattern.frecuencyId){
          child.taskForm.repetitionActived = true;
          this.configureRepeatPattern(child);
      }
    }

    configurePatternList(child:any){
      let that: any = child.taskForm;
      that.patternList.weekDays.forEach((weekDays:any) => {
        weekDays.text = moment().weekday(weekDays.value).format('ddd')
      });
      that.patternList.monthList.forEach((monthList:any) => {
        monthList.text = moment().month(monthList.value).format('MMMM')
      });
      that.patternList.periodicityList.forEach((periodicityList:any) => {
        periodicityList.text = this.translate.instant(periodicityList.text)
      });
      that.patternList.numeralTimeList.forEach((numeralTimeList:any) => {
        numeralTimeList.text = this.translate.instant(numeralTimeList.text)
      });
      that.patternList.weekDayList.forEach((weekDayList:any) => {
        weekDayList.text = weekDayList.id < 10? moment().weekday(weekDayList.value).format('dddd') : this.translate.instant(weekDayList.text);
      });
    }


    cancelMeeting(child:any){
        this.modalService.adviceModal(this.translate.instant('task-form.cancel-meeting-confirm'), 'info:question:question')
          .subscribe((result:any)=>{
            if(result.result ==='ok'){
              this.closeMeeting(child)
            }
          })
    }

    closeMeeting(child:any){
      let taskCopy = cloneDeep(child.taskForm.task);
      taskCopy.taskStateId = 5;
      taskCopy.client = child.taskForm.taskClient
      taskCopy.createdBy = child.taskForm.task.createdUserId;
      taskCopy.evaluation = child.taskForm.evaluations.find((evaluation:any) => evaluation.evaluationId = child.taskForm.task.evaluationId)
      this.apiService.patch('tasks/changestate', child.taskForm.task.taskId, taskCopy)
          .then(()=>{
             this.cancelCreateTask(child);
          }, (error:any)=> {});
    }

    cancelCreateTask(child:any){
      child.taskForm.task.taskStateId = 5;
      child.taskForm.task.state = this.translate.instant('common.closed');
      this.modalService.adviceModal(this.translate.instant('task-form.cancel-meeting-ok'), 'info:accept:check-round')
        .subscribe((result:any)=>{
            child.cancelTask.emit();
        })
    }

    sendNotify(child:any){
        this.modalService.sendReminder(child.taskForm.task)
          .subscribe((result:any)=>{
            if(result.result==='ok'){
              this.modalService.adviceModal(result.text, 'info:accept:check-round');
            }
          })
    }

    configureTaskInit(child:any) {
        this.initTinyMce(child, false);
        this.configureTask(child);
        this.configureFields(child);
        this.getTaskTypes(child);
        if(child.currentComponent === 'genericTask'){
            let clients = child.route.snapshot.data['clients'];
            let evaluations = child.route.snapshot.data['evaluations'];
            this.getClients(child, clients);
            this.getEvaluations(child, evaluations);
        }else if(child.taskForm.task.taskTypeId === 4){
            this.getApiClients(child);
            this.getApiEvaluations(child);
        }
    }

    configureTask(child:any) {
        let that: any = child.taskForm;
        let task: any = that.task;
        if (task && task.taskId){
            that.newValueId = task.evaluationId;
            that.isNew = false;
            if (task.taskStateName !== undefined) {
                task.state = task.taskStateName;
            }
            if (task.participants && task.participants.length) {
                let size = task.participants.length - 1;
                that.participantsTask = task.participants[size].id;
            }
            if (task.expirationDate) {
                that.taskDate = this.dateConverterService.toDate(that.task.expirationDate);

            }
            if (task.clientId && !task.evaluationId) {
                that.taskReach.model = 'Client';
                that.newValueId = task.clientId;
            }else if(task.taskTypeId === 7){
                that.taskReach.model = 'process-task';
            }
        } else {
            that.userLogin = this.context.user.fullName;
            that.isNew = true;
        }

        if (that.shared && !that.field) { // desde el botón de crear tarea que hay en concept tab
            task.title = that.concept.title;
            task.taskTypeId = 3;
            task.type = this.translate.instant('common.evaluation');
        }

        that.taskContactSimple ={
            id:'edit-task-contact',
            typeConfiguration: that.taskReach.model,
            fieldType: 'multiple',
            addContacts: !(child.isLanding || that.task.taskTypeId ===7),
            required: true,
            typeCall: this.configureTypeCallTaskContact(child),
            selectContacts: that.task.participants?that.task.participants:[],
            optionType: that.taskReach.model,
            label: that.taskReach.model === 'process-task'? this.translate.instant('field-type-contacts.users') : this.translate.instant('common.users-contacts')
        }

        that.evaluationParticipant ={
            id:'edit-task-participant',
            typeConfiguration: that.titleParticipants,
            fieldType: 'multiple',
            addContacts: !child.isLanding,
            required: true,
            typeCall: 'groups',
            selectContacts: that.task.participants? that.task.participants : [],
            optionType: that.taskReach.model,
            label: this.translate.instant('contacts.recipients')
        }
    }

    configureTypeCallTaskContact(child:any){
        if(child.taskForm.taskReach.model === 'process-task'){
            return 'users';
        }else if(child.taskForm.titleParticipants === 'task-comunication' && child.taskForm.concept?.conceptNamedKey !== 'SujetoObligado'){
            return 'groups';
        }else{
            return 'contacts';
        }
    }

    configureTaskIdSelected(newValue:any, child:any) {
        if (newValue === 2) {
            child.taskForm.titleParticipants = 'sign';
        }
        else if (newValue === 4) {
            child.taskForm.titleParticipants = 'meeting';
            child.taskForm.taskExpirationDate.label = this.translate.instant('task-edit.meeting-date');
            child.taskForm.taskExpirationDate.required = true;
        } else {
            if (newValue === 5) {
                child.taskForm.titleParticipants = 'task-comunication';
                child.taskForm.taskExpirationDate.label = this.translate.instant('task-edit.communication-date');
                child.taskForm.taskExpirationDate.required = true;
            } else {
                child.taskForm.taskExpirationDate.label = this.translate.instant('task-edit.expiration-date');
                child.taskForm.taskExpirationDate.required = false;
            }
        }
        child.taskForm.evaluationParticipant.typeConfiguration = child.taskForm.titleParticipants;
        child.taskForm.task.taskTypeId = newValue;
    }

    initTinyMce( child:any, hidetiny:any = false) {
        child.taskForm.tinyMceOptions.noEditable = !child.taskForm.canCreate || hidetiny?true:false;
        child.taskForm.tinyMceOptions.toolbar = !child.taskForm.canCreate || hidetiny?'':'bold italic underline | alignleft aligncenter alignright alignjustify | undo redo | bullist numlist outdent indent | removeformat | link';
    }

    configureFields(child:any) {
        let that:any = child.taskForm
        this.configureFieldsRadioOptions(child);
        if (that.task?.taskId) {
            that.typeOfTask.noEditable = true;
            that.typeOfTask.disabled = true;
            that.taskExpirationDate.showAlertButton = true;
            that.taskModality.noEditable = true;
            that.securitySettings.noEditable = true;
            if(that.currentComponent === 'genericTask'){
                that.taskEvaluation.noEditable = true;
                that.taskClient.noEditable = true;
            }
            if (that.newValueId) {
                that.taskReach.noEditable = true;
            }
            that.shareTypeDocumentField.noEditable = child.task.taskStateId == 5 || child.task.taskStateId == 6
            that.shareTypeDocumentField.required = child.task.taskStateId != 5 && child.task.taskStateId != 6
            this.configureTaskIdSelected(that.task.taskTypeId, child);
        }else if (!that.task.taskId) {
          if(that.task.taskTypeId === 3){
              that.typeOfTask.noEditable = child.disabledTaskEvaluation;
          }
          that.taskExpirationDate.showAlertButton = false;
          this.configureTaskIdSelected(that.task.taskTypeId, child);
        }
        if (that.shared && !that.field) {
            that.typeOfTask.noEditable = true;
        }
        this.configureFieldsWithTask(child);
    }

    configureFieldsRadioOptions(child:any){
        let that: any = child.taskForm;
        that.taskReach.canCreate = child.taskForm.canCreate;
        that.taskModality.canCreate = child.taskForm.canCreate;
        that.securitySettings.canCreate = child.taskForm.canCreate;
        if(that.task.videoconference){
            that.taskModality.model = 'Videoconference';
            that.taskPlace.required = false;
            that.taskModality.noEditable = true;
            if(that.task.virtualRoomWithPassword){
                that.securitySettings.model = 'Private';
                that.securitySettings.noEditable = true;
            }
        }

        that.listOptionsReach = [
            {
                id: 'task-edit-associated-evaluation',
                name: this.translate.instant('task-form.evaluation-task'),
                value: 'Evaluation'
            },
            {
                id: 'task-edit-associated-client',
                name: this.translate.instant('task-form.client-task'),
                value: 'Client'
            }
        ];

        that.listOptionsTaskModality = [
            {
                id: 'task-edit-meeting-face-to-face',
                name: this.translate.instant('task-form.face-to-face'),
                value: 'FaceToFace'
            },
            {
                id: 'task-edit-meeting-videoconference',
                name: this.translate.instant('task-form.videoconference'),
                value: 'Videoconference'
            }
        ];

        that.listOptionsSecuritySettings = [
            {
                id: 'task-edit-security-settings-open',
                name: this.translate.instant('task-form.open-meeting'),
                value: 'Open'
            }
        ];
    }

    configureFieldsWithTask(child:any){
        let that:any = child.taskForm
        if(!child.taskForm.canCreate || (that.task && (that.task.taskStateId === 5 || that.task.taskStateId === 6))){
            this.changeFieldToEditable(['taskTitle', 'taskExpirationDate', 'taskContactSimple', 'taskEvaluation', 'taskClient', 'taskReach', 'typeOfTask', 'taskDescription', 'stateStartTime', 'stateEndTime', 'taskPlace', 'typeOfTask', 'evaluationParticipant'], true, child);
            this.initTinyMce(child, true);
            that.showAccept = (that.task.taskStateId === 5 && !that.task.repeatPatternReadonly && !child.isLanding && that.task.repeatPattern !== null);
            that.repeatPeriod.disabled = true;
        }else{
            switch(that.task.taskTypeId){
                case 1:
                case 6:
                    if(child.isLanding){
                        this.changeFieldToEditable(['taskTitle', 'taskExpirationDate', 'taskContactSimple', 'taskEvaluation', 'taskClient', 'taskReach', 'typeOfTask', 'evaluationParticipant'], true, child)
                        that.showAccept = true;
                    }
                    break;
                case 3:
                    if(child.isLanding){
                        this.changeFieldToEditable(['taskTitle', 'taskExpirationDate', 'taskContactSimple', 'taskEvaluation', 'taskClient', 'taskReach', 'typeOfTask', 'evaluationParticipant'], true, child);
                        that.showAccept = true;
                    }else{
                        if(that.isNew){
                            that.showAccept = true;
                        }else if (that.task.taskStateId === 4){
                            if(that.task.createdUserId === this.context.user.userId){
                                this.changeFieldToEditable(['taskTitle', 'taskEvaluation', 'taskClient', 'taskReach', 'typeOfTask'], true, child);
                            }else{
                                this.changeFieldToEditable(['taskTitle', 'taskExpirationDate', 'taskContactSimple', 'taskEvaluation', 'taskClient', 'taskReach', 'typeOfTask', 'evaluationParticipant'], true, child);
                            }
                            that.showAccept = true;
                        }else{
                            if(that.task.createdUserId === this.context.user.userId){
                                this.changeFieldToEditable(['taskEvaluation', 'taskClient', 'taskReach', 'typeOfTask', 'evaluationParticipant'], true, child);
                                that.showAccept = true;
                            }else{
                                this.changeFieldToEditable(['taskTitle', 'taskExpirationDate', 'taskContactSimple', 'taskEvaluation', 'taskClient', 'taskReach', 'typeOfTask', 'taskDescription', 'evaluationParticipant'], true, child);
                                that.showAccept = false;
                            }
                        }
                    }
                    break;
                case 5:
                    if(child.isLanding){
                        if(that.task.createdUserId !== this.context.user.userId){
                            this.changeFieldToEditable(['taskTitle', 'taskExpirationDate', 'taskContactSimple', 'taskEvaluation', 'taskClient', 'taskReach', 'taskDescription', 'typeOfTask', 'evaluationParticipant'], true, child);
                            this.initTinyMce(child, true);
                            that.showAccept = false;
                        }
                    }else{
                        if(moment(that.task.expirationDate).format('DD/MM/YYYY').valueOf() < moment().format('DD/MM/YYYY').valueOf()){
                            this.changeFieldToEditable(['taskTitle', 'taskExpirationDate', 'taskContactSimple', 'taskEvaluation', 'taskClient', 'taskReach', 'taskDescription', 'typeOfTask', 'evaluationParticipant'], true, child);
                            this.initTinyMce(child, true);
                            that.showAccept = Boolean(!that.task.repeatPatternReadonly);
                        }
                    }
                    break;
            }
        }
    }

    changeFieldToEditable(listFields:any, value:any, child:any) {
        for(let i = 0; i < listFields.length; i++) {
            let field = child.taskForm[listFields[i]];
            field.noEditable = value;
            field.required = !value;
        }
    }

    getTaskTypes(child:any) {
        this.apiService.get('tasks/types')
            .then((data:any)=>{
                //No se pueden crear tareas de proceso
                remove(data, { taskTypeId: 7 });
                this.configureTypes(data, child);
                child.taskForm.typeList = data;
                if(child.currentComponent === 'genericTask' || child.taskForm.field?.fieldId){
                    for (let i = 0; i < child.taskForm.typeList.length; i++) {
                        if(child.taskForm.typeList[i].taskTypeId === 3){
                            child.taskForm.typeList.splice(i, 1);
                            break;
                        }
                    }
                }else if(child.hasOpenEvaluationTask){
                    for (let i = 0; i < child.taskForm.typeList.length; i++) {
                        if(child.taskForm.typeList[i].taskTypeId === 3){
                            child.taskForm.typeList[i].disabled = true
                            break;
                        }
                    }
                }
            }, (errorData:any)=> {
                child.taskForm.typeList = [];
            });
    }

    configureTypes(list:any, child:any) {
        list.forEach((item:any) => {
            item.text = item.taskTypeName;
            item.listValueId = item.taskTypeId;
            if (child.taskForm.task.type && item.taskTypeName === child.taskForm.task.type) {
                item.taskTypeName = child.taskForm.task.type;
                child.taskForm.task.taskTypeId = item.listValueId;
            }
        });
    }

    itemSelected(newValue:any, type:string, child:any) {
        let that: any = child.taskForm;
        switch (type) {
            case 'expirationDate':
                this.configureTimeSelect(newValue, child);
                break;
            case 'taskTypeId':
                if(that.task.taskTypeId !== newValue){
                    that.task.repeatPattern = null
                    that.repetitionActived = false;
                    that.task.type = this.getTitleText(that.task, child);
                }
                that.task.participants = [];
                that.titleParticipants = 'task';
                this.configureTaskIdSelected(newValue, child);
                break;
            case 'taskClientId':
                that.newValueId = newValue;
                that.task.clientId = newValue;
                that.task.evaluationId = null;
                break;
            case 'taskEvaluationId':
                that.newValueId = newValue;
                that.task.evaluationId = newValue;
                that.task.clientId = null;
                break;
            default:
                that.task[type] = newValue;
                break;
        }
    }

    configureTimeSelect(newValue:any, child:any) {
        child.taskForm.task.expirationDate = newValue;
        if (newValue !== null && newValue !== undefined) {
            child.taskForm.task.expirationDate = this.dateConverterService.toIso(newValue);
        }else if (newValue === null){
            child.taskForm.task.expirationDate = '';
            child.taskForm.task.repeatPattern = null;
            child.taskForm.repetitionActived = false;
            child.taskForm.task.type = this.getTitleText(child.taskForm.task, child)
        }
        if(child.taskForm.task.repeatPattern && child.taskForm.task.repeatPattern.repeatStart && child.taskForm.task.taskTypeId === 5){
            child.taskForm.task.repeatPattern.repeatStart = this.dateConverterService.toIso(newValue);
            child.taskForm.repeatPattern.startInterval = this.dateConverterService.toIso(newValue);
            this.getNextRepeat(child);
        }
    }

    getApiEvaluations(child: any){
      this.apiService.get('company', 'dashboard/evaluations')
        .then((data:any) => {
          this.getEvaluations(child, data);
        },(errorData:any)=>{})
    }

    getEvaluations(child:any, evaluations:any) {
        let settings: any = this.context.settings;
        if(child.skin === 'steel-blue' || child.taskForm.task.taskTypeId === 4){
          child.taskForm.evaluations = this.commonUtilsService.editList(evaluations, 'evaluationId', 'name');
        }else{
            let productIdSelected = '';
            if(child.taskForm.task && child.taskForm.task.taskEvaluationId){
                let evaluationSelected = this.searchProductEvaluation(evaluations, child.taskForm.task.taskEvaluationId);
                productIdSelected = evaluationSelected.productId;
                settings.defaultProduct = evaluationSelected.productId;
                settings.defaultProductName = evaluationSelected.productName;
                settings.productWithSnippets = evaluationSelected.snippets;
                this.context.storeSettings();
            }else if(child.currentComponent === 'genericTask' && child.skin === 'icam-red'){
                child.taskForm.task.evaluationId = evaluations[0].evaluationId;
                child.taskForm.newValueId = child.taskForm.task.evaluationId;
            }else if(this.context && settings && settings.defaultProduct){
                productIdSelected = settings.defaultProduct;
            }
            child.taskForm.evaluations = this.getEvaluationsForProduct(evaluations, productIdSelected);
        }
    }

    getEvaluationsForProduct(data:any, productIdSelected:any){
        let evaluationsList:any = [];
        data.forEach((evaluation:any)=>{
            evaluation.text = evaluation.name;
            evaluation.listValueId = evaluation.evaluationId;
            if(productIdSelected === evaluation.productId){
                evaluationsList.push(evaluation);
            }
        })
        return evaluationsList;
    }

    searchProductEvaluation(data:any, taskEvaluationId:any){
        for (let i = 0; i < data.length; i++) {
            let evaluation:any = data[i];
            if(taskEvaluationId === evaluation.evaluationId){
                return evaluation;
            }
        }
    }

    getApiClients(child: any){
      this.apiService.get('clients/root/true/read')
        .then((data:any) => {
          this.getClients(child, data);
        },(errorData:any)=>{})
    }

    getClients(child:any, data: any) {
        let that: any = child.taskForm;
        that.clientListComplete = false;
        that.clients = [];
        that.allClients = [];
        that.filialsCount = 0;
        that.filialsResolvedCount = 0;
        that.finishedGetClients = false;
        data.forEach((client:any, index: any) => {
          if(data.length - 1 === index){
            that.finishedGetClients = true;
          }
          if (client.active) {
              client.text = client.name;
              client.listValueId = client.clientId;
              that.allClients.push(client);
              if(client.hasSubsidiaries){
                that.filialsCount++;
                this.getFilials(client, child);
              }else{
                that.clients.push(client);
                if(that.finishedGetClients && that.filialsResolvedCount === that.filialsCount){
                  child.taskForm.clientListComplete = true;
                }
              }
          }else if(that.finishedGetClients && that.filialsResolvedCount === that.filialsCount){
            child.taskForm.clientListComplete = true;
          }
        })
    }

    getFilials(client:any, child:any){
        this.apiService.get('clients/affiliatedcompaniestree', client.clientId)
            .then((data:any) => {
                client.children = data[0].children;
                this.configureChildrenName(client.children, child);
                child.taskForm.clients.push(client);
                child.taskForm.filialsResolvedCount++;
                if(child.taskForm.finishedGetClients && child.taskForm.filialsResolvedCount === child.taskForm.filialsCount){
                    child.taskForm.clientListComplete = true;
                }
            },(errorData:any)=>{})
    }

    configureChildrenName(children:any, childThis:any){
        children.forEach((child:any) => {
            child.text = child.name;
            child.listValueId = child.clientId;
            childThis.taskForm.allClients.push(child);
            if(child.children.length){
                this.configureChildrenName(child.children, childThis);
            }
        });
    }

    configureRepeatPattern(child:any){
        let task:any = child.taskForm.task;
        child.taskForm.repeatPattern = {
            frecuency: this.commonUtilsService.extractToArray(task.repeatPattern.frecuencyId, child.taskForm.patternList.periodicityList, 'listValueId').text,
            startInterval: task.repeatPattern.repeatStart
        }
        child.taskForm.repeatPattern.selectInterval = this.getSelectInterval(child);
        child.taskForm.repeatPattern.finishInterval = this.extractFinishInterval(child.taskForm.repeatPattern.selectInterval, task.repeatPattern.repeatUntil, task.repeatPattern.repeatTimes, child);
        child.taskForm.repeatPattern.momentOfCreation = this.setMomentCreation(task.repeatPattern.frecuencyId, task.repeatPattern.repeatOption, task.repeatPattern, child)
    }

    getSelectInterval(child:any){
        let task: any = child.taskForm.task
        if(task.repeatPattern.repeatTimes !== null){
            return 'finish-after';
        }else if(task.repeatPattern.repeatUntil !== null){
            return 'end-interval-day';
        }else{
            return 'no-end-date';
        }
    }

    setMomentCreation(frecuency:any, repeatOption:any, params:any, child:any){
        let that: any = child.taskForm;
        let task: any = child.taskForm.task;
        switch(frecuency){
            case 1:
                if(repeatOption === 1){
                    task.repeatPattern.repeatParam1 = params.repeatParam1;
                    return this.translate.instant('add-alert.every')+" "+
                        params.repeatParam1 +" "+
                        this.translate.instant('repeat-pattern.days');
                }else{
                   return this.translate.instant('repeat-pattern.all-days-week');
                }
            case 2:
                let weekSelect = this.extractWeekSelect(params.repeatParam2, child);
                task.repeatPattern.repeatParam1 = params.repeatParam1;
                task.repeatPattern.repeatParam2 = params.repeatParam2;
               return this.translate.instant('repeat-pattern.repeat-every')+" "+
                    params.repeatParam1 +" "+
                    this.translate.instant('repeat-pattern.weeks-in-days') +" "+
                    weekSelect;
            case 3:
                if(repeatOption === 1){
                    task.repeatPattern.repeatParam1 = params.repeatParam1;
                    task.repeatPattern.repeatParam2 = params.repeatParam2;
                   return this.translate.instant('repeat-pattern.the-day') +" "+
                        params.repeatParam1 +" "+
                        this.translate.instant('repeat-pattern.kind') +" "+
                        params.repeatParam2 +" "+
                        this.translate.instant('repeat-pattern.month');
                }else{
                    task.repeatPattern.repeatParam1 = params.repeatParam1
                    task.repeatPattern.repeatParam2 = params.repeatParam2
                    task.repeatPattern.repeatParam3 = params.repeatParam3
                   return this.translate.instant('repeat-pattern.the') +" "+
                        this.commonUtilsService.extractToArray(params.repeatParam1, that.patternList.numeralTimeList, 'id').text +" "+
                        this.commonUtilsService.extractToArray(params.repeatParam2, that.patternList.weekDayList, 'id').text +" "+
                        this.translate.instant('repeat-pattern.kind') +" "+ params.repeatParam3 +" "+ this.translate.instant('repeat-pattern.month');
                }
            default:
                if(repeatOption === 1){
                    task.repeatPattern.repeatParam1 = params.repeatParam1;
                    task.repeatPattern.repeatParam2 = params.repeatParam2;
                   return this.translate.instant('repeat-pattern.the') +" "+
                        params.repeatParam1 +" "+
                        this.translate.instant('repeat-pattern.kind') +" "+
                        this.commonUtilsService.extractToArray(params.repeatParam2, that.patternList.monthList, 'id').text;
                }else{
                    task.repeatPattern.repeatParam1 = params.repeatParam1
                    task.repeatPattern.repeatParam2 = params.repeatParam2
                    task.repeatPattern.repeatParam3 = params.repeatParam3
                   return this.translate.instant('repeat-pattern.the') +" "+
                        this.commonUtilsService.extractToArray(params.repeatParam1, that.patternList.numeralTimeList, 'id').text +" "+
                        this.commonUtilsService.extractToArray(params.repeatParam2, that.patternList.weekDayList, 'id').text +" "+
                        this.translate.instant('repeat-pattern.kind') +" "+
                        this.commonUtilsService.extractToArray(params.repeatParam3, that.patternList.monthList, 'id').text;
                }

        }
    }

    addOrEditTask(child:any) {
        let that: any = child.taskForm;
        let type: string = 'saveTask';
        if (that.task.taskStateId === 5 && !that.task.repeatPatternReadonly && that.modifyRepeatPattern){
            let task:any = {
                taskTypeId: that.task.taskTypeId,
                title: that.task.title,
                description: that.task.description,
                participants: that.task.participants,
                expirationDate: that.task.expirationDate,
                taskId: that.task.taskId,
                location: that.task.location,
                beginHour: that.task.beginHour,
                endHour: that.task.endHour,
                repeatPattern: that.task.repeatPattern,
                url: that.task.url,
                password: that.task.password
            };
            child.launchAction.emit({
                action: {
                    type: type,
                    task: task
                }
            });
            that.modifyRepeatPattern = false;
        }else if (this.validateFieldsForm(child)) {
            let task = that.task;
            if (that.task.taskId) {
                task = {
                    taskTypeId: that.task.taskTypeId,
                    title: that.task.title,
                    description: that.task.description,
                    participants: that.task.taskId? this.configureParticipants(that.task.participants) : that.task.participants,
                    expirationDate: that.task.expirationDate,
                    taskId: that.task.taskId,
                    location: that.task.location,
                    beginHour: that.task.beginHour,
                    endHour: that.task.endHour,
                    repeatPattern: that.task.repeatPattern,
                    url: that.task.url,
                    password: that.task.password,
                    videoconference: that.task.videoconference,
                    virtualRoomWithPassword: that.task.virtualRoomWithPassword
                };
            }else if(child.currentComponent === 'genericTask'){
                type = 'createGenericTask';
            } else{
                type = 'createTask';
            }
            if(that.task.taskTypeId === 3 && that.hasContacts){
              task.documentsShareType = that.typeShareDocumentSelected;
              if(task.documentsShareType === '2'){
                if(that.task.taskId){
                  task.sharedDocumentObjectsInEvaluationTask = this.configureSharedDocumentsInTask(child)
                }else{
                  task.sharedDocumentObjects = this.extractDocumentObjects(child);
                }
              }
            }
            child.launchAction.emit({
                action:{
                    type: type,
                    task: task
                }
            });
        }
        if(type === 'createTask'){
            this.getApiEvaluations(child);
        }
    }

    configureParticipants(participants:any){
      participants.forEach((participant:any) => {
        if(participant.type === 'U' && !participant.userId){
          participant.userId = participant.taskParticipantId;
        }else if(participant.type === 'C' && !participant.contactId){
          participant.contactId = participant.taskParticipantId;
        }else if(participant.type === 'G' && !participant.groupId){
          participant.groupId = participant.taskParticipantId;
        }else if(!participant.productRoleId){
          participant.productRoleId = participant.taskParticipantId;
        }
      });
      return participants;
    }

    configureSharedDocumentsInTask(child: any){
      let list: any = [];
      let documentsSelected: any = child.taskForm.shareDocumentationListSelected.indexOf('|') === -1? [child.taskForm.shareDocumentationListSelected] : child.taskForm.shareDocumentationListSelected.split('|');
      documentsSelected.forEach((id:any) => {
        child.taskForm.shareDocumentationList.forEach((document:any)=>{
          if(document.documentObjectId === parseInt(id)){
            list.push({
              tasktDocumentObjectId: this.findInShared(document.documentObjectId, child),
              taskId: child.task.taskId,
              documentObjectId:document.documentObjectId,
            })
          }
        })
      });
      return list;
    }

    findInShared(documentObjectid:any, child:any){
      let document: any = child.taskForm.task.sharedDocumentObjectsInEvaluationTask.filter((document:any)=>{
        if(document.documentObjectId === documentObjectid){
          return document;
        }
      })[0];

      return document? document.tasktDocumentObjectId: 0;
    }

    extractDocumentObjects(child:any){
      let arrayDocuments:any = [];
      let documentsSelected: any = child.taskForm.shareDocumentationListSelected.indexOf('|') === -1? [child.taskForm.shareDocumentationListSelected] : child.taskForm.shareDocumentationListSelected.split('|');
      documentsSelected.forEach((document:any)=>{
        arrayDocuments.push(parseInt(document))
      })
      return arrayDocuments;
    }

    validateFieldsForm(child:any) {
        let that: any = child.taskForm;
        let task: any = that.task;
        that.taskExpirationDate.error = false;
        that.taskTitle.error = task.title === '' || !task.title;
        that.continue = false;
        that.showErrorHourOrDate = false;
        that.errorDate = false;
        that.errorHourOrDate = this.translate.instant('task-edit.error-info-configure');
        let validDate = true;
        if(this.checkParticipants(child)){
            that.taskContactSimple.error = true;
            that.evaluationParticipant.error = true;
            this.modalService.adviceModal(this.translate.instant('task-form.error-participants'), 'error:accept:warning');
            return false;
        }
        if(task.expirationDate){
            let hourSelected = task.taskTypeId === 4 && task.beginHour !== '00:00:00'? task.beginHour : null;
            validDate = this.dateConverterService.compareValidateDates(task.expirationDate, hourSelected);
        }
        that.showErrorHourOrDate = !validDate;
        if(that.showErrorHourOrDate){
            that.errorHourOrDate = that.errorHourOrDate + ' ' + this.translate.instant('task-edit.error-info-date');
        }
        that.errorDate = that.showErrorHourOrDate;
        if (child.currentComponent === 'genericTask' && child.skin !== 'icam-red'){
            this.validateGeneric(child);
        }

        if(!(task.taskTypeId === null || task.taskTypeId === undefined || task.taskTypeId === '')){
            switch(task.taskTypeId){
                case 5:
                    that.typeOfTask.error = false;
                    that.evaluationParticipant.error = task.participants && task.participants.length < 1;
                    that.taskExpirationDate.error = task.expirationDate === '' || !task.expirationDate || !validDate;
                    return !(that.evaluationParticipant.error || that.continue || that.taskTitle.error || that.taskExpirationDate.error);
                case 4:
                    that.typeOfTask.error = false;
                    that.evaluationParticipant.error = task.participants && task.participants.length < 1;
                    that.taskExpirationDate.error = task.expirationDate === '' || !task.expirationDate || !validDate;
                    that.stateEndTime.error = task.beginHour >= task.endHour;
                    that.stateStartTime.error = task.beginHour === '00:00:00';
                    if(that.taskModality.model === 'FaceToFace'){
                        that.taskPlace.error = task.location === '' || !task.location;
                    }
                    if(that.stateEndTime.error){
                        that.showErrorHourOrDate = true;
                        if(that.showErrorHourOrDate){
                            that.errorHourOrDate = that.errorHourOrDate + ' ' + this.translate.instant('task-edit.error-info-hour');
                        }
                    }
                    // task.participants.error = task.participants && task.participants.length < 1;
                    return !(that.taskTitle.error || that.taskPlace.error ||  that.taskExpirationDate.error || that.stateStartTime.error || that.stateEndTime.error || that.evaluationParticipant.error || that.continue);
                case 2:
                    that.typeOfTask.error = false;
                    that.evaluationParticipant.error = task.participants && task.participants.length < 1;
                    return !(that.evaluationParticipant.error || that.taskTitle.error || that.continue);

                case 1:
                case 6:
                    that.typeOfTask.error = false;
                    that.taskContactSimple.error = task.participants && task.participants.length < 1;
                    that.taskExpirationDate.error = Boolean(task.expirationDate === undefined || !validDate);
                    return !(that.taskContactSimple.error || that.taskTitle.error || that.continue || that.taskExpirationDate.error);
                case 3:
                  that.typeOfTask.error = false;
                  that.shareDocumentationField.error = false;
                  that.taskContactSimple.error = task.participants && task.participants.length < 1;
                  that.taskExpirationDate.error = Boolean(task.expirationDate === undefined || !validDate);
                  that.shareDocumentationField.error = Boolean(that.hasContacts && that.typeShareDocumentSelected==='2' && (!that.shareDocumentationListSelected || that.shareDocumentationListSelected === ''));

                  return !(that.taskContactSimple.error || that.taskTitle.error || that.continue || that.taskExpirationDate.error || that.shareDocumentationField.error);
                default:
                    that.typeOfTask.error = false;
                    that.taskContactSimple.error = task.participants && task.participants.length < 1;
                    return !(that.taskContactSimple.error || that.taskTitle.error || that.continue);
            }

        }else{
            that.typeOfTask.error = true;
            that.taskExpirationDate.error = !validDate;
            return false;
        }
    }

    checkParticipants(child:any){
        for(let i = 0; i < child.taskForm.task.participants.length; i++){
            if(child.taskForm.task.participants[i].isDeleted){
                return true
            }
        }
        return false
    }

    validateGeneric(child:any) {
        if (child.taskForm.taskReach.model === 'Evaluation') {
            child.taskForm.taskEvaluation.error = child.taskForm.task.evaluationId === '' || !child.taskForm.task.evaluationId;
            child.taskForm.continue = child.taskForm.taskEvaluation.error;
        } else {
            child.taskForm.taskClient.error = child.taskForm.task.clientId === '' || !child.taskForm.task.clientId;
            child.taskForm.continue = child.taskForm.taskEvaluation.error;
        }
    }

    changeValueOption(option:any, child:any){
        let that: any = child.taskForm
        switch(option.type){
            case 'reach':
                delete that.newValueId;
                delete that.task.taskTypeId;
                that.taskContactSimple.typeConfiguration = option.option;
                that.taskContactSimple.optionType = option.option;
                that.evaluationParticipant.optionType = option.option;
                break;
            case 'typemeeting':
                that.taskPlace.required = true;
                that.task.videoconference = false;
                delete that.task.virtualRoomWithPassword;
                if(that.taskModality.model === 'Videoconference'){
                    that.taskPlace.required = false;
                    that.taskPlace.error = false;
                    that.task.videoconference = true;
                    that.task.virtualRoomWithPassword = false;
                }
                break;
            case 'securitysettings':
                that.task.virtualRoomWithPassword = false;
                if(that.securitySettings.model === 'Private'){
                    that.task.virtualRoomWithPassword = true;
                }
                break;
            default:
                break;
        }
    }

    modifyPattern( child:any, date:any = null){
        let task:any = child.taskForm.task;
        if(!task.repeatPatternReadonly && !child.isLanding && child.userPermissions !=='none'){
            if(task.taskTypeId === 5){
                if(task.expirationDate !== ''){
                    this.launchSetPattern(task.expirationDate, child);
                }
            }else{
                this.launchSetPattern(date, child);
            }
            child.taskForm.modifyRepeatPattern = true;
        }

    }

    launchSetPattern(date:any, child:any){
        this.modalService.setPattern(child.taskForm.patternList, child.taskForm.task, date).subscribe((result:any)=>{
            if(result.result === 'ok'){
                this.configureTaskRepeat(result, child)
                this.getNextRepeat(child);
                child.taskForm.task.type = this.getTitleText(child.taskForm.task, child)
            }else if(result.result === 'delete'){
                child.taskForm.task.repeatPattern = null
                child.taskForm.repetitionActived = !child.taskForm.repetitionActived;
                child.taskForm.task.type = this.getTitleText(child.taskForm.task, child)
            }
        })
    }


    configureTaskRepeat(result:any, child:any){
        child.taskForm.repetitionActived = true;
        child.taskForm.task.repeatPattern = {
            frecuencyId: result.frecuency.listValueId,
            repeatStart: this.dateConverterService.toIso(result.interval.startInterval),
            repeatOption: result.repetition.repeatOption,
            repeatTimes: null,
            repeatUntil: null,
            repeatParam1: null,
            repeatParam2: null,
            repeatParam3: null
        }

        child.taskForm.repeatPattern = {
            frecuency: result.frecuency.text,
            startInterval: result.interval.startInterval,
            finishInterval: this.extractFinishInterval(result.interval.selectInterval, result.interval.finishIntervalDate, result.interval.repeatTimes, child),
            selectInterval: result.interval.selectInterval
        }
        child.taskForm.repeatPattern.momentOfCreation = this.setMomentCreation(child.taskForm.task.repeatPattern.frecuencyId, child.taskForm.task.repeatPattern.repeatOption, result.repetition.repeatParams, child)
    }

    getNextRepeat(child:any){
        this.apiService.add('repeatpattern/next-repeat', child.taskForm.task.repeatPattern)
            .then((data:any)=>{
                child.taskForm.task.repeatPattern.nextRepeat = data.nextRepeat;
            }, (errorData:any)=>{})
    }

    extractFinishInterval(selectInterval:string, repeatUntil:any, repeatTimes:any, child:any){
        switch(selectInterval){
            case 'no-end-date':
                return ''
            case 'finish-after':
                child.taskForm.task.repeatPattern.repeatTimes = repeatTimes;
                return repeatTimes +' '+ this.translate.instant('repeat-pattern.repetitions');
            case 'end-interval-day':
                child.taskForm.task.repeatPattern.repeatUntil = repeatUntil;
                return typeof repeatUntil === 'string'?repeatUntil : this.dateConverterService.toIso(repeatUntil);
            default:
              return '';
        }
    }

    setPattern(child:any, newValue:boolean){
        let date:any = null;
        if(child.taskForm.showAccept){
            if(child.taskForm.task.taskTypeId === 5){
                date = child.taskForm.task.expirationDate;
            }
            if(!child.taskForm.repetitionActived){
                this.modifyPattern(child, date)
            }else{
                child.taskForm.task.repeatPattern = null;
                child.taskForm.task.type = this.getTitleText(child.taskForm.task, child);
                child.taskForm.repetitionActived = newValue;
                child.taskForm.modifyRepeatPattern = true;
            }
        }

    }

    extractWeekSelect(weekdayMaskEnum:any, child:any){
        let weekDays = '';
        child.taskForm.patternList.weekDays.forEach((weekdays:any) => {
            if((weekdays.bit && weekdayMaskEnum) === weekdays.bit){
                weekDays += moment().weekday(weekdays.value).format('dddd')+', '
            }
        });
        return weekDays.substring(0 , weekDays.length - 2);
    }

    copyText(type:any, child:any) {
        this.commonUtilsService.clipboardText(type);
    }



    getTitleText(task:any, child:any){
        switch(task.taskTypeId){
            case 1:
                return task.repeatPattern && task.repeatPattern.frecuencyId? this.translate.instant('task-edit.periodic-info-request') : this.translate.instant('task-edit.periodic-request')
            case 3:
                return task.repeatPattern && task.repeatPattern.frecuencyId? this.translate.instant('task-edit.periodic-evaluation') : this.translate.instant('common.evaluation')
            case 5:
                return task.repeatPattern && task.repeatPattern.frecuencyId? this.translate.instant('task-edit.periodic-comunication') : this.translate.instant('law-info.comm');
            case 6:
                return task.repeatPattern && task.repeatPattern.frecuencyId? this.translate.instant('task-edit.periodic-management') : this.translate.instant('task-edit.punctual-management');
            default:
                return task.type;

        }
    }

    ngOnDestroy(){
      this.commonUtilsService.OnDestroy(this.subscribers);
    }
}
