import { Component, OnInit, Input } from '@angular/core';
import { cloneDeep } from "lodash";
import { ApiPropertiesFactory } from '@shared/factories/api-properties/api-properties.factory';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { DocumentActionsService } from '@shared/services/document-actions/document-actions.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'reference-document',
  templateUrl: './reference-document.component.html'
})
export class ReferenceDocumentComponent implements OnInit {
  constructor(
    private apiProperties: ApiPropertiesFactory,
    private globalCfg: GlobalCfgFactory,
    private context: ContextFactory,
    private modalService: ModalServiceService,
    private translate: AfTranslateFactory,
    private loader: LoaderFactory,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private activeModal: NgbActiveModal,
    private broadcastService: BroadcastService,
    private documentActions: DocumentActionsService,
    private route: ActivatedRoute) { }

  @Input() configuration:any;

  skin: string = this.globalCfg.skin;
  referenceDocument:any = {
    // defaultLanguage: (this.skin === 'uk-steel-blue' || this.skin === 'steel-blue') ? this.context.settings.defaultProduct +'_' + this.apiProperties.defaultLanguage : this.apiProperties.defaultLanguage,
    dataProtectionView: (this.skin === 'dark-blue' || this.skin === 'steel-blue' || this.skin === 'gm-law'),
    types: ['protection', 'additional', 'legal'],
    languagesApp: cloneDeep(this.context.settings.languagesApp),
    evaluationId: this.route.snapshot.firstChild?.params['evaluationId']
  };

  ngOnInit(): void {
    let that = this.referenceDocument;
    this.getFormatDocument();
    if(this.skin !== 'steel-blue' && !this.configuration.concept.isFront && this.configuration.concept.communicationChannel){
      this.getConceptInfo();
      this.getFieldContainers();
    }
    if(that.dataProtectionView){
      for(let i = 0; i < that.types.length; i++){
        this.configureReferenceDocument(that.types[i]);
      }
    }
  }

  getFormatDocument(){
    let documentationList = this.configuration.documentationList;
    for(let i = 0; i < documentationList.length; i++){
        let document = documentationList[i];
        let name = document.name;
        document.extension = name.substr(name.lastIndexOf('.') + 1, name.length);
    }
  }

  getConceptInfo(){
    this.apiService.get('concepts', this.referenceDocument.evaluationId + '/' + this.configuration.concept.conceptId)
        .then((data:any)=>{
          // data.disabled = data.publicShared || data.shared;
          this.referenceDocument.conceptInfo = data;
        })
  }

  getFieldContainers(){
    let url : string = 'fieldcontainers/'+this.referenceDocument.evaluationId+'/'+this.configuration.concept.conceptId;
    this.apiService.get(url)
      .then(
        (data:any) => {
          if(data?.length){
            if(data[0].fields?.length){
              this.referenceDocument.fieldContainerConfigurated = data[0];
              this.referenceDocument.fieldContainerConfiguratedPreview = cloneDeep(data[0]);
            }else{
              this.deleteAdditionalGroup(data[0]);
            }
          }
        }, (errorData:any)=>{});
  }

  configureReferenceDocument(type:string){
    let that = this.referenceDocument;
    let config = this.configuration;

    that[type + 'SelectedLanguages'] = [];
    if(config[type + 'Data'] && config[type + 'Data'].length){
        this.configureSelectedLanguages(type);
    }else if(type === 'additional'){
        this.selectListLanguagesSelected(type, this.apiProperties.defaultLanguage, true, '');
    }else{
        this.getDefaultLanguage(type);
    }
  }

  getDefaultLanguage(type: any){
    let textToTranslate: string = type === 'protection'? 'text-default' : 'text-default-legal';
    if(this.translate.hasLanguage(this.apiProperties.defaultLanguage)){
      let textDefault = this.translate.instantSiteTerms(textToTranslate, this.apiProperties.defaultLanguage);
      this.selectListLanguagesSelected(type, this.apiProperties.defaultLanguage, true, textDefault);
    }else{
      this.translate.getTranslates(this.apiProperties.defaultLanguage).then((data:any)=>{
        let textDefault = this.translate.instantSiteTerms(textToTranslate, this.apiProperties.defaultLanguage);
        this.selectListLanguagesSelected(type, this.apiProperties.defaultLanguage, true, textDefault);
      }, (errorData:any)=>{})
    }
  }

  configureSelectedLanguages(type:string){
    let configData = this.configuration[type + 'Data'];
    for(let i = 0; i < configData.length; i++){
        let selectedLanguage = configData[i];
        let textDefault = selectedLanguage.language === this.apiProperties.defaultLanguage;
        this.selectListLanguagesSelected(type, selectedLanguage.language, textDefault, selectedLanguage.text);
    }
  }

  selectListLanguagesSelected(type:string, selectedLanguage:string, textDefault:boolean, textDescription:string){
    let that = this.referenceDocument;
    for(let i = 0; i < that.languagesApp.length; i++){
        let language = cloneDeep(that.languagesApp[i]);
        language.indexListLanguage = i;
        if(language.listValueId === selectedLanguage){
            language.selected = true;
            language.textDescription = textDescription;
            language.defaultOption = Boolean(textDefault);
            language.active = Boolean(language.defaultOption);
            that[type + 'SelectedLanguages'].push(language);
            that.chargedLiteralDefault = true;
            if(language.active){
                that[type + 'LanguageSelected'] = language;
            }
            break;
        }
    }
  }

  addDocument(){
    let config = this.configuration;
    this.modalService.uploadDocuments('addNewDocumentsConcept', [], config.documentationList, config.concept).subscribe((result:any) => {
        this.upDocument(result);
    });
  }

  upDocument(result:any){
    let files = result.files;
    for(let i = 0; i < files.length; i++){
        let document = files[i];
        let name = document.name;
        document.extension = name.substr(name.lastIndexOf('.')+1, name.length);
        document.creator = this.context.user.fullName;
        this.configuration.documentationList.push(document);
    }
  }

  launchActionDocument(document:any, action:string){
    switch(action){
        case 'delete':
            this.openQuestionModal(document);
            break;
        case 'download':
            this.documentActions.doAction('download', document);
            break;
        default:
            break;
    }
  }

  openQuestionModal(document:any){
    this.modalService.adviceModal( this.translate.instant('documentation-list.question-remove-document') + "'" + document.name + ' ?', 'info:question:question').subscribe((result:any) => {
      this.deleteDocumentSelected(document);
    });
  }

  deleteDocumentSelected(document:any){
    this.loader.openLoader('documentation-list-delete-document');
    this.apiService.delete('publicconceptdoc/', document.sharedPublicConceptDocumentId).then(
      (data:any) => {
        let config = this.configuration;
        config.documentationList = this.commonUtilsService.deleteElement(document, config.documentationList, 'sharedPublicConceptDocumentId');
        this.modalService.adviceModal(this.translate.instant('common.the-document') + "'" + document.name + "'" +  this.translate.instant('documentation-list.remove-document'), 'info:accept:check-round');
      },
      (error:any) => {
        console.error(error);
        this.modalService.adviceModal( this.translate.instant('documentation-list.error-remove-document') + "'" + document.name + "'", 'error:accept:warning');
      }
    ).finally(() => {
      this.loader.closeLoader('documentation-list-delete-document');
    });
  }

  configureData(type:string){
    let that = this.referenceDocument;
    let config = this.configuration;
    let configurationModal = {
        // defaultLanguage: sessionStorage.getItem('selectLanguage'),
        defaultLanguage: this.apiProperties.defaultLanguage,
        concept: config.concept,
        languagesApp: that.languagesApp,
        selectedLanguages: that[type + 'SelectedLanguages'],
        languageSelected: that[type + 'LanguageSelected'],
        aditionalInformation: type
    };

    this.modalService.protectionDataModal(configurationModal).subscribe((result:any) => {
      if(result.result === 'ok'){
        let action = config[type + 'Data'] && config[type + 'Data'].length ? 'edit' : 'add';
        this.changeData(type, result.selectedLanguages, action);
      }
    });
  }

  changeData(type:string, selectedLanguages:any, action:string){
    let that = this.referenceDocument;
    let config = this.configuration;
    this.loader.openLoader('change-data-protection');
    let url: string = 'publicconceptcfg/concept/' + config.concept.conceptId;
    let extraUrl: string = this.getUpdateUrl(type);
    this.apiService.update( url, extraUrl, selectedLanguages).then(
      (data:any) => {
          that[type + 'SelectedLanguages'] = [];
          config[type + 'Data'] = selectedLanguages;
          that.languagesApp.forEach((language:any) => {
              language.selected = false;
          });
          let text =  this.translate.instant('reference-document-modal.save-data-protection-ok');
          if(action === 'delete'){
              let textDefault = this.getTextDefault(type);
              this.selectListLanguagesSelected(type, this.apiProperties.defaultLanguage, true, textDefault);
              text =  this.translate.instant('reference-document-modal.delete-data-protection-ok');
          }else{
              this.configureSelectedLanguages(type);
          }
          this.broadcastService.broadcast('changeData' + type, {[type + 'Data']: config[type + 'Data']});
          this.modalService.adviceModal(text, 'info:accept:check-round');
      },
      (error:any) => {
        console.error(error);
        this.modalService.adviceModal( this.translate.instant('field-type-role.error-assignment'), 'error:accept:warning');
      }
    ).finally(() => {
      this.loader.closeLoader('change-data-protection');
    });
  }

  getTextDefault(type:string){
    switch(type){
      case 'additional':
        return ''
      case 'protection':
        return this.translate.instant('protection-data-modal.text-default')
      default:
        return this.translate.instant('protection-data-modal.text-default-legal')
    }
  }

  getUpdateUrl(type:string){
    switch(type){
      case 'additional':
        return 'extra-info';
      case 'legal':
        return 'legal-info';
      default:
        return 'data-treatments'
    }
  }

  changeSelectedLanguage(type:string, selectedLanguage:any){
    this.referenceDocument[type + 'LanguageSelected'] = selectedLanguage;
    this.referenceDocument.additionalLanguageSelected.text = selectedLanguage.text;

  }

  editDataProtectionLanguages(type:string){
    let selectedLanguages = this.referenceDocument[type+'SelectedLanguages'];
    for(let i = 0; i < selectedLanguages.length; i++){
        let language = selectedLanguages[i];
        language.active = language.listValueId === Boolean(this.referenceDocument[type+'LanguageSelected'].listValueId);
    }
    this.configureData(type);
  }

  deleteDataProtectionLanguages(type:string){
    this.modalService.adviceModal( this.translate.instant('reference-document-modal.question-delete-data-protection'), 'info:question:question')
      .subscribe((result: any) => {
          if(result.result !== 'cancel'){
              this.changeData(type, [], 'delete');
          }
      });
  }

  configureAdditionalGroup(){
    if(this.referenceDocument.fieldContainerConfigurated){
      this.openConfigurationGroupConcept();
    }else{
      this.createGroupContainer();
    }
  }

  createGroupContainer(){
    let newGroup:any = {
        name: this.translate.instant('form-configuration-modal.previous-data'),
        propertyVisibleValues: [],
        listVisibleValues: [],
        fieldVisibleValues: [],
        references: []
    };
    this.apiService.add('fieldContainers/'+ this.configuration.concept.conceptId, newGroup)
      .then((data:any)=>{
          this.referenceDocument.fieldContainerConfigurated = data;
          this.referenceDocument.fieldContainerConfiguratedPreview = cloneDeep(data);
          this.openConfigurationGroupConcept();
      }, ()=>{})
  }

  openConfigurationGroupConcept(){
    let configuration: any = {
      concept: this.configuration.concept,
      conceptInfo: this.referenceDocument.conceptInfo,
      fieldContainer: this.referenceDocument.fieldContainerConfiguratedPreview
    }
    this.modalService.formConfigurationModal(configuration)
      .subscribe((result: any) => {
          if(!result.fieldContainer.fields?.length){
            this.deleteAdditionalGroup(this.referenceDocument.fieldContainerConfigurated);
          }else if(result.result === 'ok' && result.changesInConfiguration){
            this.referenceDocument.fieldContainerConfigurated = cloneDeep(this.referenceDocument.fieldContainerConfiguratedPreview);
            this.modalService.adviceModal(this.translate.instant('reference-document-modal.configurated-form-configuration-ok'), 'info:accept:check-round');
          }
      });
  }

  deleteAditionalGroupManually(container: any){
    this.modalService.adviceModal(this.translate.instant('reference-document-modal.question-delete-form-configuration'), 'info:question:question')
      .subscribe((result: any) => {
          if(result.result !== 'cancel'){
            this.loader.openLoader('reference-document-delete-group-manual');
            this.deleteAdditionalGroup(container, true);
          }
      });
  }

  deleteAdditionalGroup(container: any, manualProcess: boolean = false){
    this.apiService.delete('fieldContainers', container.fieldContainerId)
      .then(()=>{
        delete this.referenceDocument.fieldContainerConfigurated;
        delete this.referenceDocument.fieldContainerConfiguratedPreview;
        if(manualProcess){
          this.loader.closeLoader('reference-document-delete-group-manual');
          this.modalService.adviceModal(this.translate.instant('reference-document-modal.confirmation-delete-form-configuration'), 'info:accept:check-round');
        }
      }, (errorData:any)=>{
        this.loader.closeError();
      })
  }

  handleAccept() {
    let config = this.configuration;
    this.activeModal.close({
      result: 'ok',
      list: config.documentationList,
      protectionData: config.protectionData,
      additionalData: config.additionalData,
      legalData: config.legalData
    });
  }

  handleDismiss(){
    let config = this.configuration;
    this.activeModal.close({
      result: 'cancel',
      list: config.documentationList,
      protectionData: config.protectionData,
      additionalData: config.additionalData,
      legalData: config.legalData
    });
  }
}
