<div class="clients-tracking">
  <header-nav></header-nav>
  <div class="clients-tracking__body">
      <div class="common__block-button-close-window">
          <button (click)="changeView()"
              class="common__back-link">
              <span [afTranslate]="'clients-tracking.close'">_Cerrar seguimiento de clientes</span>
              <i class="lf-icon-close common__back-link-icon"></i>
          </button>
      </div>
      <div class="clients-tracking__container">
          <div class="common__block-list-new-version clients-tracking__block-container">
              <div class="common__header-new-version">
                  <i class="lf-icon-build common__header-icon-new-version"></i>
                  <span class="common__header-title-new-version"
                      [afTranslate]="'common.clients-tracking'">_Seguimiento de clientes</span>
                  <div class="clients-tracking__header-buttons-container"
                    *ngIf="clientsTracking.userPermissions ==='all'">
                      <button class="lf-icon-send clients-tracking__header-button"
                              (click)="exportList()"
                              matTooltip="{{'common.export-excel' | afTranslate}}"
                              matTooltipPosition="above"
                              matTooltipClass="above">
                      </button>
                      <button class="lf-icon-add-round clients-tracking__header-button"
                              (click)="newClient()"
                              matTooltip="{{'client-evaluation.new-client-evaluation' | afTranslate}}"
                              matTooltipPosition="above"
                              matTooltipClass="above">
                      </button>
                  </div>
              </div>
              <div class="common__table-block">
                  <div class="common__table-new-version">
                      <div class="common__table-header-flex">
                          <div class="clients-tracking__col clients-tracking__col--organization clients-tracking__col--first clients-tracking__col--top-1">
                              <filter-by-search
                                  [item]="clientsTracking.clientNameFilter"
                                  [filterOrder]="clientsTracking.typeFilter"
                                  [order]="clientsTracking.orderOperationList"
                                  [filterText]="clientsTracking.clientNameWordFilter"
                                  (launchActionSearch)="orderBySearch($event, 'clientName')">
                              </filter-by-search>
                          </div>
                          <div class="clients-tracking__col clients-tracking__col--author clients-tracking__col--top-1">
                              <filter-by-list [type]="'listFind'"
                                  [item]="clientsTracking.authorFilter"
                                  [widthClass]="'200px'"
                                  [list]="clientsTracking.authorList"
                                  (filterElementEmit)="selectTypeOperation($event.source, 'author')">
                              </filter-by-list>
                          </div>
                          <div class="clients-tracking__col clients-tracking__col--top-1"
                            [ngClass]="skin === 'steel-blue'?'clients-tracking__col--creation-be':'clients-tracking__col--creation'">
                              <div class="common__cell-block">
                                  <p id="clients-tracking-creation-header-0"
                                      class="common__table-header-text common__table-header-text--ellipsis"
                                      matTooltip="{{'common.creation' | afTranslate}}"
                                      matTooltipPosition="below"
                                      matTooltipClass="below"
                                      [matTooltipDisabled]="showTooltip('clients-tracking-creation-header-0')"
                                      (click)="searchBy('creationDate')">
                                      <span id="span-clients-tracking-creation-header-0"
                                          [afTranslate]="'common.creation'">_Creación</span>
                                  </p>
                                  <i class="common__order-by"
                                      [ngClass]="{'lf-icon-arrow-down':(clientsTracking.typeFilter==='creationDate' && clientsTracking.orderOperationList) || clientsTracking.typeFilter!=='creationDate',
                                          'lf-icon-arrow-up': clientsTracking.typeFilter==='creationDate' && !clientsTracking.orderOperationList,
                                          'common__order-by--active': clientsTracking.typeFilter==='creationDate'}"
                                      (click)="searchBy('creationDate')"></i>
                              </div>
                          </div>
                          <div class="clients-tracking__col clients-tracking__col--top-1"
                            [ngClass]="skin === 'steel-blue'?'clients-tracking__col--modification-be':'clients-tracking__col--modification'">
                              <div class="common__cell-block">
                                  <p id="clients-tracking-modification-header-0"
                                      class="common__table-header-text common__table-header-text--ellipsis"
                                      matTooltip="{{'common.modification' | afTranslate}}"
                                      matTooltipPosition="below"
                                      matTooltipClass="below"
                                      [matTooltipDisabled]="showTooltip('clients-tracking-modification-header-0')"
                                      (click)="searchBy('modificationDate')">
                                      <span id="span-clients-tracking-modification-header-0"
                                          [afTranslate]="'common.modification'">_Modificación</span>
                                  </p>
                                  <i class="common__order-by"
                                      [ngClass]="{'lf-icon-arrow-down':(clientsTracking.typeFilter==='modificationDate' && clientsTracking.orderOperationList) || clientsTracking.typeFilter!=='modificationDate',
                                          'lf-icon-arrow-up': clientsTracking.typeFilter==='modificationDate' && !clientsTracking.orderOperationList,
                                          'common__order-by--active': clientsTracking.typeFilter==='modificationDate'}"
                                      (click)="searchBy('modificationDate')"></i>
                              </div>
                          </div>
                          <div class="clients-tracking__col clients-tracking__col--top-1"
                            [ngClass]="skin === 'steel-blue'?'clients-tracking__col--risk-be':'clients-tracking__col--risk'">
                              <filter-by-list [type]="'list'"
                                  [item]="clientsTracking.riesgoFilter"
                                  [widthClass]="'200px'"
                                  [list]="clientsTracking.riesgoList"
                                  (filterElementEmit)="selectTypeOperation($event.source, 'riesgo')">
                              </filter-by-list>
                          </div>
                          <div class="clients-tracking__col clients-tracking__col--top-1"
                            [ngClass]="skin === 'steel-blue'?'clients-tracking__col--next-tracing-be':'clients-tracking__col--next-tracing'">
                              <div class="common__cell-block">
                                  <p id="clients-tracking-next-tracing-0"
                                      class="common__table-header-text common__table-header-text--ellipsis"
                                      matTooltip="{{'clients-tracking.next-tracing' | afTranslate}}"
                                      matTooltipPosition="below"
                                      matTooltipClass="below"
                                      [matTooltipDisabled]="showTooltip('clients-tracking-next-tracing-0')"
                                      (click)="searchBy('proximoSeguimiento')">
                                      <span id="span-clients-tracking-next-tracing-0"
                                          [afTranslate]="'clients-tracking.next-tracing'">_Próximo seguimiento</span>
                                  </p>
                                  <i class="common__order-by"
                                      [ngClass]="{'lf-icon-arrow-down':(clientsTracking.typeFilter==='proximoSeguimiento' && clientsTracking.orderOperationList) || clientsTracking.typeFilter!=='proximoSeguimiento',
                                          'lf-icon-arrow-up': clientsTracking.typeFilter==='proximoSeguimiento' && !clientsTracking.orderOperationList,
                                          'common__order-by--active': clientsTracking.typeFilter==='proximoSeguimiento'}"
                                      (click)="searchBy('proximoSeguimiento')"></i>
                              </div>
                          </div>
                          <div class="clients-tracking__col clients-tracking__col--top-1"
                              [ngClass]="skin === 'steel-blue'?'clients-tracking__col--end-relationship-be':'clients-tracking__col--end-relationship'">
                              <div class="common__cell-block">
                                  <p id="clients-tracking-end-relationship-0"
                                      class="common__table-header-text common__table-header-text--ellipsis"
                                      matTooltip="{{'clients-tracking.end-relationship' | afTranslate}}"
                                      matTooltipPosition="below"
                                      matTooltipClass="below"
                                      [matTooltipDisabled]="showTooltip('clients-tracking-end-relationship-0')"
                                      (click)="searchBy('finRelacion')">
                                      <span id="span-clients-tracking-end-relationship-0"
                                          [afTranslate]="'clients-tracking.end-relationship'">_Fin de relación</span>
                                  </p>
                                  <i class="common__order-by"
                                      [ngClass]="{'lf-icon-arrow-down':(clientsTracking.typeFilter==='finRelacion' && clientsTracking.orderOperationList) || clientsTracking.typeFilter!=='finRelacion',
                                          'lf-icon-arrow-up': clientsTracking.typeFilter==='finRelacion' && !clientsTracking.orderOperationList,
                                          'common__order-by--active': clientsTracking.typeFilter==='finRelacion'}"
                                      (click)="searchBy('finRelacion')"></i>
                              </div>
                          </div>
                          <div class="clients-tracking__col clients-tracking__col--top-1"
                            [ngClass]="skin === 'steel-blue'?'clients-tracking__col--state-be':'clients-tracking__col--state'">
                              <filter-by-list [type]="'list'"
                                  [item]="clientsTracking.stateNameFilter"
                                  [widthClass]="'200px'"
                                  [list]="clientsTracking.stateNameList"
                                  (filterElementEmit)="selectTypeOperation($event.source, 'stateName')">
                              </filter-by-list>
                          </div>
                          <div class="clients-tracking__col clients-tracking__col--top-1 clients-tracking__col--recurrente"
                              *ngIf="skin !== 'steel-blue'">
                              <filter-by-list [type]="'list'"
                                  [item]="clientsTracking.recurrenteTextFilter"
                                  [widthClass]="'200px'"
                                  [list]="clientsTracking.recurrenteTextList"
                                  (filterElementEmit)="selectTypeOperation($event.source, 'recurrenteText')">
                              </filter-by-list>
                          </div>
                          <div class="clients-tracking__col clients-tracking__col--legal-form clients-tracking__col--top-1"
                            *ngIf="skin !== 'steel-blue'">
                              <filter-by-list [type]="'list'"
                                  [item]="clientsTracking.legalFormFilter"
                                  [widthClass]="'200px'"
                                  [list]="clientsTracking.legalFormList"
                                  (filterElementEmit)="selectTypeOperation($event.source, 'legalForm')">
                              </filter-by-list>
                          </div>
                          <div class="clients-tracking__col clients-tracking__col--actions">
                              <p class="common__table-header-text common__table-header-text--right"
                                  [afTranslate]="'common.actions'">Acciones</p>
                          </div>
                      </div>
                      <div class="clients-tracking__table-body">
                          <div class="common__table-row-flex"
                              *ngFor="let client of clientsTracking.filterClientList | byWord:clientsTracking.searchClient:['clientName', 'author'] | afOrderBy:clientsTracking.typeFilter:clientsTracking.orderOperationList | afLimitTo:clientsTracking.configPagination.pageSize:clientsTracking.configPagination.pageSize*(clientsTracking.currentPage - 1); let i = index">
                              <div class="clients-tracking__col clients-tracking__col--organization clients-tracking__col--first common__cursor-pointer"
                                  (click)="editClient(client)">
                                  <div class="common__cell-block">
                                      <parragraph-tooltip [text]="client.clientName"></parragraph-tooltip>
                                  </div>
                              </div>

                              <div class="clients-tracking__col clients-tracking__col--author">
                                  <div class="common__cell-block">
                                      <parragraph-tooltip [text]="client.author"></parragraph-tooltip>
                                  </div>
                              </div>
                              <div class="clients-tracking__col"
                                [ngClass]="skin === 'steel-blue'?'clients-tracking__col--creation-be':'clients-tracking__col--creation'">
                                  <div class="common__cell-block">
                                      <parragraph-tooltip [text]="client.creationDate | convertDate:''"></parragraph-tooltip>
                                  </div>
                              </div>
                              <div class="clients-tracking__col"
                                [ngClass]="skin === 'steel-blue'?'clients-tracking__col--modification-be':'clients-tracking__col--modification'">
                                  <div class="common__cell-block">
                                      <parragraph-tooltip [text]="client.modificationDate | convertDate:''"></parragraph-tooltip>
                                  </div>
                              </div>
                              <div class="clients-tracking__col"
                                [ngClass]="skin === 'steel-blue'?'clients-tracking__col--risk-be':'clients-tracking__col--risk'">
                                  <parragraph-tooltip [text]="client.riesgo"
                                    class="evaluations-list__result-tag"
                                    [ngStyle]="{'background-color': client.colorRGBARiesgo}"></parragraph-tooltip>
                              </div>
                              <div class="clients-tracking__col"
                                [ngClass]="skin === 'steel-blue'?'clients-tracking__col--next-tracing-be':'clients-tracking__col--next-tracing'">
                                  <div class="common__cell-block">
                                      <parragraph-tooltip [text]="client.proximoSeguimiento | convertDate:'without-time'"></parragraph-tooltip>
                                  </div>
                              </div>
                              <div class="clients-tracking__col"
                                [ngClass]="skin === 'steel-blue'?'clients-tracking__col--end-relationship-be':'clients-tracking__col--end-relationship'">
                                  <div class="common__cell-block">
                                      <parragraph-tooltip [text]="client.finRelacion | convertDate:'without-time'"></parragraph-tooltip>
                                  </div>
                              </div>
                              <div class="clients-tracking__col"
                                [ngClass]="skin === 'steel-blue'?'clients-tracking__col--state-be':'clients-tracking__col--state'">
                                  <i [class]="(client.idEstado | taskTypeIcons:'clientState') + ' clients-tracking__icon-client-state'"></i>
                                  <div class="common__cell-block clients-tracking__cell-block-with-icon">
                                      <parragraph-tooltip [text]="client.stateName"></parragraph-tooltip>
                                  </div>
                              </div>
                              <div class="clients-tracking__col clients-tracking__col--recurrente"
                                  *ngIf="skin !== 'steel-blue'">
                                  <div class="common__cell-block">
                                      <parragraph-tooltip [text]="client.recurrenteText"></parragraph-tooltip>
                                  </div>
                              </div>
                              <div class="clients-tracking__col clients-tracking__col--legal-form"
                                *ngIf="skin !== 'steel-blue'">
                                  <div class="common__cell-block">
                                      <parragraph-tooltip [text]="client.legalForm"></parragraph-tooltip>
                                  </div>
                              </div>
                              <div class="clients-tracking__col clients-tracking__col--actions">
                                  <ul class="common__options-row-flex">
                                      <li class="common__menu-options-row"
                                        *ngIf="clientsTracking.userPermissions ==='all'"
                                        (click)="editClient(client)"
                                        matTooltip="{{'common.edit' | afTranslate}}"
                                        matTooltipPosition="below"
                                        matTooltipClass="below">
                                        <i class="lf-icon-editable-form"></i>
                                      </li>
                                      <li class="common__menu-options-row"
                                          (click)="openInNewTab(client)"
                                          matTooltip="{{'clients-tracking.open-in-new-tab' | afTranslate}}"
                                          matTooltipPosition="below"
                                          matTooltipClass="below">
                                          <i class="lf-icon-open-window"></i>
                                      </li>
                                      <li class="common__menu-options-row"
                                        *ngIf="clientsTracking.userPermissions ==='all'"
                                        (click)="deleteOption(client)"
                                        matTooltip="{{'common.remove' | afTranslate}}"
                                        matTooltipPosition="below">
                                        <i class="lf-icon-trash">
                                        </i>
                                      </li>
                                  </ul>
                                  <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="common__block-footer-table">
                  <div class="common__block-search">
                      <input type="text"
                          [ngShow]="clientsTracking.search"
                          class="common__search-input"
                          [(ngModel)]="clientsTracking.searchClient"
                          [placeholder]="'common.search' | afTranslate"
                          [focusWhen]="clientsTracking.search">
                      <span class="common__text-search"
                          [ngShow]="!clientsTracking.search"
                          (click)="toggleSearch()"
                          [afTranslate]="'common.search'">_Buscar</span>
                      <button class="lf-icon-search common__icon-button common__icon-button-search"
                          [ngClass]="{'lf-icon-search': !clientsTracking.search,
                              'lf-icon-close-search': clientsTracking.search,
                              'common__icon-button-search--open': clientsTracking.search}"
                          (click)="toggleSearch()">
                      </button>
                  </div>
                  <pagination-control class="clients-tracking__pagination-block"
                      [ngShow]="clientsTracking.filterClientList.length > clientsTracking.configPagination.pageSize"
                      [config]="clientsTracking.configPagination"
                      [collectionSize]="clientsTracking.filterClientList.length"
                      [(currentPage)]="clientsTracking.currentPage">
                  </pagination-control>
                  <div class="common__block-footer-total">
                      <span [afTranslate]="'common.total'">_Total</span>
                      <span class="common__total-number-element notranslate">{{clientsTracking.filterClientList.length}}</span>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <compliance-footer></compliance-footer>
</div>
<pdf-table class="common__pdf-hidde-element"
    [configuration]="clientsTracking.configurationPdf"></pdf-table>
