import { Component, OnInit, Input, OnChanges, SimpleChanges } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Component({
  selector: 'migration-data',
  templateUrl: './migration-data.component.html'
})
export class MigrationDataComponent implements OnInit {
  constructor(private translate:AfTranslateFactory, private activeModal:NgbActiveModal){}

  @Input() isClient:boolean;

  migrationData:any = {
    optionSelect: 'template'
  };

  ngOnInit(): void {
    this.checkIsClient();
  }

  checkIsClient() {
    let that = this.migrationData;
    let text = this.isClient ? '-client' : '';
    that.titleInfo = this.translate.instant("migration-data.title-info" + text);
    that.downloadTemplateInfo = this.translate.instant("migration-data.download-template-info" + text);
    that.exportData = this.translate.instant("migration-data.export-data" + text);
    that.exportDataInfo = this.translate.instant("migration-data.export-data-info" + text);
    that.importData = this.translate.instant("migration-data.import-data" + text);
    that.importDataInfo = this.translate.instant("migration-data.import-data-info" + text);
  }

  handleAccept() {
    this.activeModal.close({result: 'ok', optionSelect: this.migrationData.optionSelect});
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }
}
