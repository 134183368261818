import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';

import { UserPermissionsResolver } from '@shared/resolves/user-permissions-resolver/user-permissions-resolver';
import { ClientsListComponent } from '@core/components/clients-list/clients-list-default/clients-list.component';
import { DashboardViewComponent } from '@core/components/dashboard-view/dashboard-view.component';
import { EditConceptComponent } from '@core/components/edit-concept/edit-concept.component';
import { RolesWindowComponent } from '@core/components/roles-window/roles-window.component';
import { EvaluationComponent } from '@core/components/evaluation/evaluation.component';
import { FolderComponent } from '@core/components/folder/folder.component';
import { FrontLoginComponent } from '@core/components/front-login/front-login/front-login.component';
import { ConceptResolver } from '@shared/resolves/concept-resolver/concept-resolver';
import { FieldContainerResolver } from '@shared/resolves/fieldcontainer-resolver/fieldcontainer-resolver';
import { FullMenuConceptResolver } from '@shared/resolves/full-menu-concept-resolve/full-menu-concept-resolve';
import { ProcessResolver } from '@shared/resolves/process-resolver/process-resolver';
import { JwtAuthGuard } from '@shared/guards/jwt-auth/jwt-auth.guard';
import { EvaluationResolver } from '@shared/resolves/evaluation-resolve/evaluation-resolve';
import { OpenLoaderResolver } from '@shared/resolves/loader-resolver/loader-resolver';
import { OutCustomizationGuard } from '@shared/guards/out-customization/out-customization.guard';
import { GlobalCustomizationComponent } from '@core/components/global-customization/global-customization.component';
import { PreviewCustomizationComponent } from '@core/components/preview-customization/preview-customization.component';
import { TaskResolve } from '@shared/resolves/task-resolve/task-resolve';
import { TaskGenericComponent } from '@shared/components/task-generic/task-generic.component';
import { ClientsTrackingComponent } from '@shared/components/clients-tracking/clients-tracking.component';
import { InstantVideoconferencesComponent } from '@core/components/instant-videoconferences/instant-videoconferences.component';
import { OutVideoconferenceGuard } from '@shared/guards/out-videoconference/out-videoconference.guard';
import { SnippetsReusableComponent } from '@core/components/snippets-reusable/snippets-reusable.component';
import { OutSnippetsGuard } from '@shared/guards/out-snippets/out-snippets.guard';
import { ResultSearchComponent } from '@core/components/result-search/result-search.component';
import { ReportsComponent } from '@core/components/reports/reports.component';
import { AllDocumentationComponent } from '@core/components/all-documentation/all-documentation.component';
import { ClientEvaluationComponent } from '@core/components/client-evaluation/client-evaluation.component';
import { CheckValidProductIdGuard } from '@shared/guards/check-valid-product-id/check-valid-product-id.guard';
import { ReportInfoComponent } from '@shared/components/report-info/report-info.component';
import { reportResolve } from '@shared/resolves/report-resolver/report-resolver';
import { UsersAdminComponent } from '@core/components/users-admin/users-admin.component';
import { UsersAdminMainComponent } from '@core/components/users-admin-main/users-admin-main.component';
import { AlertListWindowComponent } from '@core/components/alert-list-window/alert-list-window.component';
import { OpenChatResolver } from '@shared/resolves/open-chat-resolver/open-chat-resolver';
import { ConceptWindowComponent } from '@core/components/concept-window/concept-window.component';
import { UsersAdminNewWizardComponent } from '@core/components/users-admin-new-wizard/users-admin-new-wizard.component';
import { UsersAdminEditComponent } from '@core/components/users-admin-edit/users-admin-edit.component';
import { reportColumnsResolve } from '@shared/resolves/report-columns-resolver/report-columns-resolver';
import { reportRowsResolve } from '@shared/resolves/report-rows-resolver/report-rows-resolver';
import { ConceptComponent } from '@core/components/concept/concept.component';
import { ExitLoginComponent } from '@shared/components/exit-login/exit-login.component';
import { GeneralReportComponent } from '@core/components/general-report/general-report.component';
import { reportObjectsListResolve } from '@shared/resolves/report-objects-list-resolve/report-objects-list-resolver';
import { EditionComparatorComponent } from '@core/components/edition-comparator/edition-comparator.component';
import { ReportIncidentsListComponent } from '@core/components/report-incidents-list/report-incidents-list.component';
import { EvaluationImportComponent } from '@core/components/evaluation-import/evaluation-import.component';
import { LandingPageComponent } from '@core/components/landing-page/landing-page.component';
import { ConceptObjectResolver } from '@shared/resolves/concept-object-resolver/concept-object-resolver';
import { ConceptByIdResolver } from '@shared/resolves/concept-by-id-resolver/concept-by-id-resolver';
import { AssistResolver } from '@shared/resolves/assist-resolver/assist-resolver';
import { HistoryViewerComponent } from '@shared/components/history-viewer/history-viewer.component';
import { FolderResolver } from '@shared/resolves/folder-resolver/folder-resolver';
import { ScheduleBigComponent } from '@core/components/schedule-big/schedule-big.component';
import { OrganizationsResolver } from '@shared/resolves/organizations-resolver/organizations-resolver';
import { FrontLoginCustomComponent } from '@core/components/front-login/front-login-custom/front-login-custom.component';
import { CustomReportsComponent } from '@core/components/custom-reports/custom-reports.component';
import { FavoritesComponent } from '@core/components/favorites/favorites.component';
import { EvaluationTreeResolver } from '@shared/resolves/evaluation-tree-resolver/evaluation-tree-resolver';
import { generalReportsWhResolve } from '@shared/resolves/general-reports-wh-resolver/general-reports-wh-resolver';
import { ReportRecordDefaultComponent } from '@core/components/report-record-detail/report-record-default/report-record-default.component';
import { OfficeOnlineComponent } from '@shared/components/office-online/office-online.component';
import { OfficeOnlineResolver } from '@shared/resolves/office-online-resolver/office-online-resolver';
import { ReportRecordDetailResolver } from '@shared/resolves/report-record-detail-resolver/report-record-detail-resolver';
import { EvaluationsCompanyResolverResolver } from '@shared/resolves/evaluations-company-resolver/evaluations-company-resolver.resolver';
import { ClientsCompanyResolverResolver } from '@shared/resolves/clients-company-resolver/clients-company-resolver.resolver';
import { LoginBeAccessComponent } from '@core/components/login-be-access/login-be-access.component';
import { LoginBeAccessGuard } from '@shared/guards/login-be-access/login-be-access.guard';
import { CompanyPermissionsResolver } from '@shared/resolves/company-permissions-resolver/company-permissions-resolver';
import { IcamAccessLandingComponent } from '@core/components/icam-access-landing/icam-access-landing.component';
import { IcamAccessGuard } from '@shared/guards/icam-access/icam-access.guard';
import { ReportListBeComponent } from '@core/components/reports-list/report-list-be/report-list-be.component';
import { RolesProductResolver } from '@shared/resolves/roles-product-resolver/roles-product-resolver.resolver';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'prefix', //default
    redirectTo: 'dashboard'
  },
  { path: 'dashboard',
    component: DashboardViewComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'dashboard',
      path: 'dashboard',
      componentName: 'dashboardPanel'
    },
    resolve: {
      companyPermissions: CompanyPermissionsResolver,
      chat: OpenChatResolver
    }
  },
  { path: 'login',
    component: FrontLoginComponent,
    data:{
      name: 'login',
      path: 'login',
      componentName: 'frontLogin'
    }
  },
  { path: 'login/:target',
    component: FrontLoginComponent,
    data:{
      name: 'login',
      path: 'login',
      componentName: 'frontLogin'
    }
  },
  { path: 'dfa',
    component: FrontLoginComponent,
    data:{
      name: 'dfa',
      path: 'dfa',
      componentName: 'frontLogin'
    },
  },
  { path: 'BE_EZAccess',
    component: LoginBeAccessComponent,
    canActivate: [LoginBeAccessGuard],
    data:{
      name: 'BE_EZAccess',
      path: 'BE_EZAccess',
      componentName: 'loginBeAccess'
    }
  },
  { path: 'Icam_Access',
    component: IcamAccessLandingComponent,
    canActivate: [IcamAccessGuard],
    data:{
      name: 'Icam_Access',
      path: 'Icam_Access',
      componentName: 'IcamAccessLanding'
    }
  },
  { path: 'import-evaluation',
    component: EvaluationImportComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'import-evaluation',
      path: 'import-evaluation',
      componentName: 'evaluationImport'
    }
  },
  { path: 'organizations',
    component: ClientsListComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'organizations',
      path: 'organizations',
      componentName: 'clientsList'
    },
    resolve: {
      companyPermissions: CompanyPermissionsResolver,
      organizations: OrganizationsResolver,
      chat: OpenChatResolver
    }
  },
  { path: 'evaluation'},
  { path: 'evaluation/:evaluationId',
        component: EvaluationComponent,
        canActivate: [JwtAuthGuard],
        data:{
          name: 'root.evaluation',
          path: 'evaluation/:evaluationId',
          componentName: 'evaluation'
        },
        resolve:{
          userPermissions: UserPermissionsResolver,
          evaluationTree: EvaluationTreeResolver,
          evaluationInfo: EvaluationResolver,
          chat: OpenChatResolver
        },
        children: [
          { path: 'redirect'},
          { path: 'folder/:folderId',
            component: FolderComponent,
            data:{
              name: 'root.evaluation.folder',
              path: 'folder/:folderId',
              componentName: 'folder'
            },
            resolve:{
              folder: FolderResolver
            },
          },
          {
            path: 'folder/:folderId/concept/:conceptId/concept-evaluated/:conceptObjectId',
            component: ConceptComponent,
            data:{
              evaluationTree: null,
              name: 'root.evaluation.concept',
              path: 'folder/:folderId/concept/:conceptId/concept-evaluated/:conceptObjectId',
              to: 'general',
              componentName: 'concept'
            },
            resolve: {
              conceptInfo: ConceptResolver,
              fieldContainerInfo: FieldContainerResolver,
              fullMenu: FullMenuConceptResolver,
              openLoader: OpenLoaderResolver
            }
          },
          { path: 'folder/:folderId/concept/:conceptId/:to',
            component: EditConceptComponent,
            data:{
              name: 'root.evaluation.editConcept',
              path: 'folder/:folderId/concept/:conceptId/:to',
              componentName: 'editConcept'
            },
            resolve: {
              conceptInfo: ConceptResolver,
              processInfo: ProcessResolver,
              fieldContainerInfo: FieldContainerResolver,
              fullMenu: FullMenuConceptResolver
            }
          },
          {path: 'reports',
            component: ReportsComponent,
            data:{
              name: 'root.evaluation.reports',
              path: 'reports',
              componentName: 'reports'
            }
          },
          {
            path: 'reports/:reportId',
            component: ReportInfoComponent,
            data:{
              name: 'root.evaluation.reportInfo-icam',
              path: 'reports/:reportId',
              componentName: 'reportInfo'
            },
            resolve: {
              report: reportResolve
            }
          },
          {
            path: 'reports/:reportId/report-related/:reportObjectId',
            component: ReportInfoComponent,
            data:{
              name: 'root.evaluation.reportInfo',
              path: 'reports/:reportId/report-related/:reportObjectId',
              componentName: 'reportInfo'
            },
            resolve: {
              report: reportResolve
            },
          },{
            path: 'reports/:reportId/report-related/:reportObjectId/archived',
            component: ReportInfoComponent,
            data:{
              name: 'root.evaluation.reportInfo',
              path: 'reports/:reportId/report-related/:reportObjectId/archived',
              componentName: 'reportInfo',
              archived: 'archived'
            },
            resolve: {
              report: reportResolve
            }
          },
          {
            path: 'folder/:folderId/concept/:conceptId/concept-evaluated/:conceptObjectId/tasks/:taskId',
            component: ConceptComponent,
            data:{
              name: 'root.evaluation.conceptTask',
              path: 'folder/:folderId/concept/:conceptId/concept-evaluated/:conceptObjectId/tasks',
              componentName: 'concept'
            },
            resolve: {
              conceptInfo: ConceptResolver,
              fieldContainerInfo: FieldContainerResolver,
              fullMenu: FullMenuConceptResolver,
              openLoader: OpenLoaderResolver
            }
          },
          {
            path: 'folder/:folderId/concept/:conceptId/concept-evaluated/:conceptObjectId/:to',
            component: ConceptComponent,
            data:{
              name: 'root.evaluation.conceptOptions',
              path: 'folder/:folderId/concept/:conceptId/concept-evaluated/:conceptObjectId/:to',
              componentName: 'concept'
            },
            resolve: {
              conceptInfo: ConceptResolver,
              fieldContainerInfo: FieldContainerResolver,
              fullMenu: FullMenuConceptResolver,
              openLoader: OpenLoaderResolver
            }
          },
          {
            path: 'favorites',
            component: FavoritesComponent,
            data:{
              name: 'root.evaluation.favorites',
              path: 'favorites',
              componentName: 'favorites'
            }
        }
        ]
  },
  { path: 'roles',
    component: RolesWindowComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.roles',
      path: 'roles',
      componentName: 'rolesWindow'
    },
    resolve: {
      chat: OpenChatResolver,
      rolesProduct: RolesProductResolver
    }
  },
  { path: 'customization',
    component: GlobalCustomizationComponent,
    canActivate: [JwtAuthGuard, OutCustomizationGuard],
    data:{
      name: 'root.customization',
      path: 'customization',
      componentName: 'globalCustomization'
    },
    resolve: {
      chat: OpenChatResolver
    }
  },
  { path: 'preview-customization',
    component: PreviewCustomizationComponent,
    canActivate: [JwtAuthGuard, OutCustomizationGuard],
    data:{
      name: 'root.preview-customization',
      path: 'preview-customization',
      componentName: 'previewCustomization'
    }
  },
  { path: 'history-viewer',
    component: HistoryViewerComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.history-viewer',
      path: 'history-viewer',
      componentName: 'historyViewer'
    }
    // ,
    // resolve: {
    //   chat: OpenChatResolver
    // }
  },
  { path: 'comparator-viewer',
    component: EditionComparatorComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.comparator-viewer',
      path: 'comparator-viewer',
      componentName: 'editionComparator'
    },
    resolve: {
      chat: OpenChatResolver
    }
  },
  { path: 'custom-reports',
    component: CustomReportsComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.custom-reports',
      path: 'custom-reports',
      componentName: 'customReports'
    },
    resolve: {
      chat: OpenChatResolver
    }
  },
  { path: 'task/:taskId',
    component: TaskGenericComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.task',
      path: 'task/:taskId',
      componentName: 'genericTask'//'openTask'=>'genericTask'
    },
    resolve: {
      task: TaskResolve,
      userProductPermissions: UserPermissionsResolver,
      evaluations: EvaluationsCompanyResolverResolver,
      clients: ClientsCompanyResolverResolver,
      chat: OpenChatResolver
    }
  },
  { path: 'task',
    component: TaskGenericComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.task',
      path: 'task/:taskId',
      componentName: 'genericTask'//'openTask'=>'genericTask'
    },
    resolve: {
      task: TaskResolve,
      userProductPermissions: UserPermissionsResolver,
      evaluations: EvaluationsCompanyResolverResolver,
      clients: ClientsCompanyResolverResolver,
      chat: OpenChatResolver
    }
  },
  { path: 'clients-tracking',
    component: ClientsTrackingComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.clients-tracking',
      path: 'clients-tracking',
      componentName: 'clientsTracking'
    },
    resolve: {
      chat: OpenChatResolver
    }
  },
  { path: 'obligated-subject',
    component: ConceptWindowComponent,
    canActivate: [JwtAuthGuard, CheckValidProductIdGuard],
    data:{
      name: 'root.obligated-subject',
      path: 'obligated-subject',
      componentName: 'conceptWindow',
      from: 'subject'
    },
    resolve: {
      chat: OpenChatResolver
    }
  },
  { path: 'videoconferences',
    component: InstantVideoconferencesComponent,
    canActivate: [JwtAuthGuard, OutVideoconferenceGuard],
    data:{
      name: 'root.videoconferences',
      path: 'videoconferences',
      componentName: 'instantVideoconferences'
    },
    resolve: {
      chat: OpenChatResolver
    }
  },
  { path: 'snippets',
    component: SnippetsReusableComponent,
    canActivate: [JwtAuthGuard, OutSnippetsGuard],
    data:{
      name: 'root.snippets',
      path: 'snippets',
      componentName: 'snippetsReusable'
    }
  },
  { path: 'search/:text',
    component: ResultSearchComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.search',
      path: 'search/:text',
      componentName: 'resultSearch'
    },
    resolve: {
      chat: OpenChatResolver
    }
  },
  { path: 'documentation/:productId',
    component: AllDocumentationComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.documentation',
      path: 'documentation/:productId',
      componentName: 'allDocumentation'
    },
    resolve: {
      chat: OpenChatResolver
    }
  },
  { path: 'client-evaluation',
    component: ClientEvaluationComponent,
    canActivate: [JwtAuthGuard, CheckValidProductIdGuard],
    data:{
      name: 'root.client-evaluation',
      path: 'client-evaluation',
      componentName: 'clientEvaluation'
    },
    resolve: {
      chat: OpenChatResolver
    }
  },
  { path: 'users',
    component: UsersAdminComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.users',
      path: 'users',
      componentName: 'usersAdmin'
    },
    resolve: {
      chat: OpenChatResolver
    },
    children: [
      { path: '',
        component: UsersAdminMainComponent,
        canActivate: [JwtAuthGuard],
        data:{
          name: 'root.users.main',
          path: '',
          componentName: 'usersAdminMain'
        }
      },
      { path: 'new/user',
        component: UsersAdminNewWizardComponent,
        canActivate: [JwtAuthGuard],
        data:{
          name: 'root.users.new',
          path: 'new/user',
          type: 'user',
          componentName: 'usersAdminNewWizard'
        }
      },
      { path: 'new/contact',
        component: UsersAdminEditComponent,
        canActivate: [JwtAuthGuard],
        data:{
          name: 'root.contact.new',
          path: 'new/contact',
          type: 'contact',
          operation: 'new',
          componentName: 'usersAdminEdit'
        }
      },
      { path: 'new/group',
        component: UsersAdminNewWizardComponent,
        canActivate: [JwtAuthGuard],
        data:{
          name: 'root.group.new',
          path: 'new/group',
          type: 'group',
          operation: 'new',
          componentName: 'usersAdminNewWizard'
        }
      },
      { path: 'edit/:type/:id',
        component: UsersAdminEditComponent,
        canActivate: [JwtAuthGuard],
        data:{
          name: 'root.users.edit',
          path: 'edit/:type/:id',
          operation: 'edit',
          componentName: 'usersAdminEdit'
        }
      },
      { path: 'edit-basic/:type/:id',
        component: UsersAdminEditComponent,
        canActivate: [JwtAuthGuard],
        data:{
          name: 'root.users.edit-basic',
          path: 'edit-basic/:type/:id',
          type: 'user',
          operation: 'edit-basic',
          componentName: 'usersAdminEdit'
        }
      },
      { path: 'security/:type/:id',
        component: UsersAdminEditComponent,
        canActivate: [JwtAuthGuard],
        data:{
          name: 'root.users.security',
          path: 'security/:type/:id',
          operation: 'security',
          componentName: 'usersAdminEdit'
        }
      },
      { path: 'see-security/:type/:id',
        component: UsersAdminEditComponent,
        canActivate: [JwtAuthGuard],
        data:{
          name: 'root.users.see-security',
          path: 'see-security/:type/:id',
          type: 'user',
          operation: 'see-security',
          componentName: 'usersAdminEdit'
        }
      },
      { path: 'convert/:type/:id',
        component: UsersAdminNewWizardComponent,
        canActivate: [JwtAuthGuard],
        data:{
          name: 'root.users.convert',
          path: 'convert/:type/:id',
          type: 'contact',
          operation: 'convert',
          componentName: 'usersAdminNewWizard'
        }
      }
    ],
  },
  { path: 'alert-list/:evaluationId',
    component: AlertListWindowComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.alert-list',
      path: 'alert-list/:evaluationId',
      componentName: 'alertListWindow'
    },
    resolve: {
      chat: OpenChatResolver
    }
  },
  { path: 'client/:clientId',
    component: ConceptWindowComponent,
    canActivate: [JwtAuthGuard, CheckValidProductIdGuard],
    data:{
      name: 'root.client',
      path: 'client/:clientId',
      componentName: 'conceptWindow',
      from:'client'
    },
    resolve: {
      chat: OpenChatResolver
    }
  },
  { path: 'report-detail/:reportObjectId',
      component: ReportRecordDefaultComponent,
      canActivate: [JwtAuthGuard],
      data:{
        name: 'root.report-detail',
        path: 'report-detail/:reportObjectId',
        componentName: 'reportRecordDefault'
      },
      resolve: {
        rows: reportRowsResolve,
        columns: reportColumnsResolve,
        report: reportResolve,
        chat: OpenChatResolver
      }
  },
  { path: 'report-detail/:reportObjectId/archived',
    component: ReportRecordDefaultComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.report-detail',
      path: 'report-detail/:reportObjectId/archived',
      componentName: 'reportRecordDefault',
      archived: 'archived'
    },
    resolve: {
      rows: reportRowsResolve,
      columns: reportColumnsResolve,
      report: reportResolve,
      chat: OpenChatResolver
    }
  },
  { path: 'schedule',
    component: ScheduleBigComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.schedule',
      path: 'schedule',
      componentName: 'scheduleBig'
    },
    resolve: {
      chat: OpenChatResolver
    }
  },
  { path: 'office-online/:type/:id',
    component: OfficeOnlineComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.office-online',
      path: 'office-online',
      componentName: 'officeOnline'
    },
    resolve: {
        dataOffice: OfficeOnlineResolver
    }
  },
  { path: 'shared/task-concept-evaluated/:conceptObjectId',
    component: LandingPageComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.shared.taskconcept',
      path: 'shared/task-concept-evaluated/:conceptObjectId',
      componentName: 'landingPage',
      isLanding: true
    },
    resolve: {
      concept: ConceptObjectResolver,
      userPermissions: UserPermissionsResolver
    }
  },
  { path: 'shared/concept-evaluated/:conceptObjectId',
    component: LandingPageComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.shared.concept',
      path: 'shared/concept-evaluated/:conceptObjectId',
      componentName: 'landingPage',
      isLanding: true
    },
    resolve: {
      concept: ConceptObjectResolver,
      userPermissions: UserPermissionsResolver
    }
  },
  { path: 'shared/concept-evaluated/:conceptObjectId/field-container/:fieldContainerId',
    component: LandingPageComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.shared.fieldContainer',
      path: 'shared/concept-evaluated/:conceptObjectId/field-container/:fieldContainerId',
      componentName: 'landingPage',
      isLanding: true
    },
    resolve: {
      concept: ConceptObjectResolver,
      userPermissions: UserPermissionsResolver
    }
  },
  { path: 'shared/concept-preview/:conceptId',
    component: LandingPageComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.shared.concept-preview',
      path: 'shared/concept-preview/:conceptId',
      componentName: 'landingPage',
      isLanding: true
    },
    resolve: {
      concept: ConceptByIdResolver,
      userPermissions: UserPermissionsResolver
    }
  },
  { path: 'shared/meeting/:meetingId/assist/:assist',
    component: LandingPageComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.shared.meeting',
      path: 'shared/meeting/:meetingId/assist/:assist',
      componentName: 'landingPage',
      exitType: null,
      isLanding: true
    },
    resolve: {
      assist: AssistResolver,
      userPermissions: UserPermissionsResolver
    }
  },
  { path: 'shared/task/:taskId',
    component: LandingPageComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.shared.task',
      path: 'shared/task/:taskId',
      componentName: 'landingPage',
      isLanding: true
    },
    resolve: {
      task: TaskResolve,
      userPermissions: UserPermissionsResolver
    }
  },
  { path: 'shared/download/:documenObjectId',
    component: LandingPageComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.shared.download',
      path: 'shared/download/:documenObjectId',
      componentName: 'landingPage',
      isLanding: true
    }
  },
  { path: 'shared/exit',
    component: ExitLoginComponent,
    data:{
      name: 'root.shared.exit',//'root.shared.finish'
      path: 'shared/exit',
      componentName: 'exitLogin',
      isLanding: true
    }
  },
  { path: 'public/concept/:conceptId',
    component: LandingPageComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.public.concept',
      path: 'public/concept/:conceptId',
      componentName: 'landingPage',
      public: true,
      isLanding: true
    },
    resolve: {
      concept: ConceptByIdResolver,
      userPermissions: UserPermissionsResolver
    }
  },
  { path: 'public/concept-preview/:conceptId',
    component: LandingPageComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.public.concept-preview',
      path: 'public/concept-preview/:conceptId',
      componentName: 'landingPage',
      public: true,
      isLanding: true
    },
    resolve: {
      concept: ConceptByIdResolver,
      userPermissions: UserPermissionsResolver
    }
  },
  { path: 'public/monitoring',
    component: LandingPageComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.public.monitoring',
      path: 'public/monitoring',
      componentName: 'landingPage',
      public: true,
      isLanding: true,
      isMonitoring: true
    },
    resolve: {
      concept: ConceptObjectResolver,
      userPermissions: UserPermissionsResolver
    }
  },
  { path: 'public/exit',
    component: ExitLoginComponent,
    data:{
      name: 'root.public.exit',//'root.public.finish'
      path: 'public/exit',
      componentName: 'exitLogin',
      isLanding: true
    }
  },
  { path: 'reports/:reportId',
    component: GeneralReportComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.generalReport',
      path: 'reports/:reportId',
      componentName: 'generalReport',
    },
    resolve: {
      reportObjects: reportObjectsListResolve,
      userPermissions: UserPermissionsResolver,
      chat: OpenChatResolver
    }
  },
  { path: 'report-list/:reportId',
    component: ReportIncidentsListComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'report-list',
      path: 'report-list',
      componentName: 'reportIncidentsList',
    },
    resolve: {
      chat: OpenChatResolver,
      reportList: ReportRecordDetailResolver
    }
  },
  { path: 'report-list/:reportId/:evaluationId',
    component: ReportIncidentsListComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'report-list-evaluation',
      path: 'report-list',
      componentName: 'reportIncidentsList',
    },
    resolve: {
      chat: OpenChatResolver,
      reportList: ReportRecordDetailResolver
    }
  },
  { path: 'reports-evaluation',
    component: ReportListBeComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'root.generalReports',
      path: 'reports/evaluation',
      componentName: 'reportList',
    },
    resolve: {
      chat: OpenChatResolver,
      filterReportsList: generalReportsWhResolve,
      permissions: CompanyPermissionsResolver
    }
  },
  { path: 'contact-login',
    component: FrontLoginCustomComponent,
    data:{
      name: 'contact-login',
      path: 'contact-login',
      componentName: 'frontLogin',
      contactLogin: true,
      isLanding: true
    }
  },
  { path: 'change-password/:operation',
    component: FrontLoginComponent,
    canActivate: [JwtAuthGuard],
    data:{
      name: 'change-password',
      path: 'change-password',
      componentName: 'frontLogin',
    }
  },
  { path: 'change-password-error',
    component: FrontLoginComponent,
    data:{
      name: 'change-password-error',
      path: 'change-password-error',
      componentName: 'frontLogin',
      contactLogin: true
    },
  },
  { path: '**',
    redirectTo: 'dashboard'
  }
]

const options:ExtraOptions= {
  paramsInheritanceStrategy: 'always',
  onSameUrlNavigation: 'reload'
}


@NgModule({
  imports: [RouterModule.forRoot(routes, options)],
  exports: [RouterModule],
  providers: [ConceptResolver,
    ProcessResolver,
    FieldContainerResolver,
    FullMenuConceptResolver,
    TaskResolve,
    UserPermissionsResolver,
    EvaluationResolver,
    OpenLoaderResolver,
    FolderResolver,
    reportResolve,
    ConceptByIdResolver,
    ConceptObjectResolver,
    OpenChatResolver,
    EvaluationTreeResolver,
    reportRowsResolve,
    reportColumnsResolve,
    generalReportsWhResolve,
    OfficeOnlineResolver,
    ReportRecordDetailResolver,
    EvaluationsCompanyResolverResolver,
    ClientsCompanyResolverResolver,
    CompanyPermissionsResolver,
    AssistResolver,
    RolesProductResolver]
})
export class AppRoutingModule {
}
