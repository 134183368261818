import moment from 'moment';
import { Injectable } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { ApiServiceService } from '../api-service/api-service.service';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';

@Injectable({
  providedIn: 'root'
})

export class SnapShotsService {
  landingPagePrint = false;
  isLanding: boolean = this.customLanding.isLanding();
  constructor(private route: ActivatedRoute,
    private context: ContextFactory,
    private apiService: ApiServiceService,
    private customLanding: CustomLandingFactory) { }

  getConceptJson(concept: any){
    let evaluatedFieldsById: any = {};
    concept.evaluatedFields.forEach((evaluatedField: any)=>{
        if(evaluatedField.numSubContainer){
            evaluatedFieldsById[evaluatedField.fieldId.toString()+ "-" + evaluatedField.numSubContainer.toString()] = evaluatedField;
        }else{
            evaluatedFieldsById[evaluatedField.fieldId.toString()] = evaluatedField;
        }
    })
    this.landingPagePrint = this.isLanding && concept.landingPrint;

    let historicConcept: any = {
        conceptId: concept.conceptId,
        conceptObjectId: concept.conceptObjectId,
        productTypeId: concept.productTypeId,
        landingPage: this.landingPagePrint,
        breadCrumb: this.configureBreadcrumb(concept),
        title: concept.title,
        fieldContainers: this.getContainersHistoricData(concept.fieldContainers, evaluatedFieldsById),
        language: sessionStorage.getItem('selectLanguage'),
        modifiedBy: {
            userId: this.context.user.userId,
            contactId: this.context.user.contactId,
            name: this.context.user.fullName?this.context.user.fullName:this.context.user.login,
        }
    };
    if(this.landingPagePrint){
        historicConcept.custom = concept.custom;
    }
    if(concept.productTypeId === 4){
        historicConcept.modifiedBy.modificationDate = concept.creationDate;
    }else{
      let modificationDate = new Date();
      historicConcept.modifiedBy.modificationDate = moment(modificationDate).toISOString();
      if (modificationDate.getTimezoneOffset() < 0) {
        historicConcept.modifiedBy.modificationDate = moment(modificationDate.setMinutes(Math.abs(modificationDate.getTimezoneOffset()) + modificationDate.getMinutes())).toISOString()
      }
    }
    return JSON.stringify(historicConcept);
  }

  getHistoricField(container: any, evaluatedFieldsById: any){
    let historicContainer: any = [];
    container.fields.forEach((field: any) => {
      if(!(field.private && this.landingPagePrint)){
        let evaluatedField = evaluatedFieldsById[field.fieldId.toString()];
        if(container.multiple){
            evaluatedField = evaluatedFieldsById[field.fieldId.toString()+ "-" + container.numSubContainer.toString()];
        }
        let historicField: any = {
            fieldId: field.fieldId,
            iDVersionOriginal: field.iD_Version0,
            iD_Version0: field.iD_Version0?field.iD_Version0:field.fieldId,
            fieldTypeId: field.fieldTypeId,
            fieldTypeFrontId: field.fieldTypeFrontId,
            label: field.extendedLabel ? field.extendedLabel : field.label,
            value: evaluatedField ? evaluatedField.displayData : null,
            noEditable: field.lockedEdit || field.fieldTypeFrontId === 'formu'? true : false,
            numOrder: field.numOrder,
            hiddenContainer: !field.show,
            hiddenBackContainer: field.hidden,
        };
        if(field.fieldTypeId === 'date' && field.showTime){
            historicField.showTime = field.showTime;
        }
        historicContainer.push(historicField);
    }
    });

    return historicContainer;
  }

  saveForContainer(conceptObjectId: string, fieldContainerId: string, jsonContent: any) {
    let data = {
      jsonContent: jsonContent
    };
    let context = null;
    if (this.route.snapshot.data['name'] === 'root.public.concept' || this.route.snapshot.firstChild?.data['name'] === 'root.public.concept') {
      context = 'landing-new';
    } else if (this.route.snapshot.data['name'] === 'root.public.monitoring' || this.route.snapshot.firstChild?.data['name'] === 'root.public.monitoring') {
      context = 'landing-monitoring';
    } else {
      context = 'front';
    }
    let url: string = 'conceptdata/savesnapshot/' + conceptObjectId + '?context=' + encodeURIComponent(context);
    if (fieldContainerId) {
      url += '&fieldContainerId=' + fieldContainerId;
    }
    return this.apiService.add(url, data).then(() => { }, () => { });
  }
  saveForConcept(conceptObjectId: string, jsonContent: any) {
    return this.saveForContainer(conceptObjectId, '', jsonContent);
  }

  configureBreadcrumb(concept: any){
      let breadCrumbList: any = [];
      breadCrumbList.push(concept.evaluationName);
      if(concept.breadCrumb){
          let breadcrumbSplit = concept.breadCrumb.split(' > ');
          breadCrumbList = breadCrumbList.concat(breadcrumbSplit);
      }
      if(concept.productTypeId !== 4 && concept.title){
          breadCrumbList.push(concept.title);
      }
      return breadCrumbList;
  }

  getContainersHistoricData(containers: any, evaluatedFieldsById: any) {
    let historicContainers: any = [];
    containers.forEach((container: any) => {
      if(!(container.private && this.landingPagePrint) && !container.multiple){
        let historicContainer: any = {
            fieldContainerId: container.fieldContainerId,
            iDVersionOriginal: container.iD_Version0,
            iD_Version0: container.iD_Version0?container.iD_Version0:container.fieldContainerId,
            numOrder: container.numOrder,
            hiddenContainer: !container.show,
            hiddenBackContainer: container.hidden,
            parentId: container.parentId,
            name: container.name
        };
        if (container.fields.length) {
            historicContainer.fields = this.getHistoricField(container, evaluatedFieldsById);
        }
        if (container.subContainers.length > 0) {
            historicContainer.subContainers = this.getContainersHistoricData(container.subContainers, evaluatedFieldsById);
        }
        historicContainers.push(historicContainer);
      }else if(container.multiple){
        container.subcontainerDuplicated.forEach((subcontainerDuplicated: any) => {
          let historicContainerDuplicated: any = {
            fieldContainerId: container.fieldContainerId,
            iDVersionOriginal: container.iD_Version0,
            iD_Version0: container.iD_Version0?container.iD_Version0:container.fieldContainerId,
            multiple: container.multiple,
            numSubContainer: subcontainerDuplicated.numSubContainer,
            numOrder: container.numOrder,
            hiddenContainer: !container.show,
            hiddenBackContainer: container.hidden,
            name: container.name
          };
          if(!(subcontainerDuplicated.private && this.landingPagePrint)){
              if (subcontainerDuplicated.fields.length) {
                  historicContainerDuplicated.fields = this.getHistoricField(subcontainerDuplicated, evaluatedFieldsById);
              }
          }
          historicContainers.push(historicContainerDuplicated);
        });
      }
    });
    return historicContainers;
  }

}
