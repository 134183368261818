<div class="report-record-detail">
    <header-nav></header-nav>
    <div class="common__block-window-view">
        <div class="common__block-button-close-window">
            <button (click)="changeViewWindow()"
                class="common__back-link">
                <span [afTranslate]="'report-record-detail.close-general-report'">_Cerrar informe general</span>
                <i class="lf-icon-close common__back-link-icon"></i>
            </button>
        </div>
        <div class="common__container-window-view">
            <div class="report-record-detail__block-container"
                [ngClass]="{'report-record-detail__block-container--graph':reportRecordDetail.selectedView?.viewOption === 'graph'}">
                <div class="report-record-detail__header">
                    <p class="float-start">
                      <span class="report-record-detail__header-title">{{report.title}}</span>
                      <span class="reports__new-version reports__new-version--concept-tab-report-record"
                      [ngShow]="archived"
                      [afTranslate]="'reports.archived'"></span>
                    </p>

                    <div class="common__options-block report-record-detail__subtitle-al"
                        [ngClass]="{'report-record-detail__subtitle-report-record-archived':archived}"
                        ngbDropdown
                        [open]="reportRecordDetail.showList">
                        <button class="common__selected-option common__selected-option--weight-normal"
                            ngbDropdownToggle>
                            <span class="evaluations-list__select-language">{{reportRecordDetail.selectedView?.name}}</span>
                            <i class="lf-icon-angle-down common__select-option-icon-angle-down"></i>
                        </button>
                        <div class="common__menu-block-dropdown dropdown-menu"
                            ngbDropdownMenu
                            role="menu">
                            <div class="common__block-option-list">
                                <div class="common__language-item"
                                    *ngFor="let view of reportRecordDetail.reporDetailView"
                                    (click)="changeViewTable(view)">
                                    <span class="report-record-detail__text-option-view">{{view.name}}</span>
                                    <div class="report-record-detail__bubble-count"
                                        [ngShow]="reportRecordDetail.documentationList?.length && view.viewOption === 'documentation'">
                                        {{reportRecordDetail.documentationList?.length}}
                                    </div>
                                    <i class="lf-icon-check-round-full common__language-icon"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="report-record-detail__header-block-buttons"
                        [ngShow]="reportRecordDetail.selectedView?.viewOption !== 'documentation' && reportRecordDetail.userPermissions !== 'none'">
                        <button class="common__button-border-none"
                            [ngClass]="{'report-table__disabled-button-graph': archived}"
                            [ngShow]="configurateReport.excelCharts > 0 && reportRecordDetail.actualVersion"
                            (click)="changeView()"
                            [disabled]="archived">
                            <i class="common__icon-button-underlined"
                            [ngClass]="this.reportRecordDetail.viewTableHtml !== 'graph'?'lf-icon-analysis':'lf-icon-products'"></i>
                            <span class="common__text-button-underlined">
                                {{this.reportRecordDetail.viewTableHtml !== 'graph'?('report-table.view-graph' | afTranslate) : ('report-table.view-list' | afTranslate)}}
                                </span>
                        </button>
                        <button class="lf-icon-all common__button-checked" #tooltip="matTooltip"
                            [ngClass]="{'report-table__disabled-button-graph': archived}"
                            (click)="saveAsPng(tooltip)"
                            [disabled]="reportRecordDetail.disabledSaveAsPng"
                            [ngShow]="configurateReport.excelCharts > 0 && reportRecordDetail.selectedView.viewOption === 'graph'"
                            matTooltip="{{'results-report.capture-image' | afTranslate}}"
                            matTooltipPosition="above"
                            matTooltipClass="above">
                        </button>
                        <button class="lf-icon-refresh common__button-checked"
                            [ngClass]="{'report-table__disabled-button': archived}"
                            (click)="updateReport()"
                            matTooltip="{{'report-table.refresh' | afTranslate}}"
                            matTooltipPosition="above"
                            matTooltipClass="above"
                            [disabled]="archived">
                        </button>
                        <button class="lf-icon-send common__button-checked"
                            [ngShow]="reportRecordDetail.userPermissions !== 'none'"
                            (click)="exportReport()"
                            matTooltip="{{'report-table.export'| afTranslate}}"
                            matTooltipPosition="above"
                            matTooltipClass="above">
                        </button>
                    </div>
                </div>
                <div [ngSwitch]="reportRecordDetail.selectedView.viewOption">
                    <report-record-detail *ngSwitchCase="'detail'"
                        [reportRecordDetail]="reportRecordDetail"
                        [rows]="rows"
                        [columns]="columns"
                        [reportfields]="reportfields"
                        [report]="report"
                        (filterColumns)="reportRecordDetail.filterDefaultColumns = $event.filterColumns">
                    </report-record-detail>
                    <report-record-documentation *ngSwitchDefault
                        [reportRecordDetail]="reportRecordDetail"
                        [rows]="rows"
                        [columns]="columns"
                        [reportfields]="reportfields"
                        [report]="report"
                        [documentationList]="reportRecordDetail.documentationList">
                    </report-record-documentation>
                    <report-graph *ngSwitchCase="'graph'"
                        [report]="report"
                        [showResidualOption]="false"
                        [configurateReport]="configurateReport"
                        [filterColumns]="reportRecordDetail.filterDefaultColumns">
                    </report-graph>
                </div>
            </div>
        </div>
    </div>
    <compliance-footer></compliance-footer>
</div>
<pdf-table class="common__pdf-hidde-element"
    *ngIf="!report.excelCharts"
    [configuration]="reportRecordDetail.configurationPdf"></pdf-table>
