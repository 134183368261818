import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChildren, QueryList, Output, EventEmitter } from '@angular/core';

import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { ReportRecordDetailService } from '../utils/report-record-detail.service';
import { ContextFactory } from '@shared/factories/context/context.factory';

@Component({
  selector: 'report-record-detail',
  templateUrl: './report-record-detail.component.html'
})

export class ReportRecordDetailComponent implements OnInit, OnChanges {
  constructor(private reportRecordDetailService: ReportRecordDetailService) {}


  @Input() rows:any = null;
  @Input() columns:any = null;
  @Input() reportfields:any = null;
  @Input() report:any = null;
  @Input() reportRecordDetail: any;
  @Output() filterColumns = new EventEmitter()
  @ViewChildren(NgbDropdown)
  dropdowns: QueryList<NgbDropdown>

  ngOnInit(): void {
    this.reportRecordDetailService.getProductPermissions(this.report.productId, this);
   
      this.reportRecordDetailService.configureColumns(this);
   
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!changes['rows']?.firstChange && changes['rows']?.currentValue){
     
        this.reportRecordDetailService.configureColumns(this);
    
    }
  }

  orderBySearch(e:any, type:any){
    this.reportRecordDetail['search'+type] = e.newValue;
    this.reportRecordDetailService.filterReport(this);
  }

  orderDate(type:any) {
    this.reportRecordDetailService.orderDate(type, this)
  }

  selectTypeOperation(e:any, column:any){
    this.reportRecordDetailService.selectTypeOperation(e.source, column, this)
  }

  relatedConcept(report:any){
    this.reportRecordDetailService.relatedConcept(report, this)
  }

  changeCalendar(e:any, column:any){
    column[column.id+'DateFilter'] = Boolean(e.dates.type === 'apply')
    if(e.dates.type !== 'cancel'){
      this.reportRecordDetail[column.id + 'FromDate'] = e.dates.type === 'apply'?e.dates.from : null;
      this.reportRecordDetail[column.id + 'ToDate'] = e.dates.type === 'apply'? e.dates.to : null;
      this.reportRecordDetail[column.id+'DateFromDate'] = this.reportRecordDetail[column.id + 'FromDate'];
      this.reportRecordDetail[column.id+'DateToDate'] = this.reportRecordDetail[column.id + 'ToDate'];
      this.reportRecordDetailService.filterReport(this);
    }
    this.closeDropdown(column.id);

  }

  closeDropdown(type: any){
    let dropdown: any = this.dropdowns.find((x:any)=>{
      return x._elementRef.nativeElement.id == type;
    });
    dropdown.close();
  }
  saveFilter(ev:any, column:any, index:number){
    let filter: any = {
      numColumn: index,
      filterType: column.columnType
    }
    switch(column.columnType){
      case '1':
        this.orderBySearch(ev, column.id);
        filter.value = ev.newValue;
        break;
      case '2':
        this.changeCalendar(ev, column);
        filter.value = this.setDatesToFilter(ev.dates)
        break;
      case '3':
      case '4':
        this.selectTypeOperation(ev, column);
        filter.value = this.getFilterListValues(column);
        break;
    }
    this.addNewFilter(filter);
  }

  setDatesToFilter(dates:any){
    return dates.type ==='restore'? '' : `${dates.from.format('DD/MM/YYYY')}|${dates.to.format('DD/MM/YYYY')}`
  }

  getFilterListValues(column:any){
    let values: string = '';
    for(let i: number = 0; i < this.reportRecordDetail[`${column.name}ListFilter`].length; i++){
      let listValuename:string = this.reportRecordDetail[`${column.name}ListFilter`][i].name
      values +=`${listValuename}|`; 
    }
    return values.substring(0, values.length-1);
  }

  addNewFilter(filter:any){
    let index: number = this.reportRecordDetail.filterDetailColumns.findIndex((filterColumn: any)=>{ return filterColumn.numColumn === filter.numColumn})
    if(index !== -1){
      this.reportRecordDetail.filterDetailColumns.splice(index, 1)
    } 

    if(filter.value !== ''){
      this.reportRecordDetail.filterDetailColumns.push(filter)
    }
    
    this.filterColumns.emit({
      filterColumns: this.reportRecordDetail.filterDetailColumns
    })
  }

}



