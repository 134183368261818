
<div class="attachments-template box-comunication-channel">
    <div class="attachments-template__custom-header">
        <i class="lf-icon-communication attachments-template__custom-add-icon"></i>
        <p class="attachments-template__custom-header-title"
            [afTranslate]="'box-comunication-channel.message-system'">_Sistema de mensajería</p>
        <i class="predefined-templates__change-view-icon attachments-template__custom-maximize-icon"
            [ngClass]="boxCommunicationChannel.fullScreen?'lf-icon-collapse-panel':'lf-icon-open-window'"
            (click)="maximizeScreen()"
            matTooltip="{{(boxCommunicationChannel.fullScreen?'common.fold':'common.deploy') | afTranslate}}"
            matTooltipPosition="above"
            matTooltipClass="above">
        </i>
        <div class="pull-righ">
            <i class="lf-icon-add-round box-comments__add-icon box-comments__add-icon--custom "
              [ngClass]="{'attachments-template__custom-upload-icon--disabled':boxCommunicationChannel.lockComToManager}"
              [ngShow]="(boxCommunicationChannel.hasPermissions || boxCommunicationChannel.public) && !boxCommunicationChannel.concept.anonymized"
              (click)="newComment()"
              matTooltip="{{'comments.new-comment' | afTranslate}}"
              matTooltipPosition="above"
              matTooltipClass="above">
            </i>
        </div>
        <button class="box-comunication-channel__info-block"
          *ngIf="boxCommunicationChannel.unreadAmountManager > 0">
          <i class="lf-icon-chat box-comunication-channel__info-icon"></i>
          <span class="box-comunication-channel__count-number">
            {{boxCommunicationChannel.unreadAmountManager}}
          </span>
        </button>
    </div>
    <div *ngIf="boxCommunicationChannel.lockComToManager"
      class="attachments-template__custom-lock-to-manager">
      <i class="lf-icon-warning attachments-template__custom-lock-to-manager-icon"></i>
      <p class="attachments-template__custom-lock-to-manager-text"
          [afTranslate]="'box-comunication-channel.no-permissions-to-add'">
          _No puedes adjuntar nuevos documentos en este punto del proceso de la comunicación.
      </p>
    </div>
    <div class="attachments-template__custom-no-documents"
        [ngClass]="{'attachments-template__custom-no-documents--with-top':!boxCommunicationChannel.lockComToManager}"
        [ngShow]="!boxCommunicationChannel.commentList.length && !boxCommunicationChannel.showCommentBox">
        <p [ngShow]="!boxCommunicationChannel.commentList.length && !boxCommunicationChannel.showCommentBox"
            [afTranslate]="'box-comunication-channel.no-messages-between'">__No hay mensajes entre el informante y el responsable del canal</p>
    </div>
    <div class="box-comments__custom-block-edit-comment"
        [ngClass]="{'box-comments__custom-block-edit-comment--open': boxCommunicationChannel.fullScreen}"
        [ngShow]="boxCommunicationChannel.showCommentBox && (boxCommunicationChannel.hasPermissions || boxCommunicationChannel.public) && !boxCommunicationChannel.concept.anonymized">
        <div *ngIf="!boxCommunicationChannel.disabledButtons">
          <div class="box-comunication-channel__custom-add-block">
            <p class="box-comments__add-comment-title box-comunication-channel__add-comment-title--landing"
              [afTranslate]="'box-comunication-channel.new-message'">__Nuevo mensaje</p>
            <div class="box-comunication-channel__custom-buttons">
                <button class="box-comunication-channel__text-link box-comunication-channel__text-link--landing"
                  *ngIf="boxCommunicationChannel.hasVoiceRecord"
                  (click)="listenVoiceRecord()">
                  {{boxCommunicationChannel.voiceRecord.name}}
                </button>
                <button class="box-comunication-channel__voice-recorder-button"
                  [ngShow]="skin === 'dark-blue' || skin === 'gm-law'"
                  (click)="openRecordVoice()">
                  <i class="lf-icon-microphone box-comunication-channel__voice-recorder-icon box-comunication-channel__voice-recorder-icon--landing"></i>
                </button>
            </div>
          </div>
          <textarea [id]="'edit-comment-new-comment'"
              class="box-comments__custom-add-comment-text"
              [(ngModel)]="boxCommunicationChannel.commentText"
              (keyup)="editTextArea()">
          </textarea>
          <button class="common__button-landing-custom common__button-landing-custom--send common__button-landing-custom--last float-end"
            [ngStyle]="{'background-color': custom.color, 'color': custom.textColor}"
            [ngClass]="{'common__button--disabled-opacity': boxCommunicationChannel.disabledButtons}"
            [disabled]="boxCommunicationChannel.disabledButtons"
            (click)="createNewComment(boxCommunicationChannel.commentText)"
            [afTranslate]="'common.send'">
            _Enviar
          </button>
          <button class="common__button-landing-custom common__button-landing-custom--cancel float-end"
            [ngStyle]="{'border-color': custom.color, 'color': custom.color}"
            [ngClass]="{'common__button--disabled-opacity': boxCommunicationChannel.disabledButtons}"
            [disabled]="boxCommunicationChannel.disabledButtons"
            (click)="closeComment()"
            [afTranslate]="'common.cancel'">
            _Cancelar
          </button>
        </div>
        <div class="box-comunication-channel__spinner-center"
          *ngIf="boxCommunicationChannel.disabledButtons">
          <div class="record-audio-modal__animation">
            <i class="lf-icon-refresh record-audio-modal__apply-changes-icon"
              [ngStyle]="{'color':custom.color}"></i>
          </div>
          <p class="box-comunication-channel__spinner-center-text box-comunication-channel__spinner-center-text--landing"
            [afTranslate]="'box-comunication-channel.send-messages'">_Enviando Mensaje</p>
        </div>
    </div>

    <ul class="box-comments__comment-list box-comments__comment-list--custom"
        [ngShow]="boxCommunicationChannel.showCommentList && boxCommunicationChannel.commentListFilter?.length"
        [ngClass]="{'box-comments__comment-list--full-add-comment': boxCommunicationChannel.fullScreen && boxCommunicationChannel.showCommentBox,
            'box-comments__comment-list--full-no-comment': boxCommunicationChannel.fullScreen && !boxCommunicationChannel.showCommentBox }">
        <li class="box-comments__comment-item"
            *ngFor="let comments of boxCommunicationChannel.commentListFilter | afOrderBy:'creationDate':true | afLimitTo:boxCommunicationChannel.fullScreen?boxCommunicationChannel.commentListFilter?.length:1;">
            <div class="box-comments__custom-block-edit-comment box-comments__custom-block-edit-comment--edit"
                [ngShow]="comments.edit">
                <textarea [id]="'edit-comment-'+comments.commentId"
                    class="box-comments__custom-add-comment-text"
                    [(ngModel)]="comments.commentTextarea">
                </textarea>
                <button class="common__button-landing-custom common__button-landing-custom--send float-end"
                        [ngStyle]="{'background-color': custom.color, 'color': custom.textColor}"
                        (click)="changeComment(comments)"
                        [afTranslate]="'common.save'">
                </button>
                <button class="common__button-landing-custom common__button-landing-custom--cancel float-end"
                        [ngStyle]="{'border-color': custom.color, 'color': custom.color}"
                        (click)="cancelComment(comments)"
                        [afTranslate]="'common.cancel'">
                </button>
            </div>
            <div class="box-comments__list-item box-comments__list-item--custom"
                [ngStyle]="{'border-left-color': custom.color}"
                [ngShow]="!(comments.edit || boxCommunicationChannel.edit)">
                <div class="box-comunication-channel__box-icon-left"
                    [ngStyle]="{'background-color': comments.managerMessage? custom.textColor : custom.color,
                        'color': comments.managerMessage? custom.color : custom.textColor,
                        'border-color': custom.color}">
                    <i [ngClass]="comments.managerMessage? 'lf-icon-defendant' : 'lf-icon-requester'"></i>
                </div>
                <div class="box-comments__custom-header-block">
                    <div class="box-comunication-channel__custom-author-data"
                        [ngClass]="{'box-comunication-channel__custom-author-data--customizable': !comments.managerMessage && !boxCommunicationChannel.conceptObjectId}">
                        <div>
                          <p class="box-comments__custom-author box-comunication-channel__custom-author"
                            [afTranslate]="comments.managerMessage?'box-comunication-channel.manager':'box-comunication-channel.applicant'"></p>
                          <div *ngIf="comments.unread && comments.managerMessage"
                            class="box-comunication-channel__box-new box-comunication-channel__box-new--custom">
                            <i class="lf-icon-step-final box-comunication-channel__new-advise-dot"></i>
                            <span class="box-comunication-channel__new-advise-text"
                              [afTranslate]="'box-comunication-channel.new'">
                            </span>
                          </div>
                          <button class="box-comunication-channel__audio-block"
                            *ngIf=" comments.showAudioRecord"
                            (click)="listenVoiceRecord(comments)">
                            <i class="lf-icon-sound box-comunication-channel__audio-icon"></i>
                          </button>
                          <p class="box-comments__custom-date box-comunication-channel__space-between">{{comments.creationDate | convertDate: 'without-time' }}
                              <span class="box-comments__custom-date box-comments__custom-date--hour">{{comments.creationDate | convertDate: 'only-time' }}</span>
                          </p>
                        </div>
                        <button class="box-comunication-channel__mark-custom-button"
                          *ngIf="comments.unread && comments.managerMessage"
                          matTooltip="{{'box-comunication-channel.check-read' | afTranslate}}"
                          matTooltipPosition="above"
                          matTooltipClass="above"
                          (click)="markAsRead(comments)">
                          <i class="lf-icon-check-round"></i>
                        </button>

                    </div>
                    <div class="box-comments__custom-author-menu"
                        [ngShow]="!comments.managerMessage && !boxCommunicationChannel.conceptObjectId">
                        <i class="lf-icon-trash box-comments__custom-trash-comment"
                            matTooltip="{{'common.remove' | afTranslate}}"
                            matTooltipPosition="above"
                            matTooltipClass="above"
                            (click)="deleteComment(comments)">
                        </i>
                        <i class="lf-icon-edit box-comments__custom-edit-comment"
                            matTooltip="{{'common.edit' | afTranslate}}"
                            matTooltipPosition="above"
                            matTooltipClass="above"
                            (click)="editComment(comments)">
                        </i>
                    </div>
                </div>
                <p class="box-comments__custom-comment-text"
                    [innerHTML]="comments.commentText">
                </p>
                <button class="box-comunication-channel__text-link box-comunication-channel__text-link--landing"
                  *ngIf="comments.showAudioRecord && !boxCommunicationChannel.concept.anonymized"
                  (click)="listenVoiceRecord(comments)">
                  {{comments.name}}
                </button>
                <div *ngIf="comments.audioFileName != null && boxCommunicationChannel.concept.anonymized"
                  class="m-0 field-type-text__transcription-block field-type-text__transcription-block--box-communication-landing">
                  <p class="field-type-text__transcription-text field-type-text__transcription-text--box-communication-landing"
                    [afTranslate]="'record-audio-modal.audio-transcription'"></p>
                </div>
            </div>
        </li>
    </ul>
</div>
